import React, { useState, useEffect } from 'react';
import PopUp from '../../components/popup';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_URL } from '../../components/Axios/base_uri';

function EditOrderModal({ editData, setEditData, getOrders }) {
  const [orderData, setOrderData] = useState(editData);
  const [productList, setProductList] = useState([]);
  const [rejoins, setRejoins] = useState([]);
  const [delivary_price, setDeliveryPrice] = useState(0)
  const [userData, setUserData] = useState({
    name: '',
    phone: '',
    email: '',
  });
  const [locationData, setLocationData] = useState({
    site: '',
    area: '',
    location: '',
  });
  const [loading, setLoading] = useState(false);
const [full_location, setFullLocation] = useState()
  useEffect(() => {
    if (editData) {
      setOrderData(editData);
      setUserData({
        name: editData?.user?.name || editData?.customer_name || '',
        phone: editData?.phone || '',
        customer_phone: editData?.customer_phone || '',
        email: editData?.user?.email || '',
      });
      setDeliveryPrice(orderData?.region_data?.price || editData?.delivary_price ||0)
      let fullLocation = {};
      try {
        fullLocation = editData.full_location
        setFullLocation(fullLocation)
        setLocationData({
          site: editData?.address_in_details || '',
          city: fullLocation?.city || '', 
          district: fullLocation?.district || '', 
          streat: fullLocation?.streat || '', 
          architecture: fullLocation?.architecture || '', 
          floor: fullLocation?.floor || '', 
          apartment: fullLocation?.apartment || '', 
          special_marque: fullLocation?.special_marque || '', 
        });
      } catch (err) {
        console.log(err)
        setLocationData({
          site: editData?.address_in_details || '',
          city:  editData.location_data?.city || '', 
          district:  editData.location_data?.district || '', 
          streat:  editData.location_data?.streat || '', 
          architecture:  editData.location_data?.architecture || '', 
          floor:  editData.location_data?.floor || '', 
          apartment:  editData.location_data?.apartment || '', 
          special_marque:  editData.location_data?.special_marque || '', 
        });
      }

    }
  }, [editData]);

  useEffect(() => {
    const fetchProductList = async () => {
      const token = localStorage.getItem('GreenTreesAdminToken');
      try {
        const response = await axios.get(`${BASE_URL}products/getAll_for_admin?token=${token}`);
        if (response?.data && response.data.status === 'success') {
          setProductList(response.data.result);
        } else {
          toast.error(response.data.message || 'حدث خطأ');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error('حدث خطأ');
      }
    };

    const fetchRejoins = async () => {
      const token = localStorage.getItem('GreenTreesAdminToken');
      try {
        const response = await axios.get(`${BASE_URL}regions/get_all_for_admin?token=${token}`);
        if (response?.data && response.data.status === 'success') {
          setRejoins(response.data.result.filter(item => item.hidden == "0"));
        } else {
          toast.error(response.data.message || 'حدث خطأ');
        }
      } catch (error) {
        console.error('Error fetching rejoins:', error);
        toast.error('حدث خطأ');
      }
    };

    fetchProductList();
    fetchRejoins();
  }, []);

  useEffect(() => {
    const calculateOrderValue = () => {
      const productTotal = orderData.orderproducts.reduce((total, product) =>
        total + (parseFloat(product.product_price) || 0) * (parseInt(product.product_count) || 0),
        0
      );
      const deliveryCost = orderData.region_data?.price || 0; // Use the delivery price from region_data
      return productTotal + parseFloat(delivary_price || 0);
    };

    setOrderData(prevState => ({
      ...prevState,
      order_value: calculateOrderValue()
    }));
  }, [orderData.orderproducts, orderData.region_data, delivary_price]);

  const handleOrderInputChange = (e) => {
    const { name, value } = e.target;
    setOrderData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLocationDataChange = (e) => {
    const { name, value } = e.target;
    setLocationData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = orderData.orderproducts.map((product, i) =>
      i === index ? { ...product, [field]: value } : product
    );
    setOrderData(prevState => ({
      ...prevState,
      orderproducts: updatedProducts,
    }));
  };

  const handleProductSelect = (index, productId) => {
    const selectedProduct = productList.find(product => product.id == productId);
    if (selectedProduct) {
      const updatedProducts = orderData.orderproducts.map((product, i) =>
        i === index ? {
          ...product,
          product: selectedProduct,
          product_price: selectedProduct.price,
        } : product
      );

      setOrderData(prevState => ({
        ...prevState,
        orderproducts: updatedProducts,
      }));
    }
  };

  const addProduct = () => {
    setOrderData(prevState => ({
      ...prevState,
      orderproducts: [
        ...prevState.orderproducts,
        {
          id: null,
          product_price: '',
          product_count: '',
          product: {
            title_ar: '',
            price: '',
            images: [],
          },
        },
      ],
    }));
  };

  const removeProduct = (index) => {
    setOrderData(prevState => ({
      ...prevState,
      orderproducts: prevState.orderproducts.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const requestData = {
      phone: userData.phone,
      delivary_type: orderData.delivary_type,
      order_time: orderData.order_time,
      location: orderData.location,
      area: orderData.area,
      customer_phone: userData?.customer_phone,
      email: userData?.email,
      address_in_details: locationData?.site,
      pay_type: orderData.pay_type,
      choose_subs: orderData.choose_subs,
      cvv: orderData.cvv,
      user_type: orderData.user_type,
      end_date: orderData.end_date,
      card_number: orderData.card_number,
      customer_name: userData.name,
      region_id: orderData.region_data?.id,
      delivary_price: parseFloat(delivary_price),
      user_card_name: orderData.user_card_name,
      city:  locationData?.city || '', 
      district:  locationData?.district || '', 
      streat:  locationData?.streat || '', 
      architecture: locationData?.architecture || '', 
      floor:  locationData?.floor || '', 
      apartment:  locationData?.apartment || '', 
      special_marque:  locationData?.special_marque || '', 
      full_location: { 
        region: orderData.region_data?.id, 
        region_ar: orderData.region_data?.title_ar, 
        region_en: orderData.region_data?.title_en, 
        region_price: delivary_price, 
        city: full_location?.city, 
        address_in_details: locationData?.site, 
        district: full_location?.district, 
        streat: full_location?.streat, 
        architecture: full_location?.architecture, 
        floor: full_location?.floor, 
        apartment: full_location?.apartment, 
        special_marque: full_location?.special_marque, 
        location: full_location?.location 
      },
      has_options: orderData.has_options,
      wallet_value: orderData.wallet_value,
      cash_value: orderData.cash_value,
      products: orderData.orderproducts.map(product => ([product?.product?.id, product?.product_count, product?.product_price])).map(item => item?.join("**prod**")).join("**elhagrasy**"),
      order_value: orderData.order_value,
    };

    try {
      console.log(orderData.orderproducts);

      const response = await fetch(`${BASE_URL}orders/edit_order_details/${orderData?.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        toast.success('تم تحديث الطلب بنجاح');
        getOrders();
      } else {
        const errorData = await response.json();
        console.error('Error updating order:', errorData);
        toast.error('فشل في تحديث الطلب');
      }
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('فشل في تحديث الطلب');
    } finally {
      setLoading(false);
      setEditData(false);
    }
  };


  return (
    <PopUp
      open={editData}
      setOpen={setEditData}
      title="تعديل الطلب"
    >
      {/* Products Section */}
      <div className="section">
        <h3>المنتجات</h3>
        <div className="product-list">
          {orderData.orderproducts.map((product, index) => (
            <div key={index} className="product-item">
              <select
                value={product.product.id || ''}
                onChange={e => handleProductSelect(index, e.target.value)}
                className="form-control"
              >
                <option value="">اختر المنتج</option>
                {productList.map(p => (
                  <option key={p.id} value={p.id}>
                    {p.title_ar}
                  </option>
                ))}
              </select>
              <input
                type="number"
                value={product.product_price || ''}
                onChange={e => handleProductChange(index, 'product_price', e.target.value)}
                className="form-control"
                placeholder="سعر المنتج"
              />
              <input
                type="number"
                value={product.product_count || ''}
                onChange={e => handleProductChange(index, 'product_count', e.target.value)}
                className="form-control"
                placeholder="عدد المنتج"
              />
              <button type="button" onClick={() => removeProduct(index)} className="btn btn-danger">
                حذف المنتج
              </button>
            </div>
          ))}
          <button type="button" onClick={addProduct} className="btn btn-primary">
            إضافة منتج
          </button>
        </div>
      </div>

      {/* Client Information Section */}
      <div className="section">
        <h3>معلومات العميل</h3>
        <div className="form-group">
          <label>الاسم</label>
          <input
            type="text"
            name="name"
            value={userData.name || ''}
            onChange={handleUserDataChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>رقم الهاتف</label>
          <input
            type="text"
            name="phone"
            value={userData.phone || ''}
            onChange={handleUserDataChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>رقم الهاتف 2</label>
          <input
            type="text"
            name="customer_phone"
            value={userData.customer_phone || ''}
            onChange={handleUserDataChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>البريد الإلكتروني</label>
          <input
            type="email"
            name="email"
            value={userData.email || ''}
            onChange={handleUserDataChange}
            className="form-control"
          />
        </div>
      </div>

      {/* Delivery Details Section */}
      <div className="section">
        <h3>تفاصيل التوصيل</h3>
        <div className="form-group">
          <label>العنوان بالتفصيل</label>
          <input
            type="text"
            name="site"
            value={locationData.site || ''}
            onChange={handleLocationDataChange}
            className="form-control"
          />
        </div>
       

        <div className="form-group">
          <label>المنطقة</label>
          <select
            name="region"
            value={orderData.region_data?.id || ''}
            onChange={e => {
              const selectedRegion = rejoins.find(region => region.id == e.target.value);
              setOrderData(prevState => ({
                ...prevState,
                region_data: selectedRegion,
              }));
            }}
            className="form-control"
          >
            {rejoins.map(region => (
              <option key={region.id} value={region.id}>
                {region.title_ar}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="section total-price">
        <div className="form-group">
          <label> سعر التوصيل</label>
          <input
            type="number"
            name="order_value"
            value={delivary_price || ''}
            onChange={(e)=>setDeliveryPrice(e.target.value)}
            className="form-control"
          />
        </div>
      </div>
      {/* Total Price Section */}
      <div className="section total-price">
        <div className="form-group">
          <label>قيمة الطلب</label>
          <input
            type="number"
            name="order_value"
            value={orderData.order_value -
              (orderData?.discount_type == "flat"
                ? orderData?.discount
                  ? parseFloat(orderData?.discount)
                  : 0
                : parseFloat(
                    (orderData?.order_value - (userData?.user_id
                      ? orderData?.region_data?.price * 1
                      : delivary_price * 1)) *
                      ((orderData?.discount
                        ? parseFloat(orderData?.discount)
                        : 0) /
                        100)
                  )) || ''}
            // readOnly
            className="form-control"
          />
        </div>
      </div>

      {/* Submit Button */}
      <div className="form-group">
        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-success"
          disabled={loading}
        >
          {loading ? 'جاري التحديث...' : 'تحديث'}
        </button>
      </div>
    </PopUp>

  );
}

export default EditOrderModal;
