import React, { useState, useEffect, useRef } from "react";
import "./notificationList.css"; // Import CSS file for styling
import { Loader } from "rsuite";
import { get, onValue, ref, update } from "firebase/database";
import { database } from "../../../firebase";
import { format } from "date-fns";
import toast from "react-hot-toast";
import UseGeneral from "../../../customHooks/useGeneral";

function NotificationList({ isOpen, setUnReadCount }) {
  const [notifications, setNotifications] = useState(null);
  const [initial, setInitial] = useState(true);
  const prevNotificationsRef = useRef();
  const audioRef = useRef(null);
  const { language } = UseGeneral();

  // Request permission for desktop notifications
  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    try {
      const x = ref(database, "notifications");
      onValue(x, (snapshot) => {
        const data = snapshot.val();
        const notificationsText = Object.keys(data)?.map((item) => ({
          ...data[item],
          not_id: item,
        }));
        setNotifications(
          notificationsText?.sort((a, b) => parseInt(b?.id) - parseInt(a?.id))
        );
        if (setUnReadCount) {
          setUnReadCount(
            notificationsText?.filter((item) => !item?.read)?.length
          );
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [setUnReadCount]);

  useEffect(() => {
    if (prevNotificationsRef.current && notifications) {
      const newNotifications = notifications.filter(
        (notification) =>
          !prevNotificationsRef.current.some(
            (prevNotification) => prevNotification.id === notification.id
          )
      );
      if (newNotifications.length > 0 && audioRef.current) {
        toast.success(
          language === "ar"
            ? "لديك إشعار جديد - تفقد الاشعارات"
            : "You Have A New Notification - See Notifications"
        );
        document.getElementById("messageSound").play();

        // Show desktop notification
        newNotifications.forEach((notification) => {
          const title =
            language === "ar" ? notification.title_ar : notification.title_en;
          const text =
            language === "ar" ? notification.text_ar : notification.text_en;
          new Notification(title, { body: text });
        });
      }
    }
    prevNotificationsRef.current = notifications;
  }, [notifications]);

  useEffect(() => {
    if (isOpen && notifications) {
      const updates = {};
      notifications.forEach((notification) => {
        updates[`/notifications/${notification.not_id}/read`] = true;
      });
      console.log("notifications",notifications)
      update(ref(database), updates).catch((err) => console.log(err));
    }
  }, [isOpen, notifications]);

  return (
    <div className={`notification-list ${isOpen ? "open" : ""}`}>
      <audio ref={audioRef} id="messageSound" src={require("./message.mp3")}>
        Your browser does not support the audio tag.
      </audio>
      {!notifications ? (
        <Loader size="lg" />
      ) : notifications?.length ? (
        notifications.map((notification, index) =>
          index < 11 ? (
            <div
            style={{cursor:"pointer"}}
              // onClick={() => {
              //   window.location.href =
              //     "https://hagrasy-printer.vercel.app?id=" +
              //     notification?.id +
              //     (language == "en" ? "&lang=en" : "&lang=ar");
              // }}
              key={notification?.id}
              className="notification-item"
            >
              <h4>
                {language === "ar"
                  ? notification?.title_ar
                  : notification?.title_en}
              </h4>
              <p>
                {language === "ar"
                  ? notification?.text_ar
                  : notification?.text_en}{" "}
                {" #"}
                {notification?.id}
              </p>
              <p>
                {notification?.date
                  ? format(
                      new Date(notification?.date),
                      "MMMM dd, yyyy h:mm:ss a"
                    )
                  : null}
              </p>
            </div>
          ) : null
        )
      ) : (
        <h2>No Notifications</h2>
      )}
    </div>
  );
}

export default NotificationList;
