import moment from "moment";
import React, { useEffect, useState } from "react";
import { eyeOn, printer } from "../../assets/svgIcons";
import { Axios } from "../../components/Axios";
import TableLayout from "../../components/table";
import { statuses } from "./constants";
import PopUp from "../../components/popup";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import "./style.css";
import UseGeneral from "../../customHooks/useGeneral";
import EditOrderModal from "./editModal";
const Orders = () => {
  const { language } = UseGeneral();
  const [statusFilter, setStatusFilter] = useState("");
  const [dateFilters, setDateFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState({
    oldStatus: {
      id: null,
      status: null,
    },
    newStatus: {
      id: null,
      status: null,
    },
  });
  const [open, setOpen] = useState({ open: false });
  const [orderValue, setOrderValue] = useState(null);
  const [loading, setLoading] = useState({
    change: false,
  });
  const [orders, setOrders] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(null);

  const headers = [
    {
      label: language == "ar" ? "رقم الطلب" : "Order Number",
      dataIndex: "id",
    },

    {
      label: language == "ar" ? "تاريخ الطلب" : "Order Date",
      type: "children",
      children: ({ row }) => {
        const dateNow = new Date(row?.created_at);
        return (
          <p className="text-dark">
            {moment(dateNow).format("YYYY-MM-DD HH:MM:SS")}
          </p>
        );
      },
    },
    // {
    //   label: language == "ar" ? "المنطقة" : "Region",
    //   type: "children",
    //   children: ({row}) => {
    //     console.log(row);
    //     const dateNow = new Date(row?.created_at);
    //     return row.location ? (
    //       <p className='text-dark'>
    //         {language == "ar"
    //           ? row?.location_data?.region?.title_ar
    //           : row?.location_data?.region?.title_en}
    //       </p>
    //     ) : (
    //       <p className='text-dark'>
    //         {(() => {
    //           try {
    //             return language == "ar"
    //               ? JSON.parse(row?.full_location)?.region_ar
    //               : JSON.parse(row?.full_location)?.region_en;
    //           } catch (e) {
    //             return "";
    //           }
    //         })()}
    //         {/* {`${JSON.parse(row?.full_location)?.region_ar}`} */}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   label: language == "ar" ? "اسم العميل" : "Customer Name",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <p className='text-dark'>
    //         {row?.user ? row?.user?.name : row?.customer_name}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   label: language == "ar" ? "رقم تليفون العميل" : "Customer Phone",
    //   type: "children",
    //   children: ({row}) => {
    //     const dateNow = new Date(row?.created_at);
    //     return (
    //       <p className='text-dark'>
    //         {row?.user ? row?.user?.phone : row?.customer_phone}
    //       </p>
    //     );
    //   },
    // },
    {
      label: language == "ar" ? "قيمة الطلب" : "Order Value",
      type: "children",
      children: ({ row }) => {
        const dateNow = new Date(row?.created_at);
        return (
          <p className="text-dark">
            {row?.order_value &&
              parseFloat(
                (+row?.order_value) -
                  +(row?.discount_type == "flat"
                    ? +row?.discount
                    : (+row?.discount / 100) * (+row?.order_value - row?.region_data?.price * 1))
              )?.toFixed(3)}
          </p>
        );
      },
    },
    // {
    //   label: language == "ar" ? "الخصم" : "Discount",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <p className='text-dark'>
    //         {row?.discount &&
    //           parseFloat(
    //             +row?.discount_type == "flat"
    //               ? +row?.discount
    //               : +(+row?.discount / 100) * +row?.order_value
    //           )?.toFixed(3)}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   label: language == "ar" ? "سعر التوصيل" : "Delivery Cost",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <p className='text-dark'>
    //         {(row?.user
    //           ? row?.location &&
    //             parseFloat(row?.location_data?.region?.price)?.toFixed(3)
    //           : row?.delivaery && parseFloat(row?.delivaery)?.toFixed(3)) || "0.00"}
    //       </p>
    //     );
    //   },
    // },
    {
      label: language == "ar" ? "حالة الطلب" : "Order Status",
      type: "children",
      children: ({ row }) => {
        return (
          <select
            name=""
            id=""
            value={row?.order_status}
            onChange={(e) =>
              setStatus({
                oldStatus: { id: row?.id, status: row?.order_status },
                newStatus: { id: row?.id, status: e.target.value },
              })
            }
          >
            {statuses?.map((item) => {
              return (
                <option key={item.id} value={item?.englishLabel}>
                  {language == "ar" ? item?.label : item?.englishLabel}
                </option>
              );
            })}
          </select>
        );
      },
    },
    // {
    //   label: "تفاصيل الطلب",
    //   type: "children",
    //   children: ({ row }) => {
    //     return (
    //       <p
    //         className="text-dark"
    //         style={{ cursor: "pointer" }}
    //         onClick={() => setOpen({ ...open, open: row })}
    //       >
    //         {eyeOn}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   label: "حالة الدفع",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <p className="text-dark">{row?.paymentStatus}</p>;
    //   },
    // },
    {
      label: language == "ar" ? "طباعة الطلب " : "Print  Order ",
      type: "children",
      children: ({ row }) => {
        return (
          <p
            className="text-dark"
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.location.href =
                "https://hagrasy-printer.vercel.app?id=" + row?.id + "&lang=ar";
            }}
          >
            {printer}
            {/* {language == "ar" ? "عربي" : "Arabic"} */}
          </p>
        );
      },
    },
    {
      label: language == "ar" ? "أدوات" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="btn btn-primary" onClick={() => setOrderValue(row)}>
            {language == "ar" ? "تعديل" : "ُEdit"}
          </div>
        );
      },
    }, // {
    //   label:
    //     language == "ar" ? "طباعة الطلب (English)" : "Print  Order (English)",
    //   type: "children",
    //   children: ({row}) => {
    //     return (
    //       <p
    //         className='text-dark'
    //         style={{cursor: "pointer"}}
    //         onClick={() => {
    //           window.location.href =
    //             "https://hagrasy-printer.vercel.app?id=" +
    //             row?.id +
    //             "&lang=en";
    //         }}
    //       >
    //         {printer} (English)
    //       </p>
    //     );
    //   },
    // },
  ];

  const ProductsHeaders = [
    {
      label: language == "ar" ? "رقم المنتج" : "Product Number",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "اسم المنتج" : "Product Name",
      type: "children",
      children: ({ row }) => {
        return <p className="text-dark">{row?.product?.title_ar}</p>;
      },
    },
    {
      label: language == "ar" ? "الكمية المطلوبة " : "Required Quantity",
      dataIndex: "product_count",
    },
    {
      label: language == "ar" ? "سعر المنتج " : "Product Price",
      dataIndex: "product_price",
    },
  ];

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    Axios({ method: "GET", url: "orders/get_all_for_admin" })
      .then((res) => {
        setOrders(res?.result);
        setFilteredOrders(res?.result); // Set filtered orders initially
      })
      .catch((err) => err);
  };

  const applyFilters = () => {
    let filtered = orders;

    if (statusFilter) {
      filtered = filtered.filter(
        (order) => order.order_status === statusFilter
      );
    }

    if (dateFilters.fromDate && dateFilters.toDate) {
      filtered = filtered.filter((order) =>
        moment(order.created_at).isBetween(
          dateFilters.fromDate,
          dateFilters.toDate
        )
      );
    }

    if (searchQuery) {
      filtered = filtered.filter((order) => order.id == searchQuery);
    }

    setFilteredOrders(filtered);
  };

  const resetFilters = () => {
    setDateFilters({
      fromDate: null,
      toDate: null,
    });
    setStatusFilter("");
    setSearchQuery("");
    setFilteredOrders(orders);
  };

  const changeStatus = () => {
    setLoading({ ...loading, change: true });
    Axios({
      method: "POST",
      url: "orders/update_order_status_for_admin/" + status?.newStatus?.id,
      data: {
        order_status: status?.newStatus?.status,
      },
    })
      .then((res) => {
        if (res?.status === "success") {
          toast.success(res.message);
          getOrders();
          setStatus({
            oldStatus: {
              id: null,
              status: null,
            },
            newStatus: {
              id: null,
              status: null,
            },
          });
        } else if (res?.status === "error" || res?.status === "faild") {
          toast.error(res.message);
        }
      })
      .finally(() => setLoading({ ...loading, change: false }));
  };

  return (
    <>
      <div className="data_filters">
        <div className="filter-item">
          <label htmlFor="searchInput">
            {language == "ar" ? "ابحث عن الطلب:" : "Search About Order Here"}
          </label>
          <input
            type="text"
            id="searchInput"
            placeholder={
              language == "ar" ? "ابحث عن الطلب:" : "Search About Order Here"
            }
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="filter-item">
          <label htmlFor="statusSelect">
            {language == "ar" ? "حالة الطلب:" : "Order Status: "}
          </label>
          <select
            id="statusSelect"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">{language == "ar" ? "الكل" : "All"}</option>
            {statuses.map((status) => (
              <option key={status.id} value={status.englishLabel}>
                {language == "ar" ? status.label : status.englishLabel}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item">
          <label htmlFor="fromDateInput">
            {language == "ar" ? "من تاريخ:" : "From Date : "}
          </label>
          <input
            type="date"
            id="fromDateInput"
            value={dateFilters.fromDate}
            onChange={(e) =>
              setDateFilters({ ...dateFilters, fromDate: e.target.value })
            }
          />
        </div>
        <div className="filter-item">
          <label htmlFor="toDateInput">
            {language == "ar" ? "إلى تاريخ:" : "To Date"}
          </label>
          <input
            type="date"
            id="toDateInput"
            value={dateFilters.toDate}
            onChange={(e) =>
              setDateFilters({ ...dateFilters, toDate: e.target.value })
            }
          />
        </div>{" "}
      </div>
      <div className="rowDivBtns">
        <button className="btn btn-success" onClick={applyFilters}>
          {language == "ar" ? "تطبيق الفلتر" : "Make Filtration"}
        </button>
        <button className="btn btn-danger" onClick={resetFilters}>
          {language == "ar" ? "إلغاء الفلتر" : "Cancle Filtration"}
        </button>
      </div>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <TableLayout headers={headers} data={filteredOrders} />
        </div>
      </div>
      <PopUp
        title={language == "ar" ? "تغيير حالة الطلب" : "Change Order Status"}
        open={status?.newStatus?.id}
        setOpen={() => {
          setStatus({
            oldStatus: {
              id: null,
              status: null,
            },
            newStatus: {
              id: null,
              status: null,
            },
          });
        }}
      >
        <p>
          {language == "ar"
            ? "هل أنت متأكد من تغيير حالة الطلب ؟"
            : "are You Suure To Change this order status"}
        </p>
        <div className="modalButtons">
          <button
            className="btn btn-success"
            onClick={() => (loading.change ? null : changeStatus())}
          >
            {loading.change ? (
              <Loader />
            ) : language == "ar" ? (
              " تأكيد"
            ) : (
              "Confirm"
            )}
          </button>
          <button
            className="btn btn-success"
            onClick={() => {
              setStatus({
                oldStatus: {
                  id: null,
                  status: null,
                },
                newStatus: {
                  id: null,
                  status: null,
                },
              });
            }}
          >
            {language == "ar" ? "إلغاء" : "Cancel"}
          </button>
        </div>
      </PopUp>
      <PopUp
        title={language == "ar" ? "تفاصيل الطلب" : "Order Details"}
        open={open?.open}
        setOpen={() => {
          setOpen({ ...open, open: false });
        }}
      >
        <p>
          <span>{language == "ar" ? "رقم الطلب:" : "Order Number: "} </span>
          <span>{open?.open?.id}</span>
        </p>
        <p>
          <span>{language == "ar" ? "حالة الطلب:" : "Order Status"} </span>
          <span>{open?.open?.order_status}</span>
        </p>
        <p>
          <span>{language == "ar" ? "تاريخ الطلب:" : "Order Date"} </span>
          <span>
            {moment(open?.open?.created_at).format("YYYY-MM-DD HH:MM:SS")}
          </span>
        </p>
        <p>
          <span>{language == "ar" ? "سعر الطلب" : "Order Total Price"}</span>
          <span>{open?.open?.order_value}</span>
        </p>
        <p>
          <span>{language == "ar" ? "اسم العميل:" : "Client Name: "} </span>
          <span>{open?.open?.user?.name}</span>
        </p>
        <p>
          <span>
            {language == "ar" ? "رقم تليفون العميل:" : "Client Phone Number"}{" "}
          </span>
          <span>{open?.open?.user?.phone}</span>
        </p>
        <p>
          <span>
            {language == "ar" ? "عنوان التوصيل:" : "Delivery Address:"}{" "}
          </span>
          <span>{open?.open?.location}</span>
        </p>
        <p>
          <span>{language == "ar" ? "نوع الدفع " : "Pay Method"}: </span>
          <span>{open?.open?.pay_type}</span>
        </p>
        <TableLayout
          headers={ProductsHeaders}
          data={open?.open?.orderproducts}
        />
      </PopUp>
      {orderValue && (
        <EditOrderModal editData={orderValue} setEditData={setOrderValue} getOrders={getOrders}/>
      )}
    </>
  );
};

export default Orders;
