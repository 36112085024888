import React from 'react';
import "./style.css";
import axios from 'axios';
import { BASE_URL } from '../Axios/base_uri';
import UseGeneral from '../../customHooks/useGeneral';
const Sats = ({stats}) => {
  const { language } = UseGeneral();
  return (
    <div class="card-list">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card blue">
            <div class="title">{language=='ar'?'كل المنتجات':"All Products"}</div>
            <i class="zmdi zmdi-upload"></i>
            <div class="value">{stats?.totalProductsCount}</div>
            {/* <div class="stat">
              <b>13</b>% زيادة
            </div> */}
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card green">
            <div class="title">{language=='ar'?'عدد المستخدمين':'Number Of Users'}</div>
            <i class="zmdi zmdi-upload"></i>
            <div class="value">{stats?.totalUsersCount}</div>
            {/* <div class="stat">
              <b>4</b>% زيادة
            </div> */}
          </div>
        </div>
        
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
          <div class="card red">
            <div class="title">{language=='ar'?'أحدث الطلبات':'New Orders'}</div>
            <i class="zmdi zmdi-download"></i>
            <div class="value">{stats?.orders}</div>
            {/* <div class="stat">
              <b>13</b>% نقص
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sats;
