import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { BASE_URL } from "../../components/Axios/base_uri";
import PopUp from "../../components/popup/index";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";
import "./style.css";

const Traders = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [traders, setTraders] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddTraderModal, setShowAddTraderModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  const getTraders = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}user/get_traders?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setTraders(res?.data?.result);
          setOriginalData(
            res?.data?.result
          );
        } else if (res.data.status === "error" || res.data.status === "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language === 'ar' ? "حدث خطأ ما" : "Something Went Wrong");
        }
      })
      .catch((e) => {
        setTraders([]);
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [selectedTrader, setSelectedTrader] = useState(null);
  const [newStatus, setNewStatus] = useState("");
  useEffect(() => {
    getTraders();
  }, []);

  const traderHeader = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "الإسم" : "Name",
      dataIndex: "name",
      type: "children",
      children: ({ row }) => {
        return (
          <>
            <p>{row?.name}</p>
          </>
        );
      },
    },
    {
      label: language == "ar" ? "البريد الإلكتروني" : "Email",
      dataIndex: "email",
    },
    {
      label: language == "ar" ? "رقم الهاتف" : "Phone",
      dataIndex: "phone",
    },
    {
      label: language == "ar" ? "الشعار" : "Logo",
      dataIndex: "logo",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className="table_row_image"
            style={{ cursor: "pointer" }}
            onClick={() => window.open(row.logo || require("../../assets/image_placeholder.png"), "_blank")}
          >
            <img
              src={row.logo || require("../../assets/image_placeholder.png")}
              alt="logo"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "السجل التجاري" : "Commercial Record",
      dataIndex: "commercial_record",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className="table_row_image"
            style={{ cursor: "pointer" }}
            onClick={() => window.open(row.commercial_record || require("../../assets/image_placeholder.png"), "_blank")}
          >
            <img
              src={row.commercial_record || require("../../assets/image_placeholder.png")}
              alt="commercial record"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "الحاله" : "Status",
      dataIndex: "status",
      type: "children",
      children: ({ row }) => {
        let statusStyle = {};

        switch (row.status) {
          case 'pending':
            statusStyle = {
              color: "#FFA500",
              backgroundColor: "#FFF8E7",
              padding: "5px 10px",
              borderRadius: "5px",
            };
            break;
          case 'approved':
            statusStyle = {
              color: "#28A745",
              backgroundColor: "#E6FFE6",
              padding: "5px 10px",
              borderRadius: "5px",
            };
            break;
          case 'rejected':
            statusStyle = {
              color: "#DC3545",
              backgroundColor: "#FFE6E6",
              padding: "5px 10px",
              borderRadius: "5px",
            };
            break;
          default:
            statusStyle = {
              color: "#6C757D",
              backgroundColor: "#F8F9FA",
              padding: "5px 10px",
              borderRadius: "5px",
            };
        }

        return (
          <div style={statusStyle}>
            {language == "ar"
              ? row.status === "pending"
                ? "قيد الانتظار"
                : row.status === "approved"
                  ? "مقبول"
                  : "مرفوض"
              : row.status.charAt(0).toUpperCase() + row.status.slice(1)}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "الأوامر" : "Actions",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowChangeStatus(row);
            }}
          >
            {language == "ar" ? "تغيير الحالة" : "Change Status"}
          </button>
        );
      },
    },
  ];




  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
      new_status: rowData.hidden === "0" ? "1" : "0"
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}change_trader_status/${rowData.id}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.data && res?.data?.status === "success") {
          toast.success(res.data.message);
          getTraders();
        } else if (res.data.status === "error" || res.data.status === "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language === 'ar' ? "حدث خطأ ما" : 'Something Went Wrong');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        const newData = originalData.filter((trader) => {
          if (
            searchValue.length >= 1 &&
            !trader.name.toLowerCase().includes(searchValue.toLowerCase()) &&
            !trader.phone.toLowerCase().includes(searchValue.toLowerCase()) &&
            !trader.email.toLowerCase().includes(searchValue.toLowerCase()) &&

            !trader.id.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setTraders(newData);
      } else {
        setTraders(originalData);
      }
    }
  }, [searchValue]);
  const handleSaveStatus = async (id, newStatus) => {
    setChangeStatusLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    try {
      await axios.post(`${BASE_URL}user/change_trader_status/${id}?token=${token}`, { new_status: newStatus });
      getTraders();
      toast.success(language === 'ar' ? "تم تغيير الحالة بنجاح" : "Status changed successfully");
      setShowChangeStatus(false);
    } catch (error) {
      toast.error(language === 'ar' ? "حدث خطأ أثناء تغيير الحالة" : "Error occurred while changing status");
    } finally {
      setChangeStatusLoading(false);

    }
  };

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language === 'ar' ? "التجار" : "Traders"}</h5>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language === 'ar' ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={traderHeader} data={traders} />
          )}
        </div>
      </div>
      <PopUp
        open={showChangeStatus}
        setOpen={setShowChangeStatus}
        title={language === 'ar' ? "تغيير حالة التاجر" : "Change Trader Status"}
        children={
          <div className="">
            <h5 className="">
              {language === 'ar' ? 'هل تريد بالفعل تغيير حالة هذا التاجر إلى ' : 'Do you really want to change the status of this trader to '}
              {newStatus === "pending" ? (language === 'ar' ? "قيد الانتظار" : "Pending")
                : newStatus === "approved" ? (language === 'ar' ? "مقبول" : "Approved")
                  : newStatus === "rejected" ? (language === 'ar' ? "مرفوض" : "Rejected")
                    : ""}
              {language === 'ar' ? '؟' : '?'}
            </h5>

            <div className="status-tabs">
              <div
                className={`tab ${newStatus === 'pending' ? 'active' : ''}`}
                style={{
                  backgroundColor: newStatus === 'pending' ? '#FFE6B3' : '#FFF',
                  borderColor: '#FFA500',
                  color: newStatus === 'pending' ? '#FFA500' : '#000'
                }}
                onClick={() => setNewStatus('pending')}
              >
                {language === 'ar' ? "قيد الانتظار" : "Pending"}
              </div>
              <div
                className={`tab ${newStatus === 'approved' ? 'active' : ''}`}
                style={{
                  backgroundColor: newStatus === 'approved' ? '#D4EDDA' : '#FFF',
                  borderColor: '#28A745',
                  color: newStatus === 'approved' ? '#28A745' : '#000'
                }}
                onClick={() => setNewStatus('approved')}
              >
                {language === 'ar' ? "مقبول" : "Approved"}
              </div>
              <div
                className={`tab ${newStatus === 'rejected' ? 'active' : ''}`}
                style={{
                  backgroundColor: newStatus === 'rejected' ? '#F8D7DA' : '#FFF',
                  borderColor: '#DC3545',
                  color: newStatus === 'rejected' ? '#DC3545' : '#000'
                }}
                onClick={() => setNewStatus('rejected')}
              >
                {language === 'ar' ? "مرفوض" : "Rejected"}
              </div>
            </div>
            <button
              onClick={() => handleSaveStatus(showChangeStatus.id, newStatus)}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : language === 'ar' ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />


    </>
  );
};

export default Traders;
