import React from "react";
import SideNav from "../sideNav";
import "./style.css";
import DashboardHeader from "../header";
const DefaultLayout = ({ children }) => {
  return (
    <div className="defaultLayout">
      <SideNav />
      <div className="siteContent">
        {" "}
        <DashboardHeader />
        <div className="childs">{children}</div>
      </div>
    </div>
  );
};

export default DefaultLayout;
