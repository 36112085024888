import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { Archived, links } from "../../data";
import UseGeneral from "../../customHooks/useGeneral";

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(true); // حالة الناف بار (مفتوحة/مغلقة)
  const { userData } = UseGeneral();
  const { language, changeLanguage } = UseGeneral();
  // دالة لتغيير حالة الناف بار
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };
  return (
    <aside className={`sidebar ${isOpen ? "" : "closed"}`}>
      <div className="logoCon">
        <img src={require("../../assets/newlogo.png")} className="logo" alt="" />
        <span
          onClick={() => toggleNav()}
          className={`menu ${isOpen ? "open" : "close"}`}
        >
          {
            <span>
              {" "}
              <i className="fas fa-bars"></i>
            </span>
          }
        </span>
      </div>
      <div
        onClick={() => {
          // console.log(language)
          changeLanguage(language == 'ar' ? 'en' : 'ar');
        }}
        className={language == 'ar' ? "lang" : "lang rev"}
      >
        {language == 'ar' ? (
          <>
            <img src={require('../../assets/United.png')} alt="" />
            <span>EN</span>
          </>
        ) : (
          <>
            <img
              src={require('../../assets/united-arab-emirates.png')}
              alt=""
            />
            <span>العربيه</span>
          </>
        )}
      </div>
      <ul>
        {links(userData?.data?.email).map((link, index) =>
          link?.to ? (
            <li key={index}>
              <NavLink to={link.to}>
                <i className={link.icon}></i>
                <span>
                  {" "}
                  {language == 'ar' ? link.labelArabic : link.labelEnglish}
                </span>
              </NavLink>
            </li>
          ) : null
        )}
        {Archived && Archived?.length ? (
          <h5 className={language == 'ar' ? '' : 'rev'}>
            {language == 'ar' ? 'الأرشيف' : 'Archive'}
          </h5>
        ) : null}
        {Archived.map((link, index) =>
          link?.to ? (
            <li key={index}>
              <NavLink to={link.to}>
                <i className={link.icon}></i>
                <span>
                  {" "}
                  {language == 'ar' ? link.labelArabic : link.labelEnglish}
                </span>
              </NavLink>
            </li>
          ) : null
        )}
      </ul>
    </aside>
  );
};

export default SideNav;
