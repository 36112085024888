import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import { Loader } from "rsuite";
import TableLayout from "../../components/table";
import { add, edit, deleteIcon } from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts"; // Import Recharts components
import { ResponsiveContainer } from "recharts"; // Import ResponsiveContainer
import UseGeneral from "../../customHooks/useGeneral";

const StockHistory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [chartDataBar, setChartDataBar] = useState([]);
  const [chartDataLine, setChartDataLine] = useState([]);
  const [chartDataPie, setChartDataPie] = useState([]);
  const { language } = UseGeneral();

  const [dataLoading, setDataLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [newQuantity, setNewQuantity] = useState({});
  const [selectedStock, setSelectedStock] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // Function to get today's date in YYYY-MM-DD format
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const productsHeader = [
    {
      label: language == "ar" ? "الكمية" : "Quantity",
      type: "children",
      children: ({ row }) => {
        return <div>{row?.stock}</div>;
      },
    },
    {
      label: language == "ar" ? "تاريخ الاضافة" : "Added Date",
      type: "children",
      children: ({ row }) => {
        return <div>{row?.data_added}</div>;
      },
    },
    {
      label: language == "ar" ? "إجراءات" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div>
            <button
              style={{ margin: "10px" }}
              className="btn btn-danger"
              onClick={() => handleShowUpdateModal(row)}
            >
              {language == "ar" ? "تعديل" : "Edit"}
            </button>
            <button
              style={{ margin: "10px" }}
              className="btn btn-primary"
              onClick={() => handleShowDeleteModal(row)}
            >
              {language == "ar" ? "حذف" : "Delete"}
            </button>
          </div>
        );
      },
    },
  ];

  const getAdminProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}products/get_product_stock_history/${id}?token=${token}`
      );
      if (response.data.status === "success") {
        setProducts(response.data.result);

        // Update chart data
        const totalQuantity =
          response.data.result && response.data.result.length
            ? response.data.result[0]?.product?.quantity
            : 0;
        const availableQuantity =
          response.data.result && response.data.result.length
            ? response.data.result[0]?.product?.avilabel_stock
            : 0;
        const soldQuantity =
          response.data.result && response.data.result.length
            ? response.data.result[0]?.product?.num_order
            : 0;

        const dataBar = [
          {
            name: language == "ar" ? "الكمية الكلية" : "Full Quantity",
            value: totalQuantity,
          },
          {
            name: language == "ar" ? "الكمية المتاحة" : "Available Quantity",
            value: availableQuantity,
          },
          {
            name: language == "ar" ? "الكمية التي تم بيعها" : "Quantity Sold",
            value: soldQuantity,
          },
        ];

        const dataLine = response.data.result
          ? response.data.result.map((product) => ({
              name: product.data_added,
              stock: product.stock,
            }))
          : [];

        const dataPie = [
          { name:language == "ar" ? "الكمية الكلية" : "Full Quantity", value: totalQuantity },
          { name: language == "ar" ? "الكمية المتاحة" : "Available Quantity", value: availableQuantity },
          { name: language == "ar" ? "الكمية التي تم بيعها" : "Quantity Sold", value: soldQuantity },
        ];

        setChartDataBar(dataBar);
        setChartDataLine(dataLine);
        setChartDataPie(dataPie);
      } else if (response.data.status === "error") {
        toast.error(response.data.message);
      } else {
        toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(
        language == "ar" ? "حدث خطأ أثناء جلب البيانات" : "Error Fetching Data"
      );
    } finally {
      setDataLoading(false);
    }
  };

  const handleAddStock = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("GreenTreesAdminToken");

    setAddLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}products/change_product_stock/${id}?token=${token}`,
        { ...newQuantity },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data.status === "success") {
        toast.success(
          language == "ar"
            ? "تمت إضافة الكمية بنجاح"
            : "Quantity Added Succeessfully"
        );
        getAdminProducts();
        setShowAddModal(false);
      } else {
        toast.error(response.data.status);
      }
    } catch (error) {
      console.error("Error adding stock:", error);
      toast.error(
        language == "ar"
          ? "حدث خطأ أثناء إضافة الكمية"
          : "Error Adding Quantity"
      );
    } finally {
      setAddLoading(false);
    }
  };

  const handleUpdateStock = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("GreenTreesAdminToken");

    setUpdateLoading(true);
    try {
      delete selectedStock.product;
      selectedStock.stock = selectedStock?.stock * 1;
      const response = await axios.post(
        `${BASE_URL}products/update_stock/${selectedStock.id}?token=${token}`,
        { ...selectedStock },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.data.status === "success") {
        toast.success(
          language == "ar" ? "تم تحديث الكمية بنجاح" : "Updated Successfully"
        );
        getAdminProducts();
        setShowUpdateModal(false);
      } else {
        toast.error(response.data.status);
      }
    } catch (error) {
      console.error("Error updating stock:", error);
      toast.error(
        language == "ar" ? "حدث خطأ أثناء تحديث الكمية" : "Error Updating Stock"
      );
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleDeleteStock = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDeleteLoading(true);
    try {
      const response = await axios.delete(
        `${BASE_URL}products/delete_stock/${selectedStock.id}?token=${token}`
      );
      if (response.data.status === "success") {
        toast.success(
          language == "ar" ? "تم حذف الكمية بنجاح" : "Deleted Successfully"
        );
        getAdminProducts();
        setShowDeleteModal(false);
      } else {
        toast.error(response.data.status);
      }
    } catch (error) {
      console.error("Error deleting stock:", error);
      toast.error(
        language == "ar" ? "حدث خطأ أثناء حذف الكمية" : "Error deleting stock"
      );
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleShowUpdateModal = (stock) => {
    setSelectedStock(stock);
    setShowUpdateModal(true);
  };

  const handleShowDeleteModal = (stock) => {
    setSelectedStock(stock);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    getAdminProducts();
  }, []);

  return (
    <>
      <div
        className="title_add"
        style={{
          marginTop: "30px",
          width: "86%",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center", // Center text
        }}
      >
        <h5>{language == "ar" ? "المخزن" : "Stock"}</h5>
        <div onClick={() => setShowAddModal(true)}>{add}</div>
      </div>
      <div
        className="rowDiv"
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "center", // Center items horizontally
          gap: "17px",
          width: "86%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="row">
          <b>{language == "ar" ? "الكمية الكلية : " : "Full Quantity : "} </b>
          <b className="text-danger">
            {products && products.length ? products[0]?.product?.quantity : 0}
          </b>
        </div>
        <div className="row">
          <b>
            {" "}
            {language == "ar"
              ? "الكمية المتاحة : "
              : "Available Quantity : "}{" "}
          </b>
          <b className="text-danger">
            {products && products.length
              ? products[0]?.product?.avilabel_stock
              : 0}
          </b>
        </div>

        <div className="row">
          <b>{language == "ar" ? "الكمية المباعة : " : "Sold Quantity : "}</b>
          <b className="text-danger">
            {products && products.length ? products[0]?.product?.num_order : 0}
          </b>
        </div>
      </div>
      <div
        className="rowDiv"
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "space-between", // Space out items
          flexWrap: "wrap",
          width: "94%",
          marginLeft: "auto",
          marginRight: "auto",
          // Left-to-right direction
        }}
      >
        {dataLoading ? (
          <Loader backdrop center speed="slow" size="lg" vertical />
        ) : (
          <>
            <TableLayout
              headers={productsHeader}
              data={products && products?.length ? products : []}
              contentClassName="content"
              theadClassName="thead"
              className="table"
            />
          </>
        )}
      </div>
      <PopUp
        onClose={() => setShowAddModal(false)}
        setOpen={setShowAddModal}
        open={showAddModal}
        title={language == "ar" ? "إضافة كمية" : "Add Quantity"}
      >
        <form onSubmit={handleAddStock}>
          <div className="form-group" style={{ textAlign: "center" }}>
            <label htmlFor="stock">
              {language == "ar" ? "الكمية" : "َQuantity"}
            </label>
            <input
              type="number"
              className="form-control"
              id="stock"
              placeholder={language == "ar" ? "الكمية" : "Quantity"}
              onChange={(e) =>
                setNewQuantity({ ...newQuantity, stock: e.target.value })
              }
              required
            />
          </div>
          <div className="field_input">
            <label htmlFor="">
              {language == "ar" ? "تاريخ الاضافة" : "Added Date"}{" "}
            </label>
            <input
              type="date"
              defaultValue={getTodayDate()}
              onChange={(e) => {
                setNewQuantity({
                  ...newQuantity,
                  data_added: e.target.value,
                });
              }}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={addLoading}
            style={{ margin: "10px" }}
          >
            {addLoading ? (
              <Loader size="xs" />
            ) : language == "ar" ? (
              "إضافة "
            ) : (
              "Add"
            )}
          </button>
        </form>
      </PopUp>
      <PopUp
        onClose={() => setShowUpdateModal(false)}
        open={showUpdateModal}
        setOpen={setShowUpdateModal}
        title={language == "ar" ? "تعديل الكمية" : "Edit Quantity"}
      >
        <form onSubmit={handleUpdateStock}>
          <div className="form-group" style={{ textAlign: "center" }}>
            <label htmlFor="stock">
              {language == "ar" ? "الكمية" : "Quantity"}
            </label>
            <input
              type="number"
              className="form-control"
              id="stock"
              placeholder={language == "ar" ? "الكمية" : "Quantity"}
              value={selectedStock?.stock}
              onChange={(e) =>
                setSelectedStock({
                  ...selectedStock,
                  stock: e.target.value,
                })
              }
              required
            />
          </div>
          <div className="field_input">
            <label htmlFor="">
              {language == "ar" ? "تاريخ الاضافة" : "Added Date"}{" "}
            </label>
            <input
              type="date"
              value={selectedStock?.data_added || ""}
              onChange={(e) => {
                setSelectedStock({
                  ...selectedStock,
                  data_added: e.target.value,
                });
              }}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={updateLoading}
            style={{ margin: "10px" }}
          >
            {updateLoading ? (
              <Loader size="xs" />
            ) : language == "ar" ? (
              "حفظ "
            ) : (
              "Save"
            )}
          </button>
        </form>
      </PopUp>
      <PopUp
        onClose={() => setShowDeleteModal(false)}
        setOpen={setShowDeleteModal}
        open={showDeleteModal}
        title={language == "ar" ? "حذف الكمية" : "Delete Quantity"}
      >
        <div style={{ textAlign: "center" }}>
          {language == "ar" ? (
            <p>هل أنت متأكد أنك تريد حذف هذه الكمية؟</p>
          ) : (
            <p>Are You Sure ?</p>
          )}
          <button
            className="btn btn-danger"
            onClick={handleDeleteStock}
            disabled={deleteLoading}
            style={{ margin: "10px" }}
          >
            {deleteLoading ? (
              <Loader size="xs" />
            ) : language == "ar" ? (
              "نعم"
            ) : (
              "Yes"
            )}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setShowDeleteModal(false)}
            disabled={deleteLoading}
          >
            {language == "ar" ? "لا" : "No"}
          </button>
        </div>
      </PopUp>

      {/* Recharts Charts */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItem: "flex-end",
          marginTop: "30px",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%" }}>
          {/* <h4 style={{ textAlign: "center" }}>Bar Chart</h4> */}
          <div
            className="rowDiv"
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            {/* <h4 style={{ textAlign: "center" }}>Pie Chart</h4> */}
            <PieChart width={350} height={350}>
              <Pie
                data={chartDataPie}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              />
              <Tooltip />
            </PieChart>
            <BarChart width={350} height={350} data={chartDataBar}>
              <Bar dataKey="value" fill="#4caf50" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </BarChart>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          {/* <h4 style={{ textAlign: "center" }}>Line Chart</h4> */}
          <ResponsiveContainer width="100%" height={350}>
            <LineChart data={chartDataLine}>
              <Line type="monotone" dataKey="stock" stroke="#2196f3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default StockHistory;
