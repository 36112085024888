import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import { Menu, add, edit, eyeOff, eyeOn } from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";
import  Select  from 'react-select';

const CategoryBorductsPage = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [password, setPassword] = useState(false);
  const { id, category_id } = useParams();
  const [params] = useSearchParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [catProds, setCatProds] = useState(null);

  const location = useLocation();
  const subCategoryData = location?.state?.subCategoryData;
  const handleChangeMultiSubCats = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    if (selectedRows && selectedRows.length) {
      setChangeStatusLoading(true);

      try {
        // Create an array of promises
        const updatePromises = selectedRows.map(async (item) => {
          const product = {
            title_ar: item?.title_ar,
            title_en: item?.title_en,
            description_ar: item?.description_ar,
            description_en: item?.description_en,
            price: parseFloat(item?.price),
            discount: parseFloat(item?.discount),
            images: item?.images.map((img) => img.url).join("**green**"),
            subcategory_ids:
              item?.subcategoryproduct && item?.subcategoryproduct?.length
                ? [
                    item?.subcategoryproduct
                      ?.map((subItem) => subItem.subcategory_id)
                      ?.join("**green**"),
                    changeMultiSubCats
                      ?.map((item) => item?.value)
                      ?.join("**green**"),
                  ]?.join("**green**")
                : changeMultiSubCats
                    ?.map((item) => item?.value)
                    ?.join("**green**"),
            has_options: item?.has_options,
            id: item?.id,
            Barcode: item?.Barcode,
            policies: item?.returnpolicies
              ?.map((policy) => policy.policy_id)
              .join("**green**"),
            category_id: item?.subcategory?.category_id,
            price_for: item?.price_for,
            quantity: parseInt(item?.quantity),
            weights: item?.productweights
              ?.map((weight) => `${weight.weight}**green**${weight.price}`)
              .join("**green**"),
          };

          const response = await axios.post(
            `${BASE_URL}products/update_product?token=${token}`,
            product,
            {
              headers: {
                "Content-Type": "application/json",
                lang: language,
              },
            }
          );
          return response.data; // Extracting data from response
        });

        // Resolve all promises
        const results = await Promise.all(updatePromises);
        console.log(results);

        // Handle results
        results.forEach((res) => {
          if (res?.status === "success") {
            toast.success(res.message);
            getCategoryProds();
            setHideMulti(false);
          } else if (res.status === "error" || res.status === "faild") {
            toast.error(res.message);
          } else {
            toast.error(
              language === "ar" ? "حدث خطأ ما" : "Something Went Error"
            );
          }
        });
      } catch (error) {
        console.error(error);
        toast.error(language === "ar" ? "حدث خطأ ما" : "Something Went Error");
      } finally {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      }
    }
  };
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddCatPordModal, setShowAddCatPordModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [changeMultiQuantity, setChangeMultiQuantity] = useState(0);
  const [subCatsMulti, setSubCatsMulti] = useState(false);
  const [deletedData, setDeleteData] = useState(false);
  const [hideMulti, setHideMulti] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [changeMultiSubCats, setChangeMultiSubCats] = useState([]);
  const [allCats, setAllCats] = useState([]);

  const deleteCategory = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    setLoader(true);
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      selectedRows.forEach(async (item) => {
        await axios
          .get(`${BASE_URL}products/delete/${item.id}?token=${token}`)
          .then((res) => {
            toast.success(res.message);
            getCategoryProds();
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setLoader(false);
          });
      });
    }
  };

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: 0,
    // images:image1**green**image2**green**image3,
    subcategory_id: id,
  });
  const [finalDraggedRow, setFinalDraggedRow] = useState(null);
  const [c_id, setId] = useState(null);
  const [draggedRow, setDraggedRow] = useState(null);
  useEffect(() => {
    if (finalDraggedRow && draggedRow) {
      const draggedIndex = catProds?.findIndex(
        (item) => item?.id == draggedRow?.id
      );
      const draggedFinalIndex = catProds?.findIndex(
        (item) => item?.id == finalDraggedRow?.id
      );
      const draggedID = catProds?.filter(
        (item) => item?.id == finalDraggedRow?.id
      )[0]?.id
      setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
      setArrangeNumber(draggedID, draggedFinalIndex + 1);

    }
  }, [finalDraggedRow]);
  useEffect(() => {
    if (draggedRow) {
      setCurrentNumber(draggedRow?.number);
      setId(draggedRow?.id);
    }
  }, [draggedRow]);


  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    // setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllCats(res?.data?.result?.filter((item) => item?.hidden == "0"));
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(
            language == "ar"
              ? "هناك مشكلة في تحميل الفئات"
              : "Something Went Error"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        setAllCats([]);
      })
      .finally(() => {
        // setDataLoading(false);
      });
  };


  const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    const num = currentNumberTwo ? currentNumberTwo : currentNumber;
    setLoader(true);
    if (video_number == num) {
      // alert(video_number, num);
      return setLoader(false);
    }

    await Axios({
      method: "POST",
      url: "products/change_product_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getCategoryProds();
        setFinalDraggedRow(null);
        setDraggedRow(null);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };


  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const [categories, setCategories] = useState(null);
  const getAllSubCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    if (!subCatsMulti) {
      setChangeMultiSubCats([]);
    } else {
      getCategories();
    }
  }, [subCatsMulti]);

  const catProdsHeader = [
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      // sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? " أوامر" : "actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${row.hidden == "0" ? "text-success" : "text-danger"
                }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                    ? "إظهار"
                    : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // console.log(row.image);
                window.open(`/editporduct/${row.id}`, "_blanck", "noopener");
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
            {/* <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                console.log(row);
              }}
            >
             <button className="btn btn-success">{language =="ar" ?"عرض":"View"}</button>
            </div> */}
          </div>
        );
      },
    },
    {
      label: language == 'ar' ? "اسم المنتج" : "Product name",
      dataIndex: language == 'ar' ? "title_ar" : "title_en",
    },
    {
      label: language == 'ar' ? "سعر المنتج" : "Product Price",
      dataIndex: "price",
    },
    // {
    //   label: language == "ar" ? "المخزن" : "Stock",
    //   dataIndex: "quantity",
    // },
    {
      label: language == 'ar' ? "الخصم" : "Discount",
      dataIndex: "discount",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == 'ar' ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"
              ? language == 'ar'
                ? "ظاهر"
                : "Showen"
              : language == 'ar'
                ? "مخفي"
                : "Hidden"}
          </div>
        );
      },
    },

  ];

  const [quantityMulti, setQuantityMulti] = useState(false);


  const handleChangeMultiQuantity = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    if (selectedRows && selectedRows.length) {
      setChangeStatusLoading(true);

      try {
        // Create an array of promises
        const updatePromises = selectedRows.map(async (item) => {
          console.log(item)

          const product = {
            title_ar: item?.title_ar,
            title_en: item?.title_en,
            description_ar: item?.description_ar,
            description_en: item?.description_en,
            price: parseFloat(item?.price),
            discount: parseFloat(item?.discount),
            images: item?.images.map((img) => img.url).join("**green**"),
            subcategory_ids: item?.subcategoryproduct
              ?.map((subItem) => subItem.subcategory_id)
              ?.join("**green**"),
            has_options: item?.has_options,
            id: item?.id,
            Barcode: item?.Barcode,
            policies: item?.returnpolicies
              ?.map((policy) => policy.policy_id)
              .join("**green**"),
            category_id: item?.subcategory?.category_id,
            price_for: item?.price_for,
            quantity: parseInt(quantityMulti),
            weights: item?.productweights
              ?.map((weight) => `${weight.weight}**green**${weight.price}`)
              .join("**green**"),
          };

          const response = await axios.post(
            `${BASE_URL}products/update_product?token=${token}`,
            product,
            {
              headers: {
                "Content-Type": "application/json",
                lang: language,
              },
            }
          );
          return response.data; // Extracting data from response
        });

        // Resolve all promises
        const results = await Promise.all(updatePromises);
        console.log(results);

        // Handle results
        results.forEach((res) => {
          if (res?.status == "success") {
            toast.success(res.message);
            getCategoryProds();
            setHideMulti(false);
          } else if (res.status === "error" || res.status === "faild") {
            toast.error(res.message);
          } else {
            toast.error(
              language === "ar" ? "حدث خطأ ما" : "Something Went Error"
            );
          }
        });
      } catch (error) {
        console.error(error);
        toast.error(language === "ar" ? "حدث خطأ ما" : "Something Went Error");
      } finally {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      }
    }
  };

  // const handleShow_hide = async () => {
  //   const token = localStorage.getItem("GreenTreesAdminToken");
  //   const dataset = {
  //     id: rowData.id,
  //   };

  //   setChangeStatusLoading(true);

  //   await axios
  //     .post(`${BASE_URL}products/update_status?token=${token}`, dataset)
  //     .then((res) => {
  //       if (res?.data && res?.data?.status == "success") {
  //         toast.success(
  //           `تم ${rowData.hidden == "0" ? " إخفاء " : " إظهار "} المنتج بنجاح`
  //         );
  //         getCategoryProds();
  //       } else if (res.data.status == "error") {
  //         toast.error("هناك مشكلة ! حاول مجدداً");
  //       } else {
  //         toast.error("حدث خطأ ما");
  //       }
  //     })
  //     .catch((e) => console.log(e))
  //     .finally(() => {
  //       setChangeStatusModal(false);
  //       setChangeStatusLoading(false);
  //       setRowData({});
  //     });
  // };


  const handleShow_hide = async (isArray) => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    setChangeStatusLoading(true);
    if (isArray && selectedRows && selectedRows?.length) {
      selectedRows.map(async (item) => {
        await axios
          .post(
            `${BASE_URL}products/update_status?token=${token}`,
            {
              id: item?.id,
            },
            {
              headers: {
                lang: language,
              },
            }
          )
          .then((res) => {
            if (res?.data && res?.data?.status == "success") {
              toast.success(res.data.message);
              getCategoryProds();
              setHideMulti(false);
            } else if (
              res.data.status == "error" ||
              res.data.status == "faild"
            ) {
              toast.error(res.data.message);
            } else {
              toast.error(
                language == "ar" ? "حدث خطأ ما" : "Something Went Error"
              );
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setChangeStatusModal(false);
            setChangeStatusLoading(false);
            setRowData({});
          });
      });
    } else {
      const dataset = {
        id: rowData.id,
      };
      await axios
        .post(`${BASE_URL}products/update_status?token=${token}`, dataset, {
          headers: {
            lang: language,
          },
        })
        .then((res) => {
          if (res?.data && res?.data?.status == "success") {
            toast.success(res.data.message);
            getCategoryProds();
          } else if (res.data.status == "error" || res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error(
              language == "ar" ? "حدث خطأ ما" : "Something Went Error"
            );
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setChangeStatusModal(false);
          setChangeStatusLoading(false);
          setRowData({});
        });
    }
  };




  const getCategoryProds = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    // setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/subcategory_products/${id}?token=${token}`)
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setCatProds(
            res?.data?.result
              ?.filter((item) => item?.hidden == "0")
              ?.sort((a, b) => a.number - b?.number)
          );
          setOriginalData(
            res?.data?.result
              ?.filter((item) => item?.hidden == "0")
              ?.sort((a, b) => a.number - b?.number)
          );
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategoryProds();

    console.log(subCategoryData);
  }, []);

  const handleEditMulti = () => {
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      // return
      selectedRows.forEach((item, index) => {
        setTimeout(() => {
          console.log(index);
          window.open(
            `/editporduct/${item?.id}`,
            "_blank",
            "noopener,noreferrer"
          );
        }, index * 500);
      });
    }
  };






  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>
              {language == 'ar'
                ? 'منتجات للفئة الفرعية'
                : 'Products For Subcategory'}{" "}
              (
              {language == 'ar'
                ? subCategoryData?.title_ar
                : subCategoryData?.title_en}
              )
            </h5>
            <div
              className="btn btn-success"
              onClick={() =>
                navigate('/addproduct', {
                  state: {
                    subCat: id,
                    subCatName:
                      language == 'ar'
                        ? subCategoryData?.title_ar
                        : subCategoryData?.title_en,
                    category_id: category_id,
                    type: "sub",
                  },
                })
              }
            >
              {language != "ar" ? "Add New Product" : "إضافة منتج جديد"}
            </div>{" "}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == 'ar' ? "بحث..." : 'Search...'}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {selectedRows && selectedRows?.length ? (
            <div className='rowDiv'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  handleEditMulti();
                }}
              >
                {language == "ar" ? "تعديل" : "Edit"}
              </button>{" "}
              <button
                className='btn btn-danger'
                onClick={() => {
                  setHideMulti(true);
                }}
              >
                {language == "ar" ? "إخفاء" : "Hide"}
              </button>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setSubCatsMulti(true);
                }}
              >
                {language == "ar" ? "تعديل الفئات" : "Change Sub-Category"}
              </button>{" "}
              <button
                className='btn btn-danger'
                style={{ cursor: "pointer", margin: "20px" }}
                onClick={() => {
                  setDeleteData(true);
                }}
              >
                {language == "ar" ? "حذف" : "Delete"}
              </button>
            </div>
          ) : null}

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout
              headers={catProdsHeader}
              data={catProds}
              finalDraggedRow={finalDraggedRow}
              setFinalDraggedRow={setFinalDraggedRow}
              draggedRow={draggedRow}
              setDraggedRow={setDraggedRow}
              setSelectedRows_t={setSelectedRows}

            />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatPordModal}
        setOpen={setShowAddCatPordModal}
        title={"إضافة منتج لهذة الفئة "}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleAddNewSubCat();
            }}
          >
            <div className="inputs ">
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">سعر المنتج </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">قيمة الخصم</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      discount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="">
                <label htmlFor="options" className="ms-3">
                  له إضافات؟
                </label>
                <input
                  id="options"
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setNewProd({
                      ...newProd,
                      hasOptions: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? "هل تريد بالفعل " : "Do You Really Want To "}
              {rowData.hidden == "0"
                ? language == 'ar'
                  ? " إخفاء "
                  : " Hide "
                : language == 'ar'
                  ? " إظهار "
                  : ' Show '}
              {language == 'ar' ? ' هذا المنتج ' : ' This Product '}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusloading ? (
                <Loader />
              ) : language == 'ar' ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />

      <PopUp
        open={hideMulti}
        setOpen={setHideMulti}
        title={
          language == "ar" ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className=''>
            <h5 className=''>
              {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
              {rowData.hidden == "0"
                ? language == "ar"
                  ? " إخفاء "
                  : "Hide"
                : language == "ar"
                  ? " إظهار "
                  : " Show "}
              {language == "ar" ? "هذه المنتجات " : "This Products"}
            </h5>

            <button
              onClick={() => handleShow_hide(true)}
              className='popup_agree_btn'
            >
              {changeStatusloading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />

      <PopUp
        open={changeMultiQuantity}
        setOpen={setChangeMultiQuantity}
        title={""}
        children={
          <div className=''>
            <p>{language == "ar" ? "الكمية الجديدة" : "New Quantity"}</p>
            <input
              type='number'
              onChange={(e) => setQuantityMulti(e.target.value)}
            />

            <button
              onClick={() => handleChangeMultiQuantity(true)}
              className='popup_agree_btn'
            >
              {changeStatusloading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />



      <PopUp
        open={deletedData}
        setOpen={setDeleteData}
        title={""}
        children={
          <div className=''>
            <h5 className=''>
              {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
              {rowData.hidden == "0"
                ? language == "ar"
                  ? " حذف "
                  : "Delete"
                : language == "ar"
                  ? " حذف "
                  : " Delete "}
              {language == "ar" ? "هذه المنتجات " : "This Products"}
            </h5>
            <p>{language == "ar" ? "كلمة السر" : "Password"}</p>
            <input type='text' onChange={(e) => setPassword(e.target.value)} />
            <button
              onClick={() =>
                // password == "*green2024deletepassword*"
                   deleteCategory(true)
                  // : toast.error(
                  //   language == "ar"
                  //     ? "كلمة السر خاطئة "
                  //     : "Password  Is Wrong"
                  // )
              }
              className='popup_agree_btn'
            >
              {changeStatusloading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />{" "}

<PopUp
        open={subCatsMulti}
        setOpen={setSubCatsMulti}
        title={""}
        children={
          <div className=''>
            <>
              <div className='field_input col-md-6'>
                <label htmlFor=''>
                  {language == "ar" ? "الفئة الفرعية" : "Subcategory "}
                </label>
                {/* <SelectWithSearch
                    options={subCategories}
                    getClientData={setSubCatData}
                    defaultValue={productData?.subcategory_id}
                  /> */}
                <Select
                  placeholder={
                    language == "ar" ? "الفئات الفرعيه" : "Subcategory"
                  }
                  isMulti
                  value={changeMultiSubCats}
                  onChange={(e) => {
                    setChangeMultiSubCats(e);
                  }}
                  // value={selectedSubCategories}
                  name='subcategories'
                  options={allCats?.map((item, index) => {
                    return {
                      label: language == "ar" ? item.title_ar : item?.title_en,
                      value: item.id,
                    };
                  })}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </div>
            </>

            <button
              onClick={() => handleChangeMultiSubCats(true)}
              className='popup_agree_btn'
            >
              {loader ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />{" "}
    </>
  );
};

export default CategoryBorductsPage;
