import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import UseGeneral from "../../customHooks/useGeneral";

const ContactsPage = () => {
  const {language}=UseGeneral()
  const navigate = useNavigate();
  const { id } = useParams();

  const location = useLocation();
  const categoryData = location?.state?.categoryData;

  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue,  setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [editLoading, setEditLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [newContact, setNewContact] = useState({
    title_ar: '',
    title_en: '',
    account: '',
    hidden: 1, //0||1 default 0
  });

  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const getContacts = async () => {
    setDataLoading(true);
    await axios
      .get(`${BASE_URL}contact_us/get_all_for_admin`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => {
        setData([]);
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getContacts();
  }, []);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAddFile = async () => {
  
    if (!img) {
      toast.error(language=='ar'?"لم تقم بإضافة صورة":"Enter Image");
      return;
    }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          handleAddNew(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload Image");
        } else {
          toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload Image");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const header = [
    
    {
      label:language=='ar'? "الصورة":"Image",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.icon} alt="image" />
          </div>
        );
      },
    },

    {
      label: language=='ar'?"العنوان":"Title",
   dataIndex: language=='ar'?"title_ar":"title_en",
    },
    {
      label: language=='ar'?"الرابط":"Link",
      dataIndex: "account",
    },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language=='ar'?"الحالة":"Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language=='ar'?"ظاهر":"Showen" : language=='ar'?"مخفي":'Hidden'}
          </div>
        );
      },
    },
    {
      label: language=='ar'?" أوامر":'Actions',
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
                setImg(row.icon);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                // setImgUrl(row.image)
                setImgUrl(row.icon);
                setImg(row.icon);
                console.log(row);
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);
    await axios
      .get(`${BASE_URL}contact_us/change_status/${rowData.id}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            res.data.message
            );
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        // getSubCatefories();
        getContacts();
        setRowData({});
      });
  };

  const handleAddNew = async (image) => {
   

    const dataset = {
      ...newContact,
      icon: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}contact_us/add_new?token=${token}`, dataset,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getContacts();
        } else if (res.data.status=='faild') {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?'حدث خطأ ما':"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };

  const handleUpdateCategoryImage = async () => {
    setUpdateLoading(true);
    console.log(selectedFile);
    console.log(img);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data != "" && res.status == 201) {
            // setImgUrl(res?.data?.message);
            console.log(res?.data?.result?.image);
            handleUpdateSubCat(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload Image");
          } else {
            toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload Image");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    } else {
      handleUpdateSubCat(rowData.icon);
    }

    setUpdateLoading(false);
  };

  const handleUpdateSubCat = async (image) => {
  

    const dataset = {
      ...rowData,
      icon: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    setEditLoading(true);
    await axios
      .post(
        `${BASE_URL}contact_us/update_cont/${rowData.id}?token=${token}`,
        dataset,
        {
          headers:{
            lang:language
          }
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getContacts();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
        setUpdateModal(false);
      });
  };

  // filteration

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((subCat) => {
          if (
            searchValue.length >= 1 &&
            !subCat.title_ar?.toLowerCase()?.includes(searchValue) &&
            !subCat.title_en?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language=='ar'?" معلومات التواصل ":"Contact Info"}</h5>
            <div onClick={() => setShowAddSubCatModal(true)} className="btn btn-success">{language == 'ar' ? 'إضافة جهة اتصال' : 'Add Contact'}</div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language=='ar'?"بحث...":"Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={header} data={data} />
          )}
        </div>
      </div>

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language=='ar'?"تغيير حالة الظهور":"Change Appearance Status"}
        children={
          <div className="">
            <h5 className=" my-5">
              {language=='ar'?"هل تريد بالفعل ":"Do You Really Want To "}{rowData.hidden == "0" ? language=='ar'?" إخفاء ":" hide " : language=='ar'?" إظهار ":" Show "}
              {language=='ar'?' الحساب الفرعية؟ ':' This Account ?'}
            </h5>

            <button
              onClick={() => {
                if (!changeStatusloading) {
                  handleShow_hide();
                }
              }}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : language=='ar'?"تأكيد":"Confrim"}
            </button>
          </div>
        }
      />

      <PopUp
        open={showAddSubCatModal}
        setOpen={setShowAddSubCatModal}
        onClose={() => {
          setSelectedFile(null);
          setImg('');
          setNewContact({
            title_ar: '',
            title_en: '',
            account: '',
            hidden: 1, //0||1 default 0
          });
        }}
        title={language=='ar'?"إضافة تواصل جديد ":"Add New Social Account"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();

              handleAddFile();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language=='ar'?"العنوان باللغة العربية":"Arabic Title"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewContact({
                      ...newContact,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language=='ar'?"العنوان باللغة الإنجليزية":"English Title"}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewContact({
                      ...newContact,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language=='ar'?"رابط حساب التواصل":"Social Link"}</label>
                <input
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setNewContact({
                      ...newContact,
                      account: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language=='ar'?"اختر أيقونة الحساب":"Enter Social account Icon"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language=='ar'?"إضافة":"Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        onClose={() => {
          setSelectedFile(null);
          setImg('');
        }}
        title={` ${language=='ar'?"تعديل حساب التواصل ":"Edit Social Account : "}  ${language=='ar'?rowData.title_ar:rowData.title_en}`}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateCategoryImage();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language=='ar'?'العنوان باللغة العربية':'Arabic Title'}</label>

                <input
                  value={rowData.title_ar}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language=='ar'?'العنوان باللغة الإنجليزية':'English Title'}</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language=='ar'?"رابط حساب التواصل":"Social Account Link"}</label>
                <input
                  value={rowData.account}
                  type="text"
                  placeholder=""
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      account: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">{language=='ar'?"اختر أيقونة الحساب":"Enter Social Account Image"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.icon && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.icon);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {editLoading ? <Loader /> : language=='ar'?"تعديل":"Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default ContactsPage;
