// import { useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { change } from "../store/languageReducer";
import { useEffect, useState } from "react";
const UseGeneral = () => {
  const user = useSelector((state) => state?.user);
  const logOut = () => {
    localStorage?.clear();
    window.location.reload();
  };
  const language = useSelector((state) => state?.language?.language);

  const dispatch = useDispatch();
  const [data, setData] = useState();
  const changeLanguage = (payload) => {
    console.log('dd')
    dispatch(change(payload));
  };

  const siteData = useSelector((state) => state?.site?.siteData);

  useEffect(() => {
    if (language.toLowerCase() == "ar") {
      document.body.classList.add("arVersion");
      document.body.classList.remove("enVersion");
    } else {
      document.body.classList.add("enVersion");
      document.body.classList.remove("arVersion");
    }
  }, [language]);
  useEffect(() => {
    if (siteData?.logo) {
      setData(siteData);
    }
  }, [siteData]);

  return {
    userData: user,
    logOut: logOut,
    language:language.toLowerCase(),
    changeLanguage:changeLanguage
  };
};

export default UseGeneral;
