import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import moment from "moment";
import Select from "react-select";
import UseGeneral from "../../customHooks/useGeneral";

import "./style.css";
const CouponsPage = () => {
  const { language } = UseGeneral();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [originalUsersData, setOriginalUsersData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});

  const [showAddModal, setShowAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [assignModal, setAssignModal] = useState(false);

  const [selectedDateTime, setSelectedDateTime] = useState(null);

  const [isPresentage, setIsPresentage] = useState(true);
  const [forUsers, setForUsers] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);

  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [showEditModal, setShowEditModal] = useState(null);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const [newCoupon, setNewCopon] = useState({
    expired_date: "",
    use_limit: "",
    val: "",
    type: "",
    prefix: "",
    generatedCode: "",
  });

  const generatingCode = () => {
    if (!newCoupon.prefix) {
      toast.error(
        language == "ar"
          ? "قم بكتابة بادئة الكود أولا"
          : "Write code prefix first"
      );
      return;
    }

    const min = 100000000;
    const max = 900000000;
    const randomCdoe = Math.floor(Math.random() * max) + min;
    setNewCopon({
      ...newCoupon,
      generatedCode: randomCdoe,
    });
  };

  const generatingCode_edit = () => {
    if (!showEditModal.prefix) {
      toast.error(
        language == "ar"
          ? "قم بكتابة بادئة الكود أولا"
          : "Write code prefix first"
      );
      return;
    }

    const min = 100000000;
    const max = 900000000;
    const randomCdoe = Math.floor(Math.random() * max) + min;
    setShowEditModal({
      ...showEditModal,
      generatedCode: randomCdoe,
    });
  };

  const getCoupons = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}coupons/get_all?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getAllUsers = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}user/get_users?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setUsers(res?.data?.result);
          setOriginalUsersData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const handleMultiSelectChnage = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  useEffect(() => {
    getCoupons();
    getAllUsers();

    console.log(selectedDateTime);
  }, []);

  const handleDateTimeChange = (e) => {
    setSelectedDateTime(e.target.value);
  };

  const handleAddNewCoupon = async () => {
    if (!selectedDateTime) {
      toast.error(
        language == "ar" ? "قم باختيار تاريخ انتهاء اولا" : "Select Expiry Date"
      );
      return;
    }

    const currentTime = new Date();
    const selectedTime = new Date(selectedDateTime);

    if (selectedTime < currentTime) {
      toast.error(
        language == "ar" ? "قم باختيار تاريخ  مستقبلي" : "Choose a future date"
      );
      return;
    }

    if (!newCoupon.use_limit) {
      toast.error(
        language == "ar"
          ? "قم بكتابة عدد مرات الاستخدام"
          : "Write the number of times used"
      );
      return;
    }

    if (!newCoupon.val) {
      toast.error(
        language == "ar" ? "قم بكتابة قيمة الخصم " : "Write the discount value"
      );
      return;
    }

    if (isPresentage && +newCoupon.val > +100) {
      toast.error(
        language == "ar"
          ? " لا يكمن ان تكون النسبة الائوية أكبر من 100% "
          : "The percentage cannot be greater than 100%."
      );
      return;
    }

    const selectedUsersInSeparator = selectedUsers.join("**green**");

    const dataset = {
      ...newCoupon,
      expired_date: selectedDateTime.split("T").join(" "),
      type: isPresentage ? "percentage" : "flat",
      coupon_type: forUsers ? "private" : "public",
      users: selectedUsersInSeparator,
      code: newCoupon?.generatedCode
        ? `${newCoupon?.prefix}_${newCoupon?.generatedCode}`
        : newCoupon?.prefix,
    };

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}coupons/make_coupon?token=${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getCoupons();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
        setSelectedUsers([]);
        setNewCopon({
          expired_date: "",
          use_limit: "",
          val: "",
          type: "",
        });
        setSelectedDateTime(null);
      });
  };
  const handleEditCoupon = async () => {
    const currentTime = new Date();
    const selectedTime = new Date(selectedDateTime);

    if (selectedTime < currentTime) {
      toast.error(
        language == "ar" ? "قم باختيار تاريخ  مستقبلي" : "Choose a future date"
      );
      return;
    }

    // if (!newCoupon.use_limit) {
    //   toast.error(
    //     language == "ar"
    //       ? "قم بكتابة عدد مرات الاستخدام"
    //       : "Write the number of times used"
    //   );
    //   return;
    // }

    // if (!newCoupon.val) {
    //   toast.error(
    //     language == "ar" ? "قم بكتابة قيمة الخصم " : "Write the discount value"
    //   );
    //   return;
    // }

    if (isPresentage && +showEditModal.val > +100) {
      toast.error(
        language == "ar"
          ? " لا يكمن ان تكون النسبة الائوية أكبر من 100% "
          : "The percentage cannot be greater than 100%."
      );
      return;
    }

    const selectedUsersInSeparator = selectedUsers.join("**green**");

    const dataset = {
      ...showEditModal,
      expired_date: selectedDateTime.split("T").join(" "),
      type: isPresentage ? "percentage" : "flat",
      coupon_type: "public",
      code: showEditModal?.code,
    };

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(
        `${BASE_URL}coupons/edit_coupon/${showEditModal?.id}?token=${token}`,
        dataset,
        {
          headers: {
            lang: language,
          },
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getCoupons();
          setShowEditModal(null);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
        setSelectedUsers([]);
        setNewCopon({
          expired_date: "",
          use_limit: "",
          val: "",
          type: "",
        });
        setSelectedDateTime(null);
      });
  };
  const handleDeleteCoupon = async () => {
   
    const dataset = {
      ...showDeleteModal,
 
    };

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .get(
        `${BASE_URL}coupons/delete_coupon/${showDeleteModal?.id}?token=${token}`,
        {
          headers: {
            lang: language,
          },
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getCoupons();
          setShowDeleteModal(null);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
       
        setAddLoading(false);
   

      });
  };const handleAssignToUsers = async () => {
    if (selectedUsers.length < 1) {
      toast.error(
        language == "ar" ? " قم باختيار مستخدمين أولً " : "Select any user"
      );
      return;
    }

    const dataset = {
      coupon_id: rowData.id,
      users: selectedUsers.map((user) => user?.value).join("**green**"),
    };

    console.log();
    // return

    const token = localStorage.getItem("GreenTreesAdminToken");

    console.log(dataset);

    setAssignLoading(true);
    await axios
      .post(`${BASE_URL}user_coupon/assign_to_user?token=${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getCoupons();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setSelectedUsers([]);
        setNewCopon({
          expired_date: "",
          use_limit: "",
          val: "",
          type: "",
        });
        setSelectedDateTime(null);
        setAssignModal(false);
        setAssignLoading(false);
      });
  };

  const header = [
    {
      label: language == "ar" ? "الكود" : "Code",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigator.clipboard.writeText(row.code);
              toast.success("تم نسخ الكوبون بنجاح");
            }}
            className="text-primary fw-bolder fs-5"
          >
            {row.code}
          </div>
        );
      },
    },
    {
      label:
        language == "ar"
          ? "تم استخدامه (عدد المرات)"
          : "Used (Number Of times)",
      dataIndex: "mush_used",
    },
    {
      label:
        language == "ar"
          ? "عدد المرات المسموح باستخدامه"
          : "Number of times allowed to be used",
      dataIndex: "use_limit",
    },
    {
      label: language == "ar" ? " قيمة الخصم" : "Discount Value",
      dataIndex: "val",
    },
    {
      label: language == "ar" ? " نوع الخصم" : "Discount Type",
      dataIndex: "",
      type: "children",
      children: ({ row }) => {
        return (
          <div>
            {row.type == "flat"
              ? language == "ar"
                ? "قيمة"
                : "Value"
              : language == "ar"
              ? "نسبة مئوية"
              : "Percentage"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "تاريخ الانتهاء" : "Expiry Date",
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        const formattedHours = moment(
          row.expired_date.split(" ")[1],
          "HH:mm"
        ).format("hh:mm A");
        return (
          <div className="d-flex align-items-center gap-3">
            <div className="">
              {getOlnlyDate(row.expired_date).split(" ")[0]}
            </div>
            <div className="  border border-2 rounded py-1 px-3 d-inline">
              {formattedHours.split(" ")[0]}{" "}
              {formattedHours.split(" ")[1] == "PM"
                ? language == "ar"
                  ? " مساءاً "
                  : "Evening"
                : language == "ar"
                ? " صباحاً  "
                : "Morning"}
            </div>
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "تاريخ الانشاء" : "Date Of Creation",
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        return <div>{getOlnlyDate(row.created_at)}</div>;
      },
    },

    {
      label: language == "ar" ? " أوامر" : "Action",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEditModal(row);
                setSelectedDateTime(row?.expired_date);
                setIsPresentage(row.type != "flat");
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowDeleteModal(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "حذف" : "Delete"}
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((item) => {
          if (
            searchValue.length >= 1 &&
            !item.id
              .toString()
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) &&
            !item.code
              .toString()
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase()) &&
            !item.val
              .toString()
              ?.toLowerCase()
              ?.includes(searchValue?.toLowerCase())
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      {/* <input type="datetime-local" value={selectedDateTime} onChange={handleDateTimeChange} /> */}

      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5> {language == "ar" ? "الكوبونات" : "Coupons"} </h5>
            <div
              onClick={() => setShowAddModal(true)}
              className="btn btn-success"
            >
              {language == "ar" ? "إضافة بطاقة خصم" : "Add Copoun"}
            </div>
          </div>

          <div className="d-flex align-items-center gap-4">
            <div className="searchInput field_input">
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                placeholder={language == "ar" ? "بحث..." : "Search..."}
                onChange={(e) => {
                  setSearchValue(e.target.value?.toLowerCase());
                }}
              />
            </div>

            {/* <div className='searchInput field_input'>
              <select value={couponsType} name='' id='' onChange={()=>setCouponsType()}>
                <option value='0'>أختر نوع الكوبون</option>
                <option value='1'>كوبونات للمستخدمين</option>
                <option value='2'>كوبونات عامة</option>
              </select>
            </div> */}
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout
              headers={header}
              data={data?.sort(
                (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
              )}
            />
          )}
        </div>
      </div>

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        onClose={() => {
          setSelectedUsers([]);
          setNewCopon({
            expired_date: "",
            use_limit: "",
            val: "",
            type: "",
          });
          setSelectedDateTime(null);
        }}
        title={language == "ar" ? "إضافة كوبون جديد " : "Add New Coupon"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewCoupon();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "تاريخ الانتهاء" : "Expiry Date"}
                </label>

                <input
                  type="datetime-local"
                  value={selectedDateTime || ""}
                  onChange={handleDateTimeChange}
                />

                {/* <input
                  type='text'
                  onChange={(e) => {
                    setNewSubCat({
                      ...newSubCat,
                      title_en: e.target.value,
                    });
                  }}
                /> */}
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "عدد مرات الاستخدام"
                    : "Number of times of use available"}
                </label>

                <input
                  onWheel={(e) => e.target.blur()}
                  value={newCoupon.use_limit}
                  type="number"
                  onChange={(e) => {
                    setNewCopon({
                      ...newCoupon,
                      use_limit: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "نوع الخصم" : "Discount Type"}
                </label>
                <div className="d-flex align-items-center gap-4 ">
                  <label htmlFor="">
                    {isPresentage ? (
                      <>
                        {" "}
                        {language == "ar"
                          ? "الخصم كنسبة مئوية"
                          : "Discount As a Percentage"}
                      </>
                    ) : (
                      <>
                        {language == "ar"
                          ? "الخصم  كاش"
                          : "Minimum amount to apply coupons"}
                      </>
                    )}
                  </label>
                  <div class="toggle-switch">
                    <input
                      checked={isPresentage}
                      class="toggle-input"
                      id="prod_name"
                      type="checkbox"
                      onChange={(e) => {
                        setIsPresentage(e.target.checked);
                      }}
                    />
                    <label class="toggle-label" for="prod_name"></label>
                  </div>
                </div>
                <input
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  onChange={(e) => {
                    setNewCopon({
                      ...newCoupon,
                      val: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "بادئة الكود" : "Code prefix"}
                </label>
                <div className="d-flex align-items-center gap-3">
                  <input
                    value={newCoupon?.prefix}
                    type="text"
                    onChange={(e) => {
                      setNewCopon({
                        ...newCoupon,
                        prefix: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    disabled
                    readOnly
                    value={newCoupon?.generatedCode}
                  />
                  <div className="btn btn-primary" onClick={generatingCode}>
                    {language == "ar" ? "إنشاء " : "Generate"}
                  </div>
                </div>
                {newCoupon?.generatedCode && newCoupon.prefix ? (
                  <div
                    className="text-center fs-4"
                    style={{ color: "#07863F" }}
                  >
                    {newCoupon.prefix + "_" + newCoupon?.generatedCode}
                  </div>
                ) : null}
              </div>
            </div>

            {forUsers && (
              <div className="my-3">
                <Select
                  // defaultValue={}
                  isMulti
                  onChange={handleMultiSelectChnage}
                  name="colors"
                  options={users?.map((user) => {
                    return { value: user.id, label: user.name };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            )}

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={assignModal}
        onClose={() => {
          setSelectedUsers([]);
          setNewCopon({
            expired_date: "",
            use_limit: "",
            val: "",
            type: "",
          });
          setSelectedDateTime(null);
        }}
        setOpen={setAssignModal}
        title={
          language == "ar"
            ? ` إسناد الكوبون للمستخدمين `
            : "Assign Coupons To Users"
        }
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAssignToUsers();
            }}
          >
            <div className={`multi_select ${isOpenSelect ? "open" : "close"}`}>
              <div className="my-2 py-2">
                <Select
                  // defaultValue={}
                  isMulti
                  onChange={handleMultiSelectChnage}
                  name="users"
                  options={users?.map((user) => {
                    return { value: user.id, label: user.name };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={selectedUsers}
                  onMenuOpen={() => setIsOpenSelect(true)}
                  onMenuClose={() => setIsOpenSelect(false)}
                  placeholder={
                    language == "ar" ? "اختر مستخدمين" : "selct users..."
                  }
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {assignLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />

      <PopUp
        open={showEditModal}
        setOpen={setShowEditModal}
        onClose={() => {
          setSelectedUsers([]);
          setNewCopon({
            expired_date: "",
            use_limit: "",
            val: "",
            type: "",
          });
          setSelectedDateTime(null);
        }}
        title={language == "ar" ? "تعديل الكوبون" : "Edit Coupon"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleEditCoupon();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "تاريخ الانتهاء" : "Expiry Date"}
                </label>

                <input
                  type="datetime-local"
                  value={selectedDateTime || ""}
                  onChange={handleDateTimeChange}
                />

                {/* <input
                  type='text'
                  onChange={(e) => {
                    setNewSubCat({
                      ...newSubCat,
                      title_en: e.target.value,
                    });
                  }}
                /> */}
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "عدد مرات الاستخدام"
                    : "Number of times of use available"}
                </label>

                <input
                  onWheel={(e) => e.target.blur()}
                  value={showEditModal?.use_limit}
                  type="number"
                  onChange={(e) => {
                    setShowEditModal({
                      ...showEditModal,
                      use_limit: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "نوع الخصم" : "Discount Type"}
                </label>
                <div className="d-flex align-items-center gap-4 ">
                  <label htmlFor="">
                    {isPresentage ? (
                      <>
                        {" "}
                        {language == "ar"
                          ? "الخصم كنسبة مئوية"
                          : "Discount As a Percentage"}
                      </>
                    ) : (
                      <>
                        {language == "ar"
                          ? "الخصم  كاش"
                          : "Minimum amount to apply coupons"}
                      </>
                    )}
                  </label>
                  <div class="toggle-switch">
                    <input
                      checked={isPresentage}
                      class="toggle-input"
                      id="prod_name"
                      type="checkbox"
                      onChange={(e) => {
                        setIsPresentage(e.target.checked);
                      }}
                    />
                    <label class="toggle-label" for="prod_name"></label>
                  </div>
                </div>
                <input
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  value={showEditModal?.val}
                  onChange={(e) => {
                    setShowEditModal({
                      ...showEditModal,
                      val: e.target.value,
                    });
                  }}
                />
              </div>

              {/* <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "بادئة الكود" : "Code prefix"}
                </label>
                <div className="d-flex align-items-center gap-3">
                  <input
                    value={showEditModal?.prefix?.split()}
                    type="text"
                    onChange={(e) => {
                      setShowEditModal({
                        ...showEditModal,
                        prefix: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="text"
                    disabled
                    readOnly
                    value={showEditModal?.generatedCode}
                    
                  />
                  <div className="btn btn-primary" onClick={generatingCode_edit}>
                    {language == "ar" ? "إنشاء " : "Generate"}
                  </div>
                </div>
                {showEditModal?.generatedCode && showEditModal.prefix ? (
                  <div
                    className="text-center fs-4"
                    style={{ color: "#07863F" }}
                  >
                    {showEditModal.prefix + "_" + showEditModal?.generatedCode}
                  </div>
                ) : null}
              </div> */}
            </div>

            {forUsers && (
              <div className="my-3">
                <Select
                  // defaultValue={}
                  isMulti
                  onChange={handleMultiSelectChnage}
                  name="colors"
                  options={users?.map((user) => {
                    return { value: user.id, label: user.name };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            )}

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "حفظ" : "Save"}
            </button>
          </form>
        }
      />

      <PopUp
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
      
        title={language == "ar" ? "حذف الكوبون" : "Delete Coupon"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleDeleteCoupon();
            }}
          >
            <h1>{language =="ar" ? "هل أنت متأكد  ..؟":"Are You Sure ..?"}</h1>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default CouponsPage;
