import axios from "axios";
import React, {useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";
import Select from "react-select";
import "./style.css";
import {Loader} from "rsuite";
import {Axios} from "../../components/Axios";
import PopUp from "../../components/popup";
import UseGeneral from "../../customHooks/useGeneral";

const ProductsPage = () => {
  const {language} = UseGeneral();
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [productData, setProductData] = useState({});
  const [originalData, setOriginalData] = useState(null);
  const [showAddProd, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [currentNumber, setCurrentNumber] = useState(null);
  const [password, setPassword] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [hideMulti, setHideMulti] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [changeTradeStatus , setChangeTradeStatus]=useState(false)
  const [categoryId, setCategoryId] = useState("");
  const [subCatId, setSubCatId] = useState("");
  const [product_id, set_product_id] = useState({});
  const [allCats, setAllCats] = useState([]);
  const [subCats, setSubCats] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewer, setViewer] = useState(false);
  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };


  const [selectedAvatars , setSelectedAvatars] = useState("")

  const productsHeader = [
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      // sort: true,
      type: "children",
      children: ({headers, row}) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{width: "fit-content", cursor: "pointer"}}
            onClick={handleClick}
          >
            <input
              style={{width: "80px", cursor: "pointer"}}
              type='text'
              onChange={(e) =>
                setProductData({id: row?.id, number: e.target.value})
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({row}) => {
        return (
          <div className='d-flex align-items-center gap-4'>
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{cursor: "pointer"}}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className='btn btn-success'>
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className={`${
                row.hidden_trade == "0" ? "text-danger" : "text-success"
              }`}
              style={{cursor: "pointer"}}
              onClick={() => {
                setRowData(row);
                setChangeTradeStatus(true)
                console.log("jcmljfcljf");
                
              }}
            >
              <button className='btn btn-success'>
                {row.hidden_trade == "0"
                  ? language == "ar"
                    ? "اخفاء للتاجر"
                    : "Hide for trader"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // setImg(row.image)
                // console.log(row.image)
                // setChangeStatusModal(true);
                window.open(`/editporduct/${row.id}`, "_blanck", "noopener");
              }}
            >
              <button className='btn btn-success'>
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "اسم المنتج" : "Product Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    {
      label: language == "ar" ? "صور المنتج" : "Products Images",
      type: "children",
      children: ({row}) => {
        return (
          <div className='products_images'>
            {row?.images && row?.images?.length
              ? row?.images?.slice(0, row.id == selectedAvatars? row?.images?.length : 3).map((item, index) => {
                  return (
                    <div className='prod_avatar'>
                      <img
                        src={item?.url}
                        alt=''
                        onClick={() =>{ setViewer(item?.url); console.log(row)}}
                      />
                    </div>
                  );
                })
              : null}

            {
              row.id == selectedAvatars || row?.images?.length > 3  && 
              <div onClick={() =>{ setSelectedAvatars(row?.id); console.log(row)}} className='prod_avatar show_more_avatar'>
                    +{row?.images?.length  - 3}
              </div>
            }
          </div>

          // <div style={{ display: "flex" }}>
          //   {row?.images && row?.images?.length
          //     ? row?.images?.map((item) => {
          //         return (
          //           <img
          //             role="button"
          //             src={item?.url}
          //             width={200}
          //             height={100}
          //             style={{
          //               margin: '20px',
          //               cursor: "pointer",
          //               objectFit: "contain",
          //             }}
          //             onClick={() => setViewer(item?.url)}
          //           />
          //         );
          //       })
          //     : null}
          // </div>
        );
      },
    },
    {
      label: language == "ar" ? "سعر الجملة " : "Trade Price ",
      dataIndex: "trade_price",
      children:({row})=>{
        return (
          <div style={{fontSize:'15px'}}>{row?.trade_price}</div>
        )
      }
    },
    {
      label: language == "ar" ? "سعر القطاعي " : "Sectoral Price ",
      dataIndex: "price",
      children:({row})=>{
        return (
          <div style={{fontSize:'15px'}}>{row?.price}</div>
        )
      }
    },
    {
      label: language == "ar" ? " الخصم" : "Discount",
      dataIndex: "discount",
      children:({row})=>{
        return (
          <div style={{fontSize:'15px'}}>{row?.discount}</div>
        )
      }
    },
    {
      label: language == "ar" ? " الباركود" : "Barcode",
      dataIndex: "Barcode",
      children:({row})=>{
        return (
          <div style={{fontSize:'15px'}}>{row?.Barcode}</div>
        )
      }
    },
    // {
    //   label: language == "ar" ? "المخزن" : "Stock",
    //   dataIndex: "quantity",
    //   children:({row})=>{
    //     return (
    //       <div style={{fontSize:'15px'}}>{row?.quantity}</div>
    //     )
    //   }
    // },

    {
      label: language == "ar" ? " حالة الظهور للمستخدم " : "Appearance Status for user",
      dataIndex: "num_order",
      type: "children",
      children: ({row}) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
            style={{fontSize:'15px'}}
          >
            {row.hidden == "0"
              ? language == "ar"
                ? "ظاهر"
                : "apparent"
              : language == "ar"
              ? "مخفي"
              : "Hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " حالة الظهور للتاجر " : "Appearance Status for trader",
      dataIndex: "num_order",
      type: "children",
      children: ({row}) => {
        return (
          <div
            className={`${row.hidden_trade == "0" ? "text-success" : "text-danger"}`}
            style={{fontSize:'15px'}}
          >
            {row.hidden_trade == "0"
              ? language == "ar"
                ? "ظاهر"
                : "apparent"
              : language == "ar"
              ? "مخفي"
              : "Hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " الفئة الفرعية" : "Subcategory",
      type: "children",
      children: ({row}) => {
        return (
          <div style={{fontSize:'15px'}}>
            {row?.subcategoryproduct
              ?.map((item , index) => {
                return <div className="" style={{fontSize:'15px'}}>
                  {index+1} - {  language == "ar"
                      ? item?.subcategory?.title_ar
                      : item?.subcategory?.title_en
                  }

                </div>
                
              })
             }
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "اقل قيمة للتاجر" : "lowest trader quantity",
      dataIndex: "trade_min_qty",
      children:({row})=>{
        return (
          <div style={{fontSize:'15px'}}>{row?.trade_min_qty}</div>
        )
      }
    },
   
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({row}) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },
  
  ];

  const getAdminProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setProducts(res?.data?.result?.filter((item) => item?.hidden == "0"));
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setAllCats(res?.data?.result?.filter((item) => item?.hidden == "0"));
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(
            language == "ar"
              ? "هناك مشكلة في تحميل الفئات"
              : "Something Went Error"
          );
        }
      })
      .catch((e) => {
        console.log(e);
        setAllCats([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "products/change_product_number/" + video_id,
      data: {number: video_number},
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getCategories();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  const [state, setState] = useState("-1");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedSubCat, setSelectedSubCat] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  useEffect(() => {
    getAdminProducts();
    getCategories();
  }, []);

  useEffect(() => {
    if (
      originalData &&
      originalData?.length >= 1 &&
      Array.isArray(originalData)
    ) {
      if (
        searchValue.length > 0 ||
        state != "-1" ||
        selectedCat != "" ||
        selectedSubCat != "" ||
        dateTo != "" ||
        dateFrom != ""
      ) {
        const newData = originalData.filter((prod) => {
          const prodDate = new Date(prod.created_at);
          const fromDate = new Date(dateFrom);
          const toDate = new Date(dateTo);
          console.log(prod, dateFrom, dateTo);
          if (
            !prod?.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            searchValue &&
            searchValue?.length &&
            !prod.id.toString()?.toLowerCase()?.includes(searchValue) &&
            !prod?.title_en?.toLowerCase()?.includes(searchValue) &&
            !prod.price.toString()?.toLowerCase()?.includes(searchValue) &&
            !prod.num_order.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }

          if (state != "-1" && !prod.hidden.includes(state)) {
            return false;
          }
          if (
            selectedCat != "" &&
            !prod.subcategory.category.id.toString().includes(selectedCat)
          ) {
            return false;
          }
          if (
            selectedSubCat != "" &&
            !prod.subcategory.id.toString().includes(selectedSubCat)
          ) {
            return false;
          }

          if (dateFrom != "" && !(prodDate >= fromDate)) {
            return false;
          }
          if (dateTo != "" && !(prodDate <= toDate)) {
            return false;
          }

          return true;
        });
        setProducts(newData);
      } else {
        setProducts(originalData);
      }
    }
  }, [
    searchValue,
    state,
    originalData,
    selectedCat,
    selectedSubCat,
    dateTo,
    dateFrom,
  ]);
  // ======================---------------------- New Features ------------------------==================
  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/${id}?token=${token}`
      )
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setSubCats(res?.data?.result?.filter((item) => item?.hidden == "0"));
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          setSubCats([]);
        } else {
          setSubCats([]);
        }
      })
      .catch((e) => {
        console.log(e);
        setSubCats([]);
      })
      .finally(() => {});
  };
  useEffect(() => {
    setSelectedSubCat("");
    if (selectedCat) {
      getSubCategories(selectedCat);
    } else {
      setSubCats([]);
    }
  }, [selectedCat]);
  const [selectedRows, setSelectedRows] = useState(null);

 
  const handleshow_hideForTrader = ()=>{
    const token = localStorage.getItem("GreenTreesAdminToken");

    axios.get(BASE_URL + `products/change_trade_hidden/${rowData.id}?token=${token}`)
    .then(res =>{
      console.log(res);
      if(res.data.status == "success"){
        console.log("suc");
        toast.success(res.data.result)
        getAdminProducts();

        
      } else if(res.data.status == "faild"){
        toast.error(res.data.result)
      } else{
        toast.error("حدث خطء ما ")
      }
    }) .finally(
      setChangeTradeStatus(false)
    ).catch(e=> console.log(e)
    )
  }






  const handleShow_hide = async (isArray) => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    setChangeStatusLoading(true);
    if (isArray && selectedRows && selectedRows?.length) {
      selectedRows.map(async (item) => {
        await axios
          .post(
            `${BASE_URL}products/update_status?token=${token}`,
            {
              id: item?.id,
            },
            {
              headers: {
                lang: language,
              },
            }
          )
          .then((res) => {
            if (res?.data && res?.data?.status == "success") {
              toast.success(res.data.message);
              getAdminProducts();
              setHideMulti(false);
            } else if (
              res.data.status == "error" ||
              res.data.status == "faild"
            ) {
              toast.error(res.data.message);
            } else {
              toast.error(
                language == "ar" ? "حدث خطأ ما" : "Something Went Error"
              );
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setChangeStatusModal(false);
            setChangeStatusLoading(false);
            setRowData({});
          });
      });
    } else {
      const dataset = {
        id: rowData.id,
      };
      await axios
        .post(`${BASE_URL}products/update_status?token=${token}`, dataset, {
          headers: {
            lang: language,
          },
        })
        .then((res) => {
          if (res?.data && res?.data?.status == "success") {
            toast.success(res.data.message);
            getAdminProducts();
          } else if (res.data.status == "error" || res.data.status == "faild") {
            toast.error(res.data.message);
          } else {
            toast.error(
              language == "ar" ? "حدث خطأ ما" : "Something Went Error"
            );
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setChangeStatusModal(false);
          setChangeStatusLoading(false);
          setRowData({});
        });
    }
  };
  const [quantityMulti, setQuantityMulti] = useState(false);
  const [changeMultiQuantity, setChangeMultiQuantity] = useState(0);
  const [subCatsMulti, setSubCatsMulti] = useState(false);
  const [changeMultiSubCats, setChangeMultiSubCats] = useState([]);
  const [categories, setCategories] = useState(null);


  const handleChangeMultiSubCats = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    console.log(selectedRows);
    if (selectedRows && selectedRows.length) {
      setChangeStatusLoading(true);

      try {
        // Create an array of promises
        const updatePromises = selectedRows.map(async (item) => {
          const product = {
            title_ar: item?.title_ar,
            title_en: item?.title_en,
            description_ar: item?.description_ar,
            description_en: item?.description_en,
            price: parseFloat(item?.price),
            discount: parseFloat(item?.discount),
            images: item?.images.map((img) => img.url).join("**green**"),
            subcategory_ids:
              item?.subcategoryproduct && item?.subcategoryproduct?.length
                ? [
                    item?.subcategoryproduct
                      ?.map((subItem) => subItem.subcategory_id)
                      ?.join("**green**"),
                    changeMultiSubCats
                      ?.map((item) => item?.value)
                      ?.join("**green**"),
                  ]?.join("**green**")
                : changeMultiSubCats
                    ?.map((item) => item?.value)
                    ?.join("**green**"),
            has_options: item?.has_options,
            id: item?.id,
            Barcode: item?.Barcode,
            policies: item?.returnpolicies
              ?.map((policy) => policy.policy_id)
              .join("**green**"),
            category_id: item?.subcategory?.category_id,
            price_for: item?.price_for,
            quantity: parseInt(item?.quantity),
            weights: item?.productweights
              ?.map((weight) => `${weight.weight}**green**${weight.price}`)
              .join("**green**"),
          };

          const response = await axios.post(
            `${BASE_URL}products/update_product?token=${token}`,
            product,
            {
              headers: {
                "Content-Type": "application/json",
                lang: language,
              },
            }
          );
          return response.data; // Extracting data from response
        });

        // Resolve all promises
        const results = await Promise.all(updatePromises);
        console.log(results);

        // Handle results
        results.forEach((res) => {
          if (res?.status === "success") {
            toast.success(res.message);
            getAdminProducts();
            setHideMulti(false);
          } else if (res.status === "error" || res.status === "faild") {
            toast.error(res.message);
          } else {
            toast.error(
              language === "ar" ? "حدث خطأ ما" : "Something Went Error"
            );
          }
        });
      } catch (error) {
        console.error(error);
        toast.error(language === "ar" ? "حدث خطأ ما" : "Something Went Error");
      } finally {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      }
    }
  };
  const getAllSubCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    if (!subCatsMulti) {
      setChangeMultiSubCats([]);
    } else {
      getAllSubCategories();
    }
  }, [subCatsMulti]);


  const handleChangeMultiQuantity = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    if (selectedRows && selectedRows.length) {
      setChangeStatusLoading(true);

      try {
        // Create an array of promises
        const updatePromises = selectedRows.map(async (item) => {
          const product = {
            title_ar: item?.title_ar,
            title_en: item?.title_en,
            description_ar: item?.description_ar,
            description_en: item?.description_en,
            price: parseFloat(item?.price),
            discount: parseFloat(item?.discount),
            images: item?.images.map((img) => img.url).join("**green**"),
            subcategory_ids: item?.subcategoryproduct
              ?.map((subItem) => subItem.subcategory_id)
              ?.join("**green**"),
            has_options: item?.has_options,
            id: item?.id,
            Barcode: item?.Barcode,
            policies: item?.returnpolicies
              ?.map((policy) => policy.policy_id)
              .join("**green**"),
            category_id: item?.subcategory?.category_id,
            price_for: item?.price_for,
            quantity: parseInt(quantityMulti),
            weights: item?.productweights
              ?.map((weight) => `${weight.weight}**green**${weight.price}`)
              .join("**green**"),
          };

          const response = await axios.post(
            `${BASE_URL}products/update_product?token=${token}`,
            product,
            {
              headers: {
                "Content-Type": "application/json",
                lang: language,
              },
            }
          );
          return response.data; // Extracting data from response
        });

        // Resolve all promises
        const results = await Promise.all(updatePromises);
        console.log(results);

        // Handle results
        results.forEach((res) => {
          if (res?.status === "success") {
            toast.success(res.message);
            getAdminProducts();
            setHideMulti(false);
          } else if (res.status === "error" || res.status === "faild") {
            toast.error(res.message);
          } else {
            toast.error(
              language === "ar" ? "حدث خطأ ما" : "Something Went Error"
            );
          }
        });
      } catch (error) {
        console.error(error);
        toast.error(language === "ar" ? "حدث خطأ ما" : "Something Went Error");
      } finally {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      }
    }
  };

  const handleEditMulti = () => {
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      // return
      selectedRows.forEach((item, index) => {
        setTimeout(() => {
          console.log(index);
          window.open(
            `/editporduct/${item?.id}`,
            "_blank",
            "noopener,noreferrer"
          );
        }, index * 500);
      });
    }
  };
  const [deletedData, setDeleteData] = useState(false);
  
  const deleteCategory = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    setLoader(true);
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      selectedRows.forEach(async (item) => {
        await axios
          .get(`${BASE_URL}products/delete/${item.id}?token=${token}`)
          .then((res) => {
            toast.success(res.message);
            getAdminProducts();
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setLoader(false);
          });
      });
    }
  };
  // ======================---------------------- New Features ------------------------==================
  return (
    <>
      <div className='rowDiv flex-2-1 page_padding'>
        <div>
          <div className='title_add'>
            <h5>{language == "ar" ? "المنتجات" : "Products"}</h5>
            <div
              className='btn btn-success'
              onClick={() => window.open("/addproduct", "_blanck")}
            >
              {language != "ar" ? "Add New Product" : "إضافة منتج جديد"}
            </div>
          </div>

          <div className='row gy-3 mb-3'>
            <div className='col-md-4'>
              <label htmlFor=''>{language == "ar" ? "بحث" : "Search"}</label>
              <input
                type='text'
                placeholder={language == "ar" ? "البحث..." : "Search..."}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value?.toLowerCase())}
              />
            </div>
            {/* <div className="col-md-4">
              <label className="d-block" htmlFor="">
                {language == 'ar' ? "حالة الظهور" : "Status"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option value="-1">
                  {language == 'ar' ? "اختر حالة الظهور" : "Select Status"}
                </option>
                <option value="0">
                  {language == 'ar' ? "ظاهر" : "apparent"}
                </option>
                <option value="1">
                  {language == 'ar' ? "مخفي" : "hidden"}
                </option>
              </select>
            </div> */}
            {/* <div className="col-md-4">
              <label htmlFor="">
                {language == 'ar' ? "الفئة" : "Category"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedCat(e.target.value)}
              >
                <option value={""}>
                  {language == 'ar' ? "اختر فئة..." : "Select Category"}
                </option>
                {allCats && allCats?.length >= 1
                  ? allCats?.map((cat, index) => {
                      return (
                        <option value={cat.id}>
                          {language == 'ar' ? cat?.title_ar : cat?.title_en}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="">
                {language == 'ar' ? "الفئة الفرعية" : "Select SubCategory"}
              </label>
              <select
                name=""
                id=""
                onChange={(e) => setSelectedSubCat(e.target.value)}
              >
                <option disabled value={""}>
                  {language == 'ar'
                    ? "اختر فئة فرعية..."
                    : "Select Subcategory...."}
                </option>
                {subCats && subCats?.length >= 1
                  ? subCats?.map((subCat, index) => {
                      return (
                        <option key={index} value={subCat.id}>
                          {language == 'ar'
                            ? subCat?.title_ar
                            : subCat?.title_en}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div> */}
            <div className='col-md-4'>
              <label htmlFor=''>{language == "ar" ? "من" : "From"}:</label>
              <input
                type='date'
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </div>
            <div className='col-md-4'>
              <label htmlFor=''>{language == "ar" ? "إلى" : "to"}:</label>
              <input
                type='date'
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />
            </div>
          </div>
          {selectedRows && selectedRows?.length ? (
            <div className='rowDiv'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  handleEditMulti();
                }}
              >
                {language == "ar" ? "تعديل" : "Edit"}
              </button>{" "}
              <button
                className='btn btn-danger'
                onClick={() => {
                  setHideMulti(true);
                }}
              >
                {language == "ar" ? "إخفاء" : "Hide"}
              </button>
              {/* <button
                className='btn btn-primary'
                onClick={() => {
                  setChangeMultiQuantity(true);
                }}
              >
                {language == "ar" ? "تعديل الكمية" : "Change Quantity"}
              </button>{" "} */}
              <button
                className='btn btn-primary'
                onClick={() => {
                  setSubCatsMulti(true);
                }}
              >
                {language == "ar" ? "تعديل الفئات" : "Change Sub-Category"}
              </button>{" "}
              <button
                className='btn btn-danger'
                style={{cursor: "pointer", margin: "20px"}}
                onClick={() => {
                  setDeleteData(true);
                }}
              >
                {language == "ar" ? "حذف" : "Delete"}
              </button>
            </div>
          ) : null}
          {/* هنا يمكنك إضافة باقي عناصر التصفية كما تريد */}

          {dataLoading ? (
            <Loader size='md' />
          ) : (
            <TableLayout
              headers={productsHeader}
              data={products}
              setSelectedRows_t={setSelectedRows}
            />
          )}
        </div>
      </div>
      <PopUp
        open={viewer}
        setOpen={setViewer}
        title={""}
        children={
          <div className=''>
            {viewer && viewer && (
              <img
                style={{objectFit: "contain"}}
                src={viewer}
                width={300}
                height={300}
              />
            )}
          </div>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == "ar" ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className=''>
            <h5 className=''>
              {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
              {rowData.hidden == "0"
                ? language == "ar"
                  ? " إخفاء "
                  : "Hide"
                : language == "ar"
                ? " إظهار "
                : " Show "}
              {language == "ar" ? "هذا المنتج" : "This Product"}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className='popup_agree_btn'
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
       <PopUp
        open={changeTradeStatus}
        setOpen={setChangeTradeStatus}
        title={
          language == "ar" ? "تغيير حالة الظهور للتاجر" : "Change Appearance Status"
        }
        children={
          <div className=''>
            <h5 className=''>
              {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
              {rowData.hidden_trade == "0"
                ? language == "ar"
                  ? " إخفاء "
                  : "Hide"
                : language == "ar"
                ? " إظهار "
                : " Show "}
              {language == "ar" ? "هذا المنتج" : "This Product"}
            </h5>

            <button
              onClick={() => handleshow_hideForTrader()}
              className='popup_agree_btn'
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
      <PopUp
        open={hideMulti}
        setOpen={setHideMulti}
        title={
          language == "ar" ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className=''>
            <h5 className=''>
              {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
              {rowData.hidden == "0"
                ? language == "ar"
                  ? " إخفاء "
                  : "Hide"
                : language == "ar"
                ? " إظهار "
                : " Show "}
              {language == "ar" ? "هذه المنتجات " : "This Products"}
            </h5>

            <button
              onClick={() => handleShow_hide(true)}
              className='popup_agree_btn'
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
      <PopUp
        open={deletedData}
        setOpen={setDeleteData}
        title={""}
        children={
          <div className=''>
            <h5 className=''>
              {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
              {rowData.hidden == "0"
                ? language == "ar"
                  ? " حذف "
                  : "Delete"
                : language == "ar"
                ? " حذف "
                : " Delete "}
              {language == "ar" ? "هذه المنتجات " : "This Products"}
            </h5>
            <p>{language == "ar" ? "كلمة السر" : "Password"}</p>
            <input type='text' onChange={(e) => setPassword(e.target.value)} />
            <button
              onClick={() =>
                // password == "*green2024deletepassword*"
                   deleteCategory(true)
                  // : toast.error(
                  //     language == "ar"
                  //       ? "كلمة السر خاطئة "
                  //       : "Password  Is Wrong"
                  //   )
              }
              className='popup_agree_btn'
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />{" "}
      <PopUp
        open={changeMultiQuantity}
        setOpen={setChangeMultiQuantity}
        title={""}
        children={
          <div className=''>
            <p>{language == "ar" ? "الكمية الجديدة" : "New Quantity"}</p>
            <input
              type='number'
              onChange={(e) => setQuantityMulti(e.target.value)}
            />

            <button
              onClick={() => handleChangeMultiQuantity(true)}
              className='popup_agree_btn'
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
      <PopUp
        open={subCatsMulti}
        setOpen={setSubCatsMulti}
        title={""}
        children={
          <div className=''>
            <>
              <div className='field_input col-md-6'>
                <label htmlFor=''>
                  {language == "ar" ? "الفئة الفرعية" : "Subcategory "}
                </label>
                {/* <SelectWithSearch
                    options={subCategories}
                    getClientData={setSubCatData}
                    defaultValue={productData?.subcategory_id}
                  /> */}
                <Select
                  placeholder={
                    language == "ar" ? "الفئات الفرعيه" : "Subcategory"
                  }
                  isMulti
                  value={changeMultiSubCats}
                  onChange={(e) => {
                    setChangeMultiSubCats(e);
                  }}
                  // value={selectedSubCategories}
                  name='subcategories'
                  options={allCats?.map((item, index) => {
                    return {
                      label: language == "ar" ? item.title_ar : item?.title_en,
                      value: item.id,
                    };
                  })}
                  className='basic-multi-select'
                  classNamePrefix='select'
                />
              </div>
            </>

            <button
              onClick={() => handleChangeMultiSubCats(true)}
              className='popup_agree_btn'
            >
              {loader ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />{" "}
    </>
  );
};

export default ProductsPage;
