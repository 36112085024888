// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyBl4eLHFxHsaLC8Lhb-ZzV6rzaVnZzF5dE",
  authDomain: "hagrasy-40705.firebaseapp.com",
  projectId: "hagrasy-40705",
  storageBucket: "hagrasy-40705.appspot.com",
  messagingSenderId: "105378589179",
  appId: "1:105378589179:web:ae1351510c5e8e2f2b035e",
  measurementId: "G-R4JKKVZK6G",
  databaseURL: "https://hagrasy-40705-default-rtdb.firebaseio.com"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const firestore = getFirestore(app);
const database = getDatabase(app);
export { app, analytics, auth, firestore, database }; 
