import axios from "axios";
import { BASE_URL } from "../../components/Axios/base_uri";

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);
  let image = await axios.post(`${BASE_URL}upload_image`, formData);

  return image?.data?.result?.image;
};
