import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import ContactsPage from '../contactsPage/ContactsPage';
import DownloadsPage from "./DownloadsPage";
import UseGeneral from "../../customHooks/useGeneral";

const FooterDetailsPage = () => {
  const {language}=UseGeneral()
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue,  setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [img, setImg] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const [editLoading, setEditLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const getImages = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}payImg/get_all_for_admin?token=${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getImages();
  }, []);

  const handleAddFile = async () => {
    console.log("add file");

    if (!img) {
      toast.error(language=='ar'?"لم تقم بإضافة صورة":"Enter Image");
      return;
    }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && (res.status == 201||res.status == "success")) {
          // setImgUrl(res?.data?.message);
          handleAddNewPayImage(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload");
        } else {
          toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setImg('');
        setSelectedFile(null);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };

  const handleAddNewPayImage = async (image) => {
    const dataset = {
      image: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}payImg/add_new?token=${token}`, dataset,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getImages();
        } else if (res.data.status == "faild"||res.data.status == "erroe") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Somthing Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const header = [
    
    {
      label: language=='ar'?"الصورة":"Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.image} alt="image" />
          </div>
        );
      },
    },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language=='ar'?"الحالة":"Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language=='ar'?"ظاهر":"Showen" : language=='ar'?"مخفي":"Hidden"}
          </div>
        );
      },
    },
    {
      label: language=='ar'?" أوامر":"Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                // setImgUrl(row.image)
                setImgUrl(row.image);
                setImg(row.image);
                console.log(row);
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setChangeStatusLoading(true);
    await axios
      .get(`${BASE_URL}payImg/change_status/${rowData.id}?token=${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            `تم ${rowData.status == "0" ? language=='ar'?" إخفاء ":" Hide " : language=='ar'?" إظهار ":" Show "} الصورة بنجاح`
          );
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        getImages();
        setRowData({});
      });
  };

  const handleUploadImage = async () => {
    if (!selectedFile) {
      toast.error(language=='ar'?'قم بتعديل الصورة أولاً':"Change Image");
      return;
    }

    setUpdateLoading(true);

    console.log(selectedFile);
    console.log(img);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data != "" && (res.status == 201||res.status == 'success')) {
            // setImgUrl(res?.data?.message);
            console.log(res?.data?.result?.image);
            toast.success(language=='ar'?'تم رفع الصوره':"Success To Upload Image")
            handleUpdatePayImage(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload");
          } else {
            toast.error(language=='ar'?"هناك مشكلة في رفع الصورة":"Faild To Upload");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          setSelectedFile(null);
          setImg('');
          setImgUrl('');
        });
    } else {
      handleUpdatePayImage(rowData.image);
    }

    setUpdateLoading(false);
  };

  const handleUpdatePayImage = async (image) => {
    

    const dataset = {
      image: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    setEditLoading(true);
    await axios
      .post(
        `${BASE_URL}payImg/update_pay_img/${rowData.id}?token=${token}`,
        dataset,
        {
          headers:{
            lang:language
          }
        }
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getImages();
        } else if (res.data == ""||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?" هناك مشكلة في عملية التعديل  ":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
        setUpdateModal(false);
      });
  };

  return (
    <div>
      <h4 className="m-4">{language=='ar'?'بيانات الفوتر ':'Footer Data'}</h4>
      <ContactsPage />

      <hr />

      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language=='ar'?'صور الدفع':'Pay Images'}</h5>
            <div onClick={() => setShowAddSubCatModal(true)} className="btn btn-success">{language == 'ar' ? 'إضافة صورة' : 'Add Image'}</div>
          </div>

          {/* <div className='searchInput field_input'>
            <input
              type='text'
              placeholder='بحث...'
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div> */}

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={header} data={data} />
          )}
        </div>
      </div>
      <hr />
      <DownloadsPage/>
      <PopUp
        open={showAddSubCatModal}
        setOpen={setShowAddSubCatModal}
        onClose={() => {
          setImg('');
          setSelectedFile(null);
        }}
        title={language=='ar'?"إضافة صورة جديد ":'Add New Image'}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddFile();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language=='ar'?'اختر صورة الدفع':'Enter Pay Image'}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language=='ar'?"إضافة":"Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        onClose={() => {
          setImg('');
          setSelectedFile(null);
        }}
        title={language=='ar'?"تغيير حالة الظهور":"Change Appearance Status"}
        children={
          <div className="">
            <h5 className=" my-5">
              {language=='ar'?"هل تريد بالفعل ":"Do You Really Want To"}{rowData.status == "0" ? language=='ar'?" إخفاء ":" hide " : language=='ar'?" إظهار ":" Show "}
              {language=='ar'?' هذه الصوره ':' This Image'}
            </h5>

            <button
              onClick={() => {
                if (!changeStatusloading) {
                  handleShow_hide();
                }
              }}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : language=='ar'?"تأكيد":"Confirm"}
            </button>
          </div>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={language=='ar'?"تعديل صورة دفع جديدة":"Edit Pay Image"}
        onClose={() => {
          setImg('');
          setSelectedFile(null);
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUploadImage();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language=='ar'?"اختر صورة الدفع":"Enter Pay Image"}</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                        setSelectedFile(null);
                        console.log(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {editLoading ? <Loader /> : language=='ar'?"تعديل":"Edit"}
            </button>
          </form>
        }
      />
    </div>
  );
};

export default FooterDetailsPage;
