import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Loader, Toggle } from "rsuite";
import { Axios } from "../../components/Axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";
import PopUp from "./../../components/popup/index";
import "./style.css";
import ReactSelect from "react-select";

const Offers = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [offerss, setOfferss] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddCatModal, setShowAddCatModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showAddOffersModal, setShowAddOffersModal] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newOffers, setNewOffers] = useState({
    title_ar: "",
    title_en: "",
  });

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const getOfferss = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}offers/get_all_for_admin?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setOfferss(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => {
        setOfferss([]);
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  const [finalDraggedRow, setFinalDraggedRow] = useState(null);
  const [c_id, setId] = useState(null);
  const [draggedRow, setDraggedRow] = useState(null);
  useEffect(() => {
    if (finalDraggedRow && draggedRow) {
      console.log(finalDraggedRow, draggedRow);
      const draggedIndex = offerss?.findIndex(
        (item) => item?.id == draggedRow?.id
      );
      const draggedFinalIndex = offerss?.findIndex(
        (item) => item?.id == finalDraggedRow?.id
      );
      const draggedID = offerss?.filter(
        (item) => item?.id == finalDraggedRow?.id
      )[0]?.id;
      setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
      setArrangeNumber(draggedID, draggedFinalIndex + 1);
      // setArrangeNumber(
      //   finalDraggedRow?.id,
      //   draggedRow?.number,
      //   finalDraggedRow?.number
      // );
    }
  }, [finalDraggedRow]);
  useEffect(() => {
    if (draggedRow) {
      setCurrentNumber(draggedRow?.number);
      setId(draggedRow?.id);
    }
  }, [draggedRow]);
  const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    const num = currentNumberTwo ? currentNumberTwo : currentNumber;
    setLoader(true);
    if (video_number == num) {
      // alert(video_number, num);
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "offers/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getOfferss();
        setFinalDraggedRow(null);
        setDraggedRow(null);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  // const setArrangeNumber = async (video_id, video_number) => {
  //   console.log(video_number);
  //   console.log(video_id);
  //   // return
  //   setLoader(true);
  //   if (video_number == currentNumber) {
  //     return setLoader(false);
  //   }
  //   await Axios({
  //     method: "POST",
  //     url: "offers/change_number/" + video_id,
  //     data: { number: video_number },
  //   })
  //     .then(async (response) => {
  //       console.log(response);
  //       toast.success(response?.message);
  //       getOfferss();
  //     })
  //     .catch((error) => console.log(error))
  //     .finally(() => {
  //       setLoader(false);
  //     });
  // };

  useEffect(() => {
    getOfferss();
  }, []);

  const offerssHeaders = [
    {
      label: language == "ar" ? "سعر " : "Price",
      dataIndex: "value",
    },

    {
      label: language == "ar" ? "تاريخ البداية " : "Start Date",
      dataIndex: "start_date",
    },
    {
      label: language == "ar" ? "تاريخ النهاية " : "End Date",
      dataIndex: "end_date",
    },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            {/* <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div> */}
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                console.log("RowData=> ", {
                  ...row,
                  products_choosed: row?.offerproducts?.map((item) => ({
                    value: item?.product_id,
                    label:
                      language == "ar"
                        ? products?.filter(
                            (pItem) => pItem?.id == item?.product_id
                          )[0]?.title_ar
                        : products?.filter(
                            (pItem) => pItem?.id == item?.product_id
                          )[0]?.title_en,
                  })),
                  products: row?.offerproducts
                    ?.map((item) => item?.product_id)
                    ?.join("*green*"),
                  typed: row?.value_type != "flat",
                });
                setRowData({
                  ...row,
                  products_choosed: row?.offerproducts?.map((item) => ({
                    value: item?.product_id,
                    label:
                      language == "ar"
                        ? products?.filter(
                            (pItem) => pItem?.id == item?.product_id
                          )[0]?.title_ar
                        : products?.filter(
                            (pItem) => pItem?.id == item?.product_id
                          )[0]?.title_en,
                  })),
                  products: row?.offerproducts
                    ?.map((item) => item?.product_id)
                    ?.join("*green*"),
                  typed: row?.value_type != "flat",
                });
                setUpdateModal(true);
                console.log(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
            {/* <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/offerss/${row.id}`, { state: { offersData: row } });
                console.log(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "عرض" : "View"}
              </button>
            </div> */}
          </div>
        );
      },
    },
  ];

  const handleAddNewOffers = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      ...newOffers,
    };

    setAddLoading(true);
    await axios
      .post(`${BASE_URL}offers/add_new?token=${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getOfferss();
          setShowAddOffersModal(false);
          setNewOffers({
            title_ar: "",
            title_en: "",
          });
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "failed") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const handleEditOffers = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      ...rowData,
    };
    delete dataset?.offerproducts;

    setAddLoading(true);
    await axios
      .post(
        `${BASE_URL}offers/update_offer/${dataset?.id}?token=${token}`,
        dataset,
        {
          headers: {
            "Content-Type": "Aplication/Json",
            lang: language,
          },
        }
      )
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getOfferss();
          setShowAddOffersModal(false);
          setNewOffers({
            title_ar: "",
            title_en: "",
          });
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "failed") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setChangeStatusLoading(true);

    await axios
      .get(`${BASE_URL}offers/change_status/${rowData.id}?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getOfferss();
        } else if (res.data.status == "error" || res.data.status == "faidl") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const updateOffers = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      ...rowData,
    };

    setUpdateLoading(true);
    await axios
      .post(
        `${BASE_URL}offers/update_offer/${rowData.id}?token=${token}`,
        dataset
      )
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getOfferss();
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faidl") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setRowData({});

        setUpdateLoading(false);
      });
  };

  // filterations
  // filteraiton part

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((rej) => {
          if (
            searchValue.length >= 1 &&
            !rej.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            !rej.title_en.toLowerCase().includes(searchValue.toLowerCase()) &&
            !rej.id.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setOfferss(newData);
      } else {
        setOfferss(originalData);
      }
    }
  }, [searchValue]);
  const [products, setProducts] = useState(null);

  const getAdminProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setProducts(res?.data?.result?.filter((item) => item?.hidden == "0"));
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };
  useEffect(() => {
    getAdminProducts();
  }, []);
  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "ar" ? "عروض " : "Offers"}</h5>
            <div
              onClick={() => setShowAddOffersModal(true)}
              className="btn btn-success"
            >
              {language == "ar" ? "إضافة عرض" : "Add Offer"}
            </div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout
              headers={offerssHeaders}
              data={offerss}
              finalDraggedRow={finalDraggedRow}
              setFinalDraggedRow={setFinalDraggedRow}
              draggedRow={draggedRow}
              setDraggedRow={setDraggedRow}
            />
          )}
        </div>
      </div>

      <PopUp
        open={showAddOffersModal}
        setOpen={setShowAddOffersModal}
        title={language == "ar" ? "إضافة عرض جديدة" : "Add New Offer"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="field_input">
              <label htmlFor="">{language == "ar" ? "سعر " : "Price"}</label>
              <input
                type="text"
                onChange={(e) => {
                  setNewOffers({
                    ...newOffers,
                    value: e.target.value,
                  });
                }}
              />
            </div>
            <div className="field_input">
              <label htmlFor="">
                {language == "ar" ? "وقت بداية العرض " : "Start Date"}
              </label>
              <input
                type="datetime-local"
                onChange={(e) => {
                  setNewOffers({
                    ...newOffers,
                    start_date: e.target.value,
                  });
                }}
              />
            </div>

            <div className="field_input">
              <label htmlFor="">
                {language == "ar" ? "وقت نهاية العرض " : "End Date"}
              </label>
              <input
                type="datetime-local"
                onChange={(e) => {
                  setNewOffers({
                    ...newOffers,
                    end_date: e.target.value,
                  });
                }}
              />
            </div>
            <div className="field_input">
              <label htmlFor="">
                {language == "ar"
                  ? newOffers?.typed
                    ? "نسبة مئوية"
                    : "قيمة ثابته"
                  : newOffers?.typed
                  ? "Precentage"
                  : "Fixed Price"}
              </label>
              <Toggle
                onChange={(e) => {
                  setNewOffers({
                    ...newOffers,
                    typed: e,
                    value_type: e ? "percentage" : "flat",
                  });
                }}
              />
            </div>

            <div className="field_input">
              <label htmlFor="">
                {language == "en" ? "Products In Offer" : "المنتجات داخل العرض"}
              </label>
              <ReactSelect
                options={products?.map((item) => ({
                  label: language == "ar" ? item?.title_ar : item?.title_en,
                  value: item?.id,
                  id: item?.id,
                }))}
                isMulti={true}
                value={newOffers?.products_choosed}
                onChange={(e) => {
                  setNewOffers({
                    ...newOffers,
                    products_choosed: e?.value,
                    products: e?.map((item) => item?.value)?.join("*green*"),
                  });
                }}
              />
            </div>
            <button
              onClick={() => {
                if (!addLoading) {
                  handleAddNewOffers();
                }
              }}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={language == "ar" ? " تعديل عرض " : "Edit Offer"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="field_input">
              <label htmlFor="">{language == "ar" ? "سعر " : "Price"}</label>
              <input
                type="text"
                value={rowData?.value}
                onChange={(e) => {
                  setRowData({
                    ...rowData,
                    value: e.target.value,
                  });
                }}
              />
            </div>
            <div className="field_input">
              <label htmlFor="">
                {language == "ar" ? "وقت بداية العرض " : "Start Date"}
              </label>
              <input
                type="datetime-local"
                value={rowData?.start_date}
                onChange={(e) => {
                  setRowData({
                    ...rowData,
                    start_date: e.target.value,
                  });
                }}
              />
            </div>

            <div className="field_input">
              <label htmlFor="">
                {language == "ar" ? "وقت نهاية العرض " : "End Date"}
              </label>
              <input
                type="datetime-local"
                value={rowData?.end_date}
                onChange={(e) => {
                  setRowData({
                    ...rowData,
                    end_date: e.target.value,
                  });
                }}
              />
            </div>
            <div className="field_input">
              <label htmlFor="">
                {language == "ar"
                  ? rowData?.typed
                    ? "نسبة مئوية"
                    : "قيمة ثابته"
                  : rowData?.typed
                  ? "Precentage"
                  : "Fixed Price"}
              </label>
              <Toggle
                checked={rowData?.typed}
                onChange={(e) => {
                  setRowData({
                    ...rowData,
                    typed: e,
                    value_type: e ? "percentage" : "flat",
                  });
                }}
              />
            </div>

            <div className="field_input">
              <label htmlFor="">
                {language == "en" ? "Products In Offer" : "المنتجات داخل العرض"}
              </label>
              {console.log(rowData)}
              <ReactSelect
                options={products?.map((item) => ({
                  label: language == "ar" ? item?.title_ar : item?.title_en,
                  value: item?.id,
                  id: item?.id,
                }))}
                isMulti={true}
                value={rowData?.products_choosed}
                onChange={(e) => {
                  setRowData({
                    ...rowData,
                    products_choosed: e?.value,
                    products: e?.map((item) => item?.value)?.join("*green*"),
                  });
                }}
              />
            </div>
            <button
              onClick={() => {
                if (!addLoading) {
                  handleEditOffers();
                }
              }}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default Offers;
