import React, { useState } from "react";
import "./style.css";
import { DoubleBorderSpinner } from "../../components/loaders";
import { Axios } from "../../components/Axios";
import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";
const Login = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const {language,changeLanguage}=UseGeneral()
  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios({ url: "user/admin_login", method: "POST", data: userData })
      .then((res) => {
        console.log(res);
        if (res?.result) {
          toast.success("تم التسجيل بنجاح");
          localStorage.setItem("GreenTreesAdminToken", res?.result);
          window.location.href = "/";
        } else {
          toast.error("حدث خطأ أثناء تسجيل الدخول");
        }
      })
      .catch((err) => err)
      .finally(() => setLoading(false));
  };
  return (
    <div class="formcontainer">
      <div class="formcard">
          <img onClick={()=>{
            changeLanguage(language=='ar'?'en':'ar')
          }} src={require('../../assets/global.png')} alt="" />
        <h2>{language=='ar'?'تسجيل الدخول':'Login'}</h2>
        <form onSubmit={(e) => (loading ? e.preventDefault() : signIn(e))}>
          <label htmlFor="username">{language=='ar'?'البريد الالكتروني أو اسم المستخدم':'Email'}</label>
          <input
            type="text"
            id="username"
            name="username"
            required
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
          />

          <label htmlFor="password">{language=='ar'?'كلمة السر':'Password'}</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
          />

          {loading ? (
            <DoubleBorderSpinner />
          ) : (
            <button type="submit">{language=='ar'?'دخول':"Login"}</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
