import React, { useState } from "react";
import "./style.css"; // Import CSS file for styling
import UseGeneral from "../../customHooks/useGeneral";
import { FaBell } from "react-icons/fa"; // Import an icon from react-icons
import NotificationList from "../../components/header/notifications/NotificationList";

function DashboardHeader() {
  const { userData, logOut, language } = UseGeneral();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [unReadCount, setUnReadCount] = useState(0);
  const toggleNotificationList = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  return (
    <div className="dashboard-header">
      <div className="dropdown profile">
        <span>{userData?.data?.name}</span>
        <div
          className={
            language == "ar" ? "dropdown-content" : "dropdown-content rev"
          }
        >
          <ul>
            <li
              className="btn btn-danger"
              style={{ fontSize: "12px" }}
              onClick={() => logOut()}
            >
              {language == "ar" ? "تسجيل الخروج" : "log out"}
            </li>
          </ul>
        </div>
      </div>

      {/* Notification Icon */}
      <div>
        <div className="notification-icon" onClick={toggleNotificationList}>
          <FaBell size={24} color="green" />
          <sup>{unReadCount}</sup>
        </div>

        {/* Add the NotificationList component */}
        <NotificationList
          isOpen={isNotificationOpen}
          setUnReadCount={setUnReadCount}
        />
      </div>
    </div>
  );
}

export default DashboardHeader;
