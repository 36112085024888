import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { Loader } from "rsuite";
import { exitModal, plus } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import SelectWithSearch from "../../components/selectWithSearch/SelectWithSearch";
import "./style.css";

import toast from "react-hot-toast";
import UseGeneral from "../../customHooks/useGeneral";

const AddProductPage = () => {
  const location = useLocation();
  const { language } = UseGeneral();
  const subCatId = location?.state?.subCat;
  const subCatName = location?.state?.subCatName;
  const hasOptions = location?.state?.hasOptions;
  const [addLoading, setAddLoading] = useState(false);

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: hasOptions ? 1 : 0,
    count: "",
    pricePerUnit: "",
    trade_min_qty:"",
    category_id: location?.state?.category_id,
    // images:image1**green**image2**green**image3,
  });

  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [allProducts, setAllProducts] = useState(null);

  const [arIngs, setArIngs] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [isKilo, setIsKilo] = useState(false);

  const [steps, setSteps] = useState([
    {
      id: 1,
      ar_value: "",
      en_value: "",
      englishLang: false,
    },
  ]);

  const [products, setProducts] = useState([
    {
      id: 1,
      product_id: "",
      price: "",
      count: "",
    },
  ]);

  const [uploadImages, setUploadImages] = useState(false);

  const [policies, setPolicies] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const [weights, setWeights] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(
            res?.data?.result?.filter((item) => item?.hidden == "0")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          setCategories([]);
        } else {
          setCategories([]);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  const [data, setData] = useState({});
  const [subCatData, setSubCatData] = useState({});
  const [productData, setProdcutData] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  // const [hasOptions, setHasOptions] = useState(false);

  useEffect(() => {
    getCategories();
    getAllProducts();
    console.log(subCatId);
  }, []);
  console.log(location?.state);

  const getSubCategories = async (id) => {
    // /categories/category_subcategories_for_admin/1?
    console.log(location?.state);
    if (location?.state != null) {
      const token = localStorage.getItem("GreenTreesAdminToken");
      await axios
        .get(
          `${BASE_URL}categories/category_subcategories_for_admin/${location?.state?.category_id}?token=${token}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            setSubCategories(
              res?.data?.result?.filter((item) => item?.hidden == "0")
            );
            console.log(res.data.result);
          } else if (res.data.status == "error") {
            setSubCategories([]);
          } else {
            setSubCategories([]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    } else {
      const token = localStorage.getItem("GreenTreesAdminToken");
      await axios
        .get(
          `${BASE_URL}categories/category_subcategories_for_admin/${id}?token=${token}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            setSubCategories(
              res?.data?.result?.filter((item) => item?.hidden == "0")
            );
            console.log(res.data.result);
          } else if (res.data.status == "error") {
            setSubCategories([]);
          } else {
            setSubCategories([]);
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }
  };

  useEffect(() => {
    if (data) {
      getSubCategories(data?.id || []);
    } else {
      setSubCategories([]);
    }
  }, [data]);

  const getAllProducts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .get(`${BASE_URL}products/getAll_for_admin?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res.data.status == "success") {
          setAllProducts(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };

  useEffect(() => {
    console.log(productData);
    console.log(products);
  }, [productData]);

  const [selectedOptions, setSelectedOptions] = useState(null);

  const [lang, setLang] = useState({
    prod_name: false,
    prod_desc: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
    console.log(files);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleAddNewProduct = async () => {
  
    if (!newProd.price) {
      toast.error(
        language == "ar" ? "قم بإدخال سعر الوحدة " : "Enter Unit Price"
      );
      return;
    }

    if (+newProd.price < 0) {
      toast.error(
        language == "ar"
          ? " لا يمكن أن يكون السعر بقيمة سالبة "
          : "The price cannot be negative"
      );
      return;
    }

    if (newProd.discount && +newProd.discount > +newProd.price) {
      toast.error(
        language == "ar"
          ? " لا يمكن أن يكون  قيمة الخصم أكبر سعر الوحدة "
          : "The discount value cannot be greater than the unit price"
      );
      return;
    }

    if (newProd.discount && +newProd.discount < 0) {
      toast.error(
        language == "ar"
          ? " لا يمكن أن يكون  الخصم بقيمة سالبة "
          : "he discount cannot be a negative value"
      );
      return;
    }

    if (imagesUrl.length == 0) {
      toast.error(
        language == "ar"
          ? "قم بإضافة صور للمنتج"
          : "Add pictures of the product"
      );
      return;
    }

    if (hasOptions) {
      if (!arIngs.length >= 1) {
        toast.error(
          language == "ar"
            ? "قم بإدخال مُكوِن واحد على الأقل"
            : "Enter at least one ingredient"
        );
        return;
      }

      const ingsHasNoValues = arIngs.find((item) => {
        if (item.ar_value == "" || item.en_value == "") {
          return item;
        }
      });

      const stepsHasNoValues = steps.find((item) => {
        if (item.ar_value == "" || item.en_value == "") {
          return item;
        }
      });
      const productsHasNoValues = products.find((item) => {
        if (!item.id || !item.count || !item.price) {
          return item;
        }
      });

      if (!arIngs.length >= 1) {
        toast.error(
          language == "ar"
            ? "قم بإدخال مُكوِن واحد على الأقل"
            : "Enter at least one ingredient"
        );
        return;
      }
      if (!steps.length >= 1) {
        toast.error(
          language == "ar"
            ? "قم بإدخال خطوة تحضير واحدة على الأقل"
            : "Enter at least one preparation step"
        );
        return;
      }

      if (!products.length >= 1) {
        toast.error(
          language == "ar"
            ? "قم بإدخال منتج تحضير واحد على الأقل"
            : "Enter at least one preparation product"
        );
        return;
      }

      if (ingsHasNoValues) {
        toast.error(
          language == "ar"
            ? `المكون رقم ${ingsHasNoValues.id} مطلوب باللغة العربية والإنجليزية`
            : `Ingrediants Number ${ingsHasNoValues.id} Required In Arabic And English`
        );
        return;
      }

      if (stepsHasNoValues) {
        toast.error(
          language == "ar"
            ? `خطوة التحضير رقم ${stepsHasNoValues.id} مطلوبة باللغة العربية والإنجليزية`
            : `Step Number ${stepsHasNoValues.id} is Required In Arabic And English`
        );
        return;
      }

      if (productsHasNoValues) {
        toast.error(
          language == "ar"
            ? ` أكمل بيانات منتج التحضير رقم  ${productsHasNoValues.id} `
            : `Complete the preparation product information ${productsHasNoValues.id}`
        );
        return;
      }
    }

    setAddLoading(true);

    const images = imagesUrl.join("**green**");
    const ingrediants_en = hasOptions
      ? arIngs.map((ing) => ing.en_value).join("**green**")
      : "";
    const ingrediants_ar = hasOptions
      ? arIngs.map((ing) => ing.ar_value).join("**green**")
      : "";
    const productsData = hasOptions
      ? products
          .map((prod) => {
            return `${prod.product_id}*green*${prod.price}*green*${prod.count}`;
          })
          .join("**green**")
      : "";

    // const relatedProducts = selectedOptions.join("**green**");
    const policiesData = policies.map((pol) => pol.value).join("**green**");
    const steps_ar = hasOptions
      ? steps.map((step) => step.ar_value).join("**green**")
      : "";
    const steps_en = hasOptions
      ? steps.map((step) => step.en_value).join("**green**")
      : "";

    const weightsData = weights.map((w) => w.value).join("**green**");

    const dataSet = {
      title_ar: newProd.title_ar,
      title_en: newProd.title_en,
      description_ar: newProd.description_ar,
      description_en: newProd.description_en,
      price: newProd.price,
      discount: newProd.discount,
      images,
      quantity: newProd?.quanitity,
      Barcode: newProd?.Barcode,
      subcategory_id: subCatId || subCatData.id,
      category_id: data?.id || location?.state?.category_id,
      has_options: hasOptions ? 1 : 0,
      policies: policiesData,
      price_for: isKilo ? "weight" : "piece",
      weights: isKilo ? weightsData : "", //weights,
      ingrediants_ar,
      ingrediants_en,
      steps_ar,
      trade_price: newProd?.trade_price,
      steps_en,
      trade_min_qty:newProd.trade_min_qty,
      products: productsData,
      subcategory_ids:
        selectedSubCategories && selectedSubCategories?.length
          ? selectedSubCategories
          : subCatId || subCatData.id,
      // related_products: relatedProducts,
    };

    // console.log(dataSet);
    // return;
    const token = localStorage.getItem("GreenTreesAdminToken");

    await axios
      .post(`${BASE_URL}products/add_new?token=${token}`, {...dataSet,trade_min_qty:newProd.trade_min_qty})
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          // getCategories();
          setImagesUrl([]);
          setSelectedImages([]);
          setSelectedSubCategories([]);
          setNewProd({
            title_ar: "",
            title_en: "",
            description_ar: "",
            description_en: "",
            price: "",
            discount: "",
            hasOptions: hasOptions ? 1 : 0,
            count: "",
            pricePerUnit: "",
          });

          setLang({
            prod_name: false,
            prod_desc: false,
          });

          setArIngs([
            {
              id: 1,
              ar_value: "",
              en_value: "",
              englishLang: false,
            },
          ]);

          setIsKilo(false);

          setSteps([
            {
              id: 1,
              ar_value: "",
              en_value: "",
              englishLang: false,
            },
          ]);

          setProducts([
            {
              id: 1,
              product_id: "",
              price: "",
              count: "",
            },
          ]);

          setPolicies([
            {
              id: 1,
              value: "",
            },
          ]);

          setWeights([
            {
              id: 1,
              value: "",
            },
          ]);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
        // setNewCat({
        //   title_ar: "",
        //   title_en: "",
        //   color: "",
        // });
        // setSelectedFile(null);
      });
  };

  const handleUploadImages = async () => {
    const arr = [];
    setUploadImages(true);
    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append("image", selectedImages[i]);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data && (res?.status == 201 || res?.status == "success")) {
            arr.push(res?.data?.result?.image);
            toast.success(
              language == "ar"
                ? "تم رفع الصورة بنجاح"
                : "Success To Upload Image"
            );
          } else {
            toast.error(
              language == "ar" ? "حدث خطأ ما" : "Something Went Error"
            );
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    }

    console.log(arr);
    setUploadImages(false);

    setImagesUrl([...arr]);
  };

  return (
    <div className="add_product_container">
      <div className="d-flex mb-5 mt-3 align-items-center ">
        <h3 className="">
          {language == "ar" ? "إضافة منتج جديد" : "Add New Product"}
        </h3>

        {subCatName ? (
          <h3>
            {" "}
            - {language == "ar" ? "للفئة الفرعية" : " For SubCategory "} (
            {subCatName})
          </h3>
        ) : (
          ""
        )}
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddNewProduct();
        }}
      >
        <div className="fields row gy-4">
          <div className="field_input  col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                {language == "ar" ? " اسم المنتج باللغة " : " Product Name In "}
                {lang.prod_name
                  ? language == "ar"
                    ? "الإنجليزية"
                    : " english "
                  : language == "ar"
                  ? " العربية "
                  : " arabic "}
              </label>

              <div class="toggle-switch">
                <input
                  class="toggle-input"
                  id="prod_name"
                  type="checkbox"
                  onChange={(e) => {
                    setLang({
                      ...lang,
                      prod_name: e.target.checked,
                    });
                  }}
                />
                <label class="toggle-label" for="prod_name"></label>
              </div>
            </div>
            <textarea
              // type='text'
              value={lang.prod_name ? newProd.title_en : newProd.title_ar}
              onChange={(e) => {
                lang.prod_name
                  ? setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    })
                  : setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <div className="d-flex align-items-center gap-4">
              <label htmlFor="">
                {language == "ar"
                  ? " وصف المنتج باللغة "
                  : "Product Description In "}
                {lang.prod_desc
                  ? language == "ar"
                    ? "الإنجليزية"
                    : " english "
                  : language == "ar"
                  ? " العربية "
                  : " arabic "}
              </label>

              <div class="toggle-switch">
                <input
                  checked={lang.prod_desc}
                  class="toggle-input"
                  id="prod_desc"
                  type="checkbox"
                  onChange={(e) => {
                    setLang({
                      ...lang,
                      prod_desc: e.target.checked,
                    });
                  }}
                />
                <label class="toggle-label" for="prod_desc"></label>
              </div>
            </div>

            <textarea
              // type='text'
              value={
                lang.prod_desc ? newProd.description_en : newProd.description_ar
              }
              onChange={(e) => {
                lang.prod_desc
                  ? setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    })
                  : setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <label htmlFor="">
              {language == "ar" ? "سعر القطاعي " : "Sectoral Price "}
            </label>
            <input
              value={newProd.price}
              type="number"
              onWheel={(e)=>e.target.blur()}
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  price: e.target.value,
                });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <label htmlFor="">
              {language == "ar" ? "سعر الجملة " : "Trade Price "}
            </label>
            <input
              value={newProd.trade_price}
              type="number"
              onWheel={(e)=>e.target.blur()}
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  trade_price: e.target.value,
                });
              }}
            />
          </div>
          <div className="field_input col-md-6">
            <label htmlFor="">
              {language == "ar" ? " اقل كميه للتاجر " : "lowest Trader quantity "}
            </label>
            <input
              value={newProd.trade_min_qty}
              type="number"
              onWheel={(e)=>{
                e.preventDefault()
                e.target.blur()
              }}

              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  trade_min_qty: e.target.value,
                });
              }}
            />
          </div>

          {/* <div className="field_input col-md-6">
            <label htmlFor="">{language == 'ar' ? "الكمية" : "Quantity"}</label>
            <input
              value={newProd.quanitity}
              type="number"
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  quanitity: e.target.value,
                });
              }}
            />
          </div> */}
          <div className="field_input col-md-6">
            <label htmlFor="">
              {language == "ar" ? "الباركود" : "Barcode"}
            </label>
            <input
              value={newProd.Barcode}
              type="text"
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  Barcode: e.target.value,
                });
              }}
            />
          </div>{" "}
          <div className="field_input col-md-6">
            <label htmlFor="">
              {language == "ar" ? "قيمة الخصم" : "Discount Value"}
            </label>
            <input
              type="number"
              value={newProd.discount}
              onWheel={(e)=>e.target.blur()}
              onChange={(e) => {
                setNewProd({
                  ...newProd,
                  discount: e.target.value,
                });
              }}
            />
          </div>
          {!subCatId ? (
            <>
              {!location?.state?.type && (
                <div className="field_input col-md-6">
                  <label htmlFor="">
                    {language == "ar" ? "الفئة" : "Category"}
                  </label>
                  <SelectWithSearch
                    options={categories}
                    getClientData={setData}
                  />
                </div>
              )}
              <div className="field_input col-md-6">
                <label htmlFor="">
                  {language == "ar" ? "الفئة الفرعية" : "Subcategory"}
                </label>
                {/* <SelectWithSearch
                  options={subCategories}
                  getClientData={setSubCatData}
                /> */}
                <Select
                  placeholder={
                    language == "ar" ? "الفئات الفرعيه" : "Subacategory"
                  }
                  isMulti
                  onChange={(e) => {
                    console.log(e);
                    let pushedSubCatTxt = "";
                    for (let i = 0; i < e.length; i++) {
                      // console.log(e[i]);
                      // return
                      if (i == 0) {
                        pushedSubCatTxt += e[i].value;
                      } else {
                        pushedSubCatTxt += "**green**" + e[i].value;
                      }
                    }
                    console.log(pushedSubCatTxt);
                    setSelectedSubCategories(pushedSubCatTxt);
                  }}
                  // value={selectedSubCategories}
                  isDisabled={subCategories.length == 0}
                  name="subcategories"
                  options={
                    subCategories &&
                    Array.isArray(subCategories) &&
                    subCategories.length > 0 &&
                    subCategories.map((item, index) => {
                      return {
                        label:
                          language == "ar" ? item.title_ar : item?.title_en,
                        value: item.id,
                      };
                    })
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </div>
            </>
          ) : null}
        </div>

        {/* {!hasOptions && (
          <>
            <div className="field_input col-md-6 my-4">
              <div className="d-flex align-items-center gap-4">
                <label htmlFor="">
                  {language == "ar" ? "الكمية" : "Quantity"}{" "}
                  {isKilo
                    ? language == "ar"
                      ? "بالكيلو"
                      : "By Kilo"
                    : language != "ar"
                    ? "By piece"
                    : "بالقطعة"}
                </label>

                <div class="toggle-switch">
                  <input
                    class="toggle-input"
                    id="iskilo"
                    type="checkbox"
                    checked={isKilo}
                    onChange={(e) => {
                      setIsKilo(e.target.checked);
                    }}
                  />
                  <label class="toggle-label" for="iskilo"></label>
                </div>
              </div>

            </div>

            {isKilo ? (
              <>
                <div className="d-flex align_items-center justify-content-between">
                  <h4>
                    {language == "ar" ? "الأوزان المتاحة" : "Avilable Weights"}
                  </h4>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setWeights([
                        ...weights,
                        { value: "", id: weights.length + 1 },
                      ]);
                    }}
                  >
                    {plus}
                  </div>
                </div>

                {weights && weights.length >= 1
                  ? weights.map((weight, index) => {
                      return (
                        <div className="ing_container mb-3">
                          <div
                            className={
                              language == "ar" ? "deleteIng" : "deleteIng rev"
                            }
                            onClick={() => {
                              setWeights((prev) =>
                                prev.filter((item) => item.id != weight.id)
                              );
                            }}
                          >
                            {exitModal}
                          </div>

                          <div className="fields row w-100 ">
                            <div className="field_input col-sm-12">
                              <div className="d-flex align-items-center gap-4">
                                <label htmlFor="">
                                  ({index + 1}) :
                                  {language == "ar" ? "السعر" : "Price"}
                                  <span className="fw-bolder text-primary mx-2">
                                    {+weight.value * newProd.price}
                                  </span>
                                  {language == "ar" ? "د.إ" : "AED "}
                                </label>
                              </div>

                              <input
                                type="text"
                                onWheel={(e) => e.target.blur()}
                                value={weight.value}
                                onChange={(e) => {
                                  setWeights((prev) =>
                                    prev.map((item) =>
                                      item.id == weight.id
                                        ? {
                                            ...item,
                                            value: e.target.value,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </>
            ) : null}
          </>
        )} */}

        <div className="images_container my-3 porder prorder-2 rounded">
          <h4>
            {language == "ar" ? "اختر صور المنتج" : "Enter Product Image"}
          </h4>

          <div className="images">
            <label className="image" htmlFor="mul_images">
              <img
                className="avatar"
                src={
                  "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                }
                alt=""
              />
            </label>
            <input
              type="file"
              multiple
              name=""
              id="mul_images"
              className="d-none"
              onChange={handleImageChange}
            />
            {selectedImages.length >= 1
              ? selectedImages.map((imageFile, index) => {
                  return (
                    <div className="image">
                      <img src={URL.createObjectURL(imageFile)} alt="" />
                      <div
                        className="delete_btn"
                        onClick={() => handleDeleteImage(index)}
                      >
                        {exitModal}
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (!uploadImages) {
                handleUploadImages();
              }
            }}
            className="popup_agree_btn"
          >
            {uploadImages ? (
              <Loader />
            ) : language == "ar" ? (
              "رفع الصور"
            ) : (
              "Upload Image"
            )}
          </button>
        </div>

        {/* start new  */}
        <div className="my-4"></div>

        {/* end new  */}

        <div className="my-4"></div>

        <div className="d-flex align_items-center justify-content-between ">
          <h4>{language == "ar" ? "سياسات المنتج" : "Product Policies"}</h4>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setPolicies([
                ...policies,
                { value: "", id: policies.length + 1 },
              ]);
            }}
          >
            {plus}
          </div>
        </div>

        {policies && policies.length >= 1
          ? policies.map((policy, index) => {
              return (
                <div className="ing_container mb-3">
                  <div
                    className={language == "ar" ? "deleteIng" : "deleteIng rev"}
                    onClick={() => {
                      setPolicies((prev) =>
                        prev.filter((item) => item.id != policy.id)
                      );
                    }}
                  >
                    {exitModal}
                  </div>

                  <div className="fields row w-100 ">
                    <div className="field_input col-sm-12">
                      <div className="d-flex align-items-center gap-4">
                        <label htmlFor="">
                          {/* باللغة {ing.englishLang ? "الإنجليزية" : " العربية "} */}
                          {language == "ar" ? "سياسة رقم  " : "Policy Number "}(
                          {index + 1})
                        </label>

                        {/* <div class='toggle-switch'>
                            <input
                              class='toggle-input'
                              id={`policy_${index}`}
                              type='checkbox'
                              onChange={(e) => {
                                setPolicies((prev) =>
                                  prev.map((item) =>
                                    item.id == ing.id
                                      ? {...item, englishLang: e.target.checked}
                                      : item
                                  )
                                );
                              }}
                            />
                            <label
                              class='toggle-label'
                              for={`policy_${index}`}
                            ></label>
                          </div> */}
                      </div>

                      <input
                        type="text"
                        value={policy.value}
                        onChange={(e) => {
                          // ing.englishLang
                          // ?
                          setPolicies((prev) =>
                            prev.map((item) =>
                              item.id == policy.id
                                ? { ...item, value: e.target.value }
                                : item
                            )
                          );
                          // : setArIngs((prev) =>
                          //     prev.map((item) =>
                          //       item.id == ing.id
                          //         ? {
                          //             ...item,
                          //             ar_value: e.target.value,
                          //           }
                          //         : item
                          //     )
                          //   );
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          : null}

        {/* <hr /> */}

        {/* <div className='d-flex align-items-center gap-4'>
          <label htmlFor='hasOptions'>هل المنتج يحتوي على إضافات؟</label>

          <div class='toggle-switch'>
            <input
              class='toggle-input'
              id='hasOptions'
              type='checkbox'
              onChange={(e) => {
                setHasOptions(e.target.checked);
              }}
            />
            <label class='toggle-label' for='hasOptions'></label>
          </div>
        </div> */}

        {hasOptions ? (
          <div className="ingrediants mt-4">
            <div className="d-flex align_items-center justify-content-between">
              <h4>{language == "ar" ? "المكونات" : "Ingrediants"}</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setArIngs([
                    ...arIngs,
                    {
                      ar_value: "",
                      en_value: "",
                      englishLang: false,
                      id: arIngs.length + 1,
                    },
                  ]);
                  console.log(arIngs);
                }}
              >
                {plus}
              </div>
            </div>

            {arIngs && arIngs.length >= 1
              ? arIngs.map((ing, index) => {
                  return (
                    <div className="ing_container mb-3">
                      <div
                        className={
                          language == "ar" ? "deleteIng" : "deleteIng rev"
                        }
                        onClick={() => {
                          setArIngs((prev) =>
                            prev.filter((item) => item.id != ing.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>

                      <div className="fields row w-100 ">
                        <div className="field_input col-sm-12">
                          <div className="d-flex align-items-center gap-4">
                            <label htmlFor="">
                              {language == "ar" ? "باللغة" : "In "}{" "}
                              {ing.englishLang
                                ? language == "ar"
                                  ? "الإنجليزية"
                                  : " English "
                                : language == "ar"
                                ? " العربية "
                                : " Arabic "}
                            </label>

                            <div class="toggle-switch">
                              <input
                                class="toggle-input"
                                id={`prod_desc_${index}`}
                                type="checkbox"
                                onChange={(e) => {
                                  setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? {
                                            ...item,
                                            englishLang: e.target.checked,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                              <label
                                class="toggle-label"
                                for={`prod_desc_${index}`}
                              ></label>
                            </div>
                          </div>

                          <input
                            type="text"
                            value={
                              ing.englishLang ? ing.en_value : ing.ar_value
                            }
                            onChange={(e) => {
                              ing.englishLang
                                ? setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? { ...item, en_value: e.target.value }
                                        : item
                                    )
                                  )
                                : setArIngs((prev) =>
                                    prev.map((item) =>
                                      item.id == ing.id
                                        ? {
                                            ...item,
                                            ar_value: e.target.value,
                                          }
                                        : item
                                    )
                                  );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            <div className="d-flex align_items-center justify-content-between">
              <h4>
                {language == "ar" ? "خطوات التحضير" : "Ingrediants Steps "}
              </h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSteps([
                    ...steps,
                    {
                      ar_value: "",
                      en_value: "",
                      englishLang: false,
                      id: steps.length + 1,
                    },
                  ]);
                }}
              >
                {plus}
              </div>
            </div>

            {steps && steps.length >= 1
              ? steps.map((step, index) => {
                  return (
                    <div className="ing_container mb-3">
                      <div
                        className={
                          language == "ar" ? "deleteIng" : "deleteIng rev"
                        }
                        onClick={() => {
                          setSteps((prev) =>
                            prev.filter((item) => item.id != step.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>
                      <div className="fields row w-100 ">
                        <div className="field_input col-sm-12">
                          <div className="d-flex align-items-center gap-4">
                            <label htmlFor="">
                              {language == "ar" ? "الخطوة " : "Step "} (
                              {index + 1}){" "}
                              {language == "ar" ? "باللغة " : "In "}{" "}
                              {step.englishLang
                                ? language == "ar"
                                  ? "الإنجليزية"
                                  : " english "
                                : language == "ar"
                                ? " العربية "
                                : " arabic "}
                            </label>

                            <div class="toggle-switch">
                              <input
                                class="toggle-input"
                                id={`step_${index}`}
                                type="checkbox"
                                onChange={(e) => {
                                  setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? {
                                            ...item,
                                            englishLang: e.target.checked,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                              <label
                                class="toggle-label"
                                for={`step_${index}`}
                              ></label>
                            </div>
                          </div>

                          <input
                            type="text"
                            value={
                              step.englishLang ? step.en_value : step.ar_value
                            }
                            onChange={(e) => {
                              step.englishLang
                                ? setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? { ...item, en_value: e.target.value }
                                        : item
                                    )
                                  )
                                : setSteps((prev) =>
                                    prev.map((item) =>
                                      item.id == step.id
                                        ? { ...item, ar_value: e.target.value }
                                        : item
                                    )
                                  );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            <div className="d-flex align_items-center justify-content-between">
              <h4> {language == "ar" ? "الإضافات" : "Options"}</h4>
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setProducts([
                    ...products,
                    {
                      product_id: "",
                      price: "",
                      count: "",
                      id: products.length + 1,
                    },
                  ]);
                }}
              >
                {plus}
              </div>
            </div>

            {products && products.length >= 1
              ? products.map((prod, index) => {
                  return (
                    <div className="prod_container">
                      <div
                        className={
                          language == "ar" ? "deleteIng" : "deleteIng rev"
                        }
                        onClick={() => {
                          setProducts((prev) =>
                            prev.filter((item) => item.id != prod.id)
                          );
                        }}
                      >
                        {exitModal}
                      </div>

                      <div className="field_input">
                        <label htmlFor="">
                          {language == "ar" ? "المنتج " : "Product "}
                        </label>
                        <SelectWithSearch
                          options={allProducts}
                          getClientData={setProdcutData}
                          onChange={(value) => {
                            setProducts((prev) =>
                              prev.map((item) =>
                                item.id == prod.id
                                  ? { ...item, product_id: value.id }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>



                      <div className="field_input">
                        <label htmlFor="">
                          {language == "ar"
                            ? "الوزن/الكمية "
                            : "Weigth/quantity "}
                        </label>
                        <input
                          type="number"
                          onChange={(e) => {
                            setProducts((prev) =>
                              prev.map((item) =>
                                item.id == prod.id
                                  ? { ...item, count: e.target.value }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}

        <button disabled={addLoading} className="popup_agree_btn">
          {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
        </button>
      </form>
    </div>
  );
};

export default AddProductPage;
