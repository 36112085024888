import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import { BASE_URL } from "../../components/Axios/base_uri";
import PopUp from "../../components/popup";
import TableLayout from "../../components/table";
import UseGeneral from "../../customHooks/useGeneral";
import { exitModal } from "../../assets/svgIcons";
import { uploadImage } from "./uploadImage";

const SubCategory = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const { id, category_id } = useParams();

  const location = useLocation();
  const categoryData = location?.state?.categoryData;
  const [currentNumber, setCurrentNumber] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [subCats, setSubCAts] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [productData, setProductData] = useState({});
  const [editLoading, setEditLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [newSubCat, setNewSubCat] = useState({
    title_ar: "",
    title_en: "",
    category_id: id,
  });

  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const getSubCatefories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/${id}?token=${token}`,
        {
          headers: {
            lang: language,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          setSubCAts(
            res?.data?.result
              ?.sort((a, b) => parseInt(a?.number) - parseInt(b?.number))
              ?.filter((item) => item?.hidden == "0")
          );
          setOriginalData(
            res?.data?.result
              ?.sort((a, b) => parseInt(a?.number) - parseInt(b?.number))
              ?.filter((item) => item?.hidden == "0")
          );
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something went error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getSubCatefories();
  }, []);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAddFile = async () => {
    console.log("add file");


    // if (!img) {
    //   toast.error("لم تقم بإضافة صورة");
    //   return;
    // }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        console.log(res);

        if (res.data != "" && (res.status == 201 || res.status == "success")) {
          // setImgUrl(res?.data?.message);
          handleAddNewSubCat(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error(
            language == "ar"
              ? "هناك مشكلة في رفع الصورة"
              : "Faild To Upload Image"
          );
        } else {
          toast.error(
            language == "ar"
              ? "هناك مشكلة في رفع الصورة"
              : "Faild To Upload Image"
          );
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };
  // const [id, setId] = useState(null);
  const [finalDraggedRow, setFinalDraggedRow] = useState(null);
  const [c_id, setId] = useState(null);
  const [draggedRow, setDraggedRow] = useState(null);
  useEffect(() => {
    if (finalDraggedRow && draggedRow) {
      console.log(finalDraggedRow, draggedRow);
      const draggedIndex = subCats?.findIndex(
        (item) => item?.id == draggedRow?.id
      );
      const draggedFinalIndex = subCats?.findIndex(
        (item) => item?.id == finalDraggedRow?.id
      );
      const draggedID = subCats?.filter(
        (item) => item?.id == finalDraggedRow?.id
      )[0]?.id;
      setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
      setArrangeNumber(draggedID, draggedFinalIndex + 1);
      // setArrangeNumber(
      //   finalDraggedRow?.id,
      //   draggedRow?.number,
      //   finalDraggedRow?.number
      // );
    }
  }, [finalDraggedRow]);
  useEffect(() => {
    if (draggedRow) {
      setCurrentNumber(draggedRow?.number);
      setId(draggedRow?.id);
    }
  }, [draggedRow]);
  const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    const num = currentNumberTwo ? currentNumberTwo : currentNumber;
    setLoader(true);
    if (video_number == num) {
      // alert(video_number, num);
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "subcategories/changer_sub_cat_order/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getSubCatefories();
        setFinalDraggedRow(null);
        setDraggedRow(null);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const subCategoryHeader = [
    {
      label: "Number",
      dataIndex: "number",
      search: true,
      // sort: true,
      type: "children",
      children: ({ headers, row }) => {
        const handleBlur = async (product_id, product_number) => {
          set_product_id(null);
          setArrangeNumber(product_id, product_number);
        };

        const handleFocus = () => {
          setCurrentNumber(row?.number);
          set_product_id(row?.id);
        };

        const handleClick = () => {
          set_product_id(row?.id);
        };

        return (
          <div
            style={{ width: "fit-content", cursor: "pointer" }}
            onClick={handleClick}
          >
            <input
              style={{ width: "120px", cursor: "pointer" }}
              type="text"
              onChange={(e) =>
                setProductData({ id: row?.id, number: e.target.value })
              }
              onFocus={() => handleFocus()}
              defaultValue={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              value={
                productData?.id == row?.id ? productData?.number : row?.number
              }
              disabled={loader}
              className={
                product_id === row?.id
                  ? "MR_input_form_element active"
                  : "MR_input_form_element lazy"
              }
              onBlur={async (e) => {
                await handleBlur(row?.id, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      label: language == "ar" ? "الصورة" : "Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.image} alt="image" />
          </div>
        );
      },
    },

    {
      label: language == "ar" ? "اسم  الفئة الفرعية" : "Subcategory Name",
      dataIndex: language == "ar" ? "title_ar" : "title_en",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language == "ar" ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"
              ? language == "ar"
                ? "ظاهر"
                : "Showen"
              : language == "ar"
              ? "مخفي"
              : "Hidden"}
          </div>
        );
      },
    },
    {
      label: language == "ar" ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                // setImgUrl(row.image)
                setImgUrl(row.image);
                setImg(row.image);
                console.log(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/subcategories/${row.id}/${category_id}`, {
                  state: { subCategoryData: row },
                });
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "عرض" : "View"}
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);
    await axios
      .post(`${BASE_URL}subcategories/change_status?token=${token}`, dataset)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        getSubCatefories();
        setRowData({});
      });
  };

  const handleAddNewSubCat = async (image) => {
   
    let myImage = null;
    if (selectedFile) {
      myImage = await uploadImage(selectedFile);
    }
    const dataset = {
      ...newSubCat,
      image: myImage,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}subcategories/add_new?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getSubCatefories();
          setSelectedFile(null);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddSubCatModal(false);
        setAddLoading(false);
      });
  };

  const handleUpdateCategoryImage = async () => {


    setUpdateLoading(true);

    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          if (res.data != "" && res.status == 201) {
            handleUpdateSubCat(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(
              language == "ar"
                ? "هناك مشكلة في رفع الصورة"
                : "faild to Upload image"
            );
          } else {
            toast.error(
              language == "ar"
                ? "هناك مشكلة في رفع الصورة"
                : "faild to Upload image"
            );
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {});
    } else {
      handleUpdateSubCat(rowData.image);
    }

    setUpdateLoading(false);
  };

  const handleUpdateSubCat = async (image) => {
  
    const dataset = {
      ...rowData,
      title_ar: rowData.title_ar,
      title_en: rowData.title_en,
      category_id: id,
      id: rowData.id,
      image: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    setEditLoading(true);
    await axios
      .post(
        `${BASE_URL}subcategories/update_sub_category?token=${token}`,
        dataset
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          setSelectedFile(null);

          getSubCatefories();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
        setUpdateModal(false);
      });
  };

  // filteration

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((subCat) => {
          if (
            searchValue.length >= 1 &&
            !subCat.title_ar?.toLowerCase()?.includes(searchValue) &&
            !subCat.title_en?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setSubCAts(newData);
      } else {
        setSubCAts(originalData);
      }
    }
  }, [searchValue]);
  // ======================---------------------- New Features ------------------------==================
  const [selectedRows, setSelectedRows] = useState(null);
  const [password, setPassword] = useState(false);
  const [deletedData, setDeleteData] = useState(false);
  const deleteCategory = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    setLoader(true);
    if (selectedRows && selectedRows.length) {
      console.log(selectedRows);
      selectedRows.forEach(async (item) => {
        await axios
          .get(`${BASE_URL}subcategories/delete/${item.id}?token=${token}`)
          .then((res) => {
            toast.success(res.message);
            getSubCatefories();
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setLoader(false);
          });
      });
    }
  };
  // ======================---------------------- New Features ------------------------==================
  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>
              {" "}
              {language == "ar"
                ? " الفئات الفرعية من فئة "
                : "Subcategory in "}{" "}
              (
              {language == "ar"
                ? categoryData?.title_ar
                : categoryData?.title_en}
              )
            </h5>
            <div
              onClick={() => setShowAddSubCatModal(true)}
              className="btn btn-success"
            >
              {language == "ar" ? "إضافة فئة فرعية" : "Add Sub-Category"}
            </div>
            <div
              className="btn btn-success"
              onClick={() => navigate('/AllCategoryProducts/' + category_id)}
            >
              {"المنتجات"}
            </div>
            {/* category_id */}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>
          {selectedRows && selectedRows?.length ? (
            <div className="rowDiv">
              <button
                className="btn btn-danger"
                style={{ cursor: "pointer", margin: "20px" }}
                onClick={() => {
                  setDeleteData(true);
                }}
              >
                {language == "ar" ? "حذف" : "Delete"}
              </button>
            </div>
          ) : null}
          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout
              headers={subCategoryHeader}
              data={subCats}
              finalDraggedRow={finalDraggedRow}
              setFinalDraggedRow={setFinalDraggedRow}
              draggedRow={draggedRow}
              setDraggedRow={setDraggedRow}
              setSelectedRows_t={setSelectedRows}
            />
          )}
        </div>
      </div>
      <PopUp
        onClose={() => {
          console.log("close it");
        }}
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == "ar" ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className=" my-5">
              {language == "ar"
                ? " هل تريد بالفعل  "
                : "Do You Really Want To "}
              {rowData.hidden == "0"
                ? language == "ar"
                  ? " إخفاء "
                  : " hide "
                : language == "ar"
                ? " إظهار "
                : "Show "}
              {language == "ar" ? " هذه الفئه الفرعيه " : " This Subcategory "}
            </h5>

            <button
              onClick={() => (changeStatusloading ? null : handleShow_hide())}
              className="popup_agree_btn"
            >
              {changeStatusloading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
      <PopUp
        open={showAddSubCatModal}
        setOpen={setShowAddSubCatModal}
        title={
          language == "ar" ? "إضافة فئة فرعية جديدة " : "Add New Subcategory"
        }
        onClose={() => {
          setNewSubCat({
            ...newSubCat,
            title_ar: "",
            title_en: "",
          });

          setSelectedFile(null);
          setImg("");
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewSubCat();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "اسم الفئة الفرعية باللغة العربية"
                    : "Arabic Subcategory Name"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewSubCat({
                      ...newSubCat,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "اسم الفئة الفرعية باللغة الإنجليزية"
                    : "English Subcategory Name"}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewSubCat({
                      ...newSubCat,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "اختر صورة الفئة الفرعية"
                    : "Enter Subcateogry Image"}
                </label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />
      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={`${
          language == "ar" ? "تعديل الفئة الفرعية " : "Edit Subcategory "
        }: ${language == "ar" ? rowData.title_ar : rowData.title_en}`}
        onClose={() => {
          setImg("");
          setSelectedFile(null);
          console.log("closed");
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateCategoryImage();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "اسم الفئة الفرعيه باللغة العربية"
                    : "Arabic Subcategory Name"}
                </label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "اسم الفئة باللغة الإنجليزية"
                    : "English Subcategory Name"}
                </label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar"
                    ? "اختر صورة الفئة الفرعيه"
                    : "Enter Subcateogry Image"}
                </label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                        console.log(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <button className="popup_agree_btn">
              {editLoading ? <Loader /> : language == "ar" ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
      <PopUp
        open={deletedData}
        setOpen={setDeleteData}
        title={""}
        children={
          <div className="">
            <h5 className="">
              {language == "ar" ? "هل تريد بالفعل " : "Do You Want"}{" "}
              {rowData.hidden == "0"
                ? language == "ar"
                  ? " حذف "
                  : "Delete"
                : language == "ar"
                ? " حذف "
                : " Delete "}
              {language == "ar" ? "هذه المنتجات " : "This Products"}
            </h5>
            <p>{language == "ar" ? "كلمة السر" : "Password"}</p>
            <input type="text" onChange={(e) => setPassword(e.target.value)} />
            <button
              onClick={() =>
                deleteCategory(true)
              }
              className="popup_agree_btn"
            >
              {loader ? <Loader /> : language == "ar" ? "تأكيد" : "Confirm"}
            </button>
          </div>
        }
      />{" "}
    </>
  );
};

export default SubCategory;
