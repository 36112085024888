import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { add, eyeOff, eyeOn } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";
import PopUp from "./../../components/popup/index";
// import "./style.css";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { edit } from "./../../assets/svgIcons/index";
import UseGeneral from "../../customHooks/useGeneral";

const Gallery = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [showVideoPop, setShowVideoPop] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState('');
  const [updateFileUrl, setUpdateFileURl] = useState('');
  const [updateFile, setUpdateFile] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);
  const [newBrand, setNewBrand] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    type: "video",
  });
  const { language } = UseGeneral();

  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString.split("T")[0];
    return datePart;
  };

  const translate = (arText, enText) => {
    return language === "ar" ? arText : enText;
  };
  const getBrands = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}gallery/get_all_for_admin?token=${token}`)
      .then((res) => {
        if (res.data.status == "success") {
          setBrands(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => {
        setBrands([]);
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getBrands();
  }, []);

  const brandsHeader = [
    {
      label: translate("الملف", "File"),
      dataIndex: "url",
      type: 'children',
      children: ({ row, headers }) => {
        return (
          <>
            {row.type == 'image' ? (
              <img src={row.file} alt="" />
            ) : (
              <div>
                {/* <a target="_blank" href={row.file}>

                </a> */}
                <img
                  onClick={() => {
                    setShowVideoPop(true);
                    setRowData(row);
                  }}
                  style={{ width: '40px', cursor: 'pointer' }}
                  src={require("../../assets/video.png")}
                  alt=""
                />
              </div>
            )}
          </>
        );
      },
    },
    {
      label: translate("العنوان  بالعربيه", "Address Into Arabic"),
      dataIndex: "title_ar",
    },
    {
      label: translate("العنوان  بالإنجليزيه", "Address Into English"),
      dataIndex: "title_en",
    },
    {
      label: translate("الوصف  بالعربيه", "Description Into Arabic"),
      dataIndex: "description_ar",
    },
    {
      label: translate("الوصف  بالإنجليزيه", "Description Into English"),
      dataIndex: "description_en",
    },

    {
      label: translate("وقت الانشاء", "Created At"),
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        return <div>{getOlnlyDate(row.created_at)}</div>;
      },
    },

    {
      label: translate("الحالة", "Status"),
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"
              ? translate("ظاهر", "Shown")
              : translate("مخفي", "Hidden")}
          </div>
        );
      },
    },
    {
      label: translate(" أوامر", "Actions"),
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
                setUpdateFileURl(row?.file);
                console.log(row?.file);
              }}
            >
              {row.hidden == "0" ? eyeOff : eyeOn}
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setUpdateModal(true);
                setImgUrl(row.image);
                setUpdateFileURl(row?.file);
                setImg(row.image);
                console.log(row.image);
              }}
            >
              {edit}
            </div>
            <div
              onClick={() => {
                setShowDelModal(true);
                setRowData(row);
                // handelDelItem(row.id)
              }}
            >
              <img
                style={{ width: '30px', cursor: 'pointer' }}
                src={require("../../assets/bin.png")}
                alt=""
              />
            </div>
          </div>
        );
      },
    },
  ];

  const handleAddNewBrand = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    if (file == null) {
      toast.error(translate("أدخل صوره أو فديو", "Add Video Or Image"));
      return;
    }
    setAddLoading(true);
    const formData = new FormData();
    formData.append('image', file);
    axios
      .post(BASE_URL + `upload_image`, formData)
      .then((res1) => {
        if (res1.data.status == 'success') {
          const dataset = {
            ...newBrand,
            file: res1.data.result.image,
          };
          axios
            .post(BASE_URL + `gallery/add_new?token=${token}`, dataset)
            .then((res) => {
              if (res.data.status == 'success') {
                getBrands();
                setFile(null);
                setFileUrl('');
                setNewBrand({
                  title_ar: "",
                  title_en: "",
                  description_ar: "",
                  description_en: "",
                  type: "video",
                });
                setShowAddBrandModal(false);
              } else if (res.data.status == 'faild') {
                toast.error(res.data.message);
              } else {
                toast.error(translate('حدث خطأ ما', 'Failed'));
              }
            })
            .catch((e) => console.log(e))
            .finally(() => {
              setAddLoading(false);
            });
        }
        // if()
      })
      .catch((e) => console.log(e));

    // await axios
    //   .post(`${BASE_URL}gallery/add_new?token=${token}`, dataset)
    //   .then((res) => {
    //     if (res?.data && res?.data?.status == "success") {
    //       toast.success(res.data.message);
    //       getBrands();
    //       console.log(res.data.result);
    //     } else if (res.data.status == "faild") {
    //       toast.error(res.data.message);
    //     } else {
    //       toast.error("حدث خطأ ما");
    //     }
    //   })
    //   .catch((e) => console.log(e))
    //   .finally(() => {
    //     setAddLoading(false);
    //     setShowAddBrandModal(false);
    //     setNewBrand({
    //       title_ar: "",
    //       title_en: "",
    //       description_ar: "",
    //       description_en: "",
    //       type:"video",
    //     });
    //   });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .get(`${BASE_URL}gallery/update_status/${rowData?.id}?token=${token}`)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getBrands();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const handelDelItem = () => {
    setChangeStatusLoading(true);
    axios
      .get(BASE_URL + `gallery/del_one/${rowData.id}`)
      .then((res) => {
        if (res.data.status == 'success') {
          toast.success(res.data.message);
          setChangeStatusLoading(false);
          getBrands();
          setShowDelModal(false);
        } else if (res.data.status == 'faild') {
          toast.error(res.data.message);
        } else {
          toast.error('حدث خطأ ما');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusLoading(false);
      });
  };

  const handleUpdateBrand = async () => {
    setUpdateLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    if (updateFile != null) {
      const formData = new FormData();
      formData.append('image', updateFile);
      axios
        .post(BASE_URL + `upload_image`, formData)
        .then((res1) => {
          if (res1.data.status == 'success') {
            const dataset = {
              ...rowData,
              file: res1.data.result.image,
            };
            axios
              .post(
                BASE_URL + `gallery/update_faq/${rowData?.id}?token=${token}`,
                dataset
              )
              .then((res) => {
                if (res.data.status == 'success') {
                  getBrands();
                  setUpdateModal(false);
                } else if (res.data.status == 'faild') {
                  toast.error(res.data.message);
                } else {
                  toast.error('حدث خطأ ما');
                }
              })
              .catch((e) => console.log(e))
              .finally(() => {
                setUpdateLoading(false);
              });
          }
          // if()
        })
        .catch((e) => console.log(e));
    } else {
      const dataset = {
        ...rowData,
      };
      axios
        .post(
          BASE_URL + `gallery/update_faq/${rowData?.id}?token=${token}`,
          dataset
        )
        .then((res) => {
          if (res.data.status == 'success') {
            getBrands();
            setUpdateModal(false);
          } else if (res.data.status == 'faild') {
            toast.error(res.data.message);
          } else {
            toast.error('حدث خطأ ما');
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setUpdateLoading(false);
        });
    }
    const dataset = {
      url: rowData.url,
      id: rowData.id,
    };
    // setUpdateLoading(true)

    // await axios
    //   .post(`${BASE_URL}brands/update_brand?token=${token}`, dataset)
    //   .then((res) => {
    //     if (res?.data && res?.data?.status == "success") {
    //       toast.success("تم تعديل الفئة بنجاح");
    //       getBrands();
    //       console.log(res.data.result);
    //     } else if (res.data.status == "error") {
    //       toast.error("هناك مشكلة ! حاول مجدداً");
    //     } else {
    //       toast.error("حدث خطأ ما");
    //     }
    //   })
    //   .catch((e) => console.log(e))
    //   .finally(() => {
    //     setUpdateModal(false);
    //     setRowData({});
    //     setUpdateLoading(false)

    //   });
  };

  // filterations
  // filterations
  // filterations

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((brand) => {
          if (
            searchValue.length >= 1 &&
            !brand.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            !brand.title_en.toLowerCase().includes(searchValue.toLowerCase()) &&
            !brand.url.toLowerCase().includes(searchValue.toLowerCase()) &&
            !brand.id.toString().includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setBrands(newData);
      } else {
        setBrands(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{translate("معرض الصور", "Gallery")} </h5>
            <div onClick={() => setShowAddBrandModal(true)}>{add}</div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={translate("بحث ...", "Search...")}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={brandsHeader} data={brands} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddBrandModal}
        setOpen={setShowAddBrandModal}
        title={translate("إضافة ملف جديد ", "Add New File")}
        children={
          <form
            // style={{ maxHeight: '600px', overflow: 'auto' }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {translate("العنوان  بالعربيه", "Address Into Arabic")}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {" "}
                  {translate(
                    "العنوان  بالإنجليزيه",
                    "Address Into English"
                  )}{" "}
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {translate("الوصف  بالعربيه", "Description Into Arabic")}
                </label>
                <textarea
                  name=""
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      description_ar: e.target.value,
                    });
                  }}
                  id=""
                ></textarea>
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {translate("الوصف  بالإنجليزيه", "Description Into English")}
                </label>
                <textarea
                  name=""
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      description_en: e.target.value,
                    });
                  }}
                  id=""
                ></textarea>
              </div>
              <div className="field_input">
                <label htmlFor="">{translate("النوع", "Type")}</label>
                <select
                  value={newBrand.type}
                  onChange={(e) => {
                    setNewBrand({ ...newBrand, type: e.target.value });
                  }}
                >
                  <option value="video">Video</option>
                  <option value="image">image</option>
                </select>
              </div>
              <div className="field_input">
                <label htmlFor="">{translate("الملف", "File")}</label>
                <input
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    if (newBrand.type == 'image') {
                      setFileUrl(URL.createObjectURL(e.target.files[0]));
                    }
                  }}
                />
              </div>
              {newBrand.type == 'image' && fileUrl != '' && (
                <img style={{ width: '300px' }} src={fileUrl} alt="" />
              )}
              {/* <div className='field_input'>
                <label htmlFor=''>الصوره</label>
                <input
                  type='text'
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      url: e.target.value,
                    });
                  }}
                />
              </div> */}
            </div>

            <button
              onClick={() => handleAddNewBrand()}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : translate("إضافة", "Add")}
            </button>
          </form>
        }
      />
      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={translate("تغيير حالة الظهور", "Change Visibility Status")}
        children={
          <div className="">
            <h5 className=" my-5">
              {translate(
                `هل تريد بالفعل ${
                  rowData.hidden == "0" ? "إخفاء" : "إظهار"
                } هذا الملف`,
                `Do you really want to ${
                  rowData.hidden == "0" ? "hide" : "show"
                } this file?`
              )}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? <Loader /> : translate("تأكيد", "Confirm")}
            </button>
          </div>
        }
      />

      <PopUp
        open={showDelModal}
        setOpen={setShowDelModal}
        title={translate("حذف العنصر", "Delete Item")}
        children={
          <div className="">
            <h5 className="my-5">
              {translate(
                "هل تريد بالفعل حذف هذا العنصر",
                "Are you sure you want to delete this item?"
              )}
            </h5>

            <button onClick={() => handelDelItem()} className="popup_agree_btn">
              {changeStatusLoading ? <Loader /> : translate("تأكيد", "Confirm")}
            </button>
          </div>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={translate(
          `تعديل الملف : ${rowData.title_ar}`,
          `Edit File: ${rowData.title_en}`
        )}
        children={
          <form
            // style={{ maxHeight: '600px', overflow: 'auto' }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">
                  {translate("العنوان باللغة العربية", "Title in Arabic")}
                </label>
                <input
                  type="text"
                  value={rowData?.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {translate("العنوان باللغة الإنجليزية", "Title in English")}
                </label>
                <input
                  type="text"
                  value={rowData?.title_en}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {translate("الوصف باللغة العربيه", "Description in Arabic")}
                </label>
                <textarea
                  name=""
                  value={rowData?.description_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      description_ar: e.target.value,
                    });
                  }}
                  id=""
                ></textarea>
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {translate(
                    "الوصف باللغة الإنجليزيه",
                    "Description in English"
                  )}
                </label>
                <textarea
                  name=""
                  value={rowData?.description_en}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      description_en: e.target.value,
                    });
                  }}
                  id=""
                ></textarea>
              </div>
              <div className="field_input">
                <label htmlFor="">{translate("النوع", "Type")}</label>
                <select
                  value={rowData.type}
                  onChange={(e) => {
                    setRowData({ ...rowData, type: e.target.value });
                  }}
                >
                  <option value="video">{translate("فيديو", "Video")}</option>
                  <option value="image">{translate("صورة", "Image")}</option>
                </select>
              </div>
              <div className="field_input">
                <label htmlFor="">{translate("الملف", "File")}</label>
                <input
                  type="file"
                  onChange={(e) => {
                    setUpdateFile(e.target.files[0]);
                    if (rowData.type == 'image') {
                      setUpdateFileURl(URL.createObjectURL(e.target.files[0]));
                    }
                  }}
                />
              </div>
              {rowData.type == 'image' && updateFileUrl != '' && (
                <img style={{ width: '300px' }} src={updateFileUrl} alt="" />
              )}
            </div>

            <button
              onClick={() => handleUpdateBrand()}
              className="popup_agree_btn"
            >
              {updateLoading ? <Loader /> : translate("تعديل", "Edit")}
            </button>
          </form>
        }
      />

      <PopUp
        open={showVideoPop}
        setOpen={setShowVideoPop}
        title={translate("الفيديو", "Video")}
        children={
          <div className="">
            <video
              style={{ maxWidth: '100%' }}
              src={rowData?.file}
              controls
              autoPlay
            />
          </div>
        }
      />
    </>
  );
};

export default Gallery;
