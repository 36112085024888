import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { eyeOff, eyeOn } from "../../assets/svgIcons";
import { BASE_URL } from "../../components/Axios/base_uri";
import TableLayout from "../../components/table";
import "./style.css";

import { Loader } from "rsuite";
import { edit } from "../../assets/svgIcons/index";
import PopUp from "../../components/popup";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const Sections = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [open, setOpen] = useState({ add: false, edit: false, hide: false });
  const [loading, setLoading] = useState({
    add: false,
    edit: false,
    hide: false,
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [allCats, setAllCats] = useState([]);
  const [type] = useState(['box1', 'box2', 'box3']);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [data, setData] = useState({
    sec_title_ar: "",
    sec_title_en: "",
    category_id: "",
  });

  const productsHeader = [
 
    {
      label:language=='ar'? "اسم السكشن":"Section Name",
      dataIndex: language == "ar" ? "sec_title_ar":"sec_title_en",
    },
    {
      label:language=='ar'? "الفئة":"Category",
      dataIndex: "",
      type: "children",
      children: ({ row }) => {
        return <p>{language=='ar'?row?.category?.title_ar:row?.category?.title_en} </p>;
      },
    },
    {
      label: language=='ar'?"النوع":"Type",
      dataIndex: "section_type",
    },
    {
      label: language=='ar'?"أوامر":"Actions",
      dataIndex: "",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpen({ ...open, hide: row });
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpen({ ...open, edit: row });
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div>
          </div>
        );
      },
    },
  ];
  const [finalDraggedRow, setFinalDraggedRow] = useState(null);
  const [c_id, setId] = useState(null);
  const [draggedRow, setDraggedRow] = useState(null);
  useEffect(() => {
    if (finalDraggedRow && draggedRow) {
      const draggedIndex = products?.findIndex(
        (item) => item?.id == draggedRow?.id
      );
      const draggedFinalIndex = products?.findIndex(
        (item) => item?.id == finalDraggedRow?.id
      );
      const draggedID = products?.filter(
        (item) => item?.id == finalDraggedRow?.id
      )[0]?.id
      setArrangeNumber(c_id, draggedFinalIndex, draggedIndex);
      setArrangeNumber(draggedID, draggedFinalIndex+1);
    
    }
  }, [finalDraggedRow]);
  useEffect(() => {
    if (draggedRow) {
      setCurrentNumber(draggedRow?.number);
      setId(draggedRow?.id);
    }
  }, [draggedRow]);
  const setArrangeNumber = async (video_id, video_number, currentNumberTwo) => {
    // console.log(video_number)
    // console.log(video_id)
    // return
    const num = currentNumberTwo ? currentNumberTwo : currentNumber;
    setLoader(true);
    if (video_number == num) {
      // alert(video_number, num);
      return setLoader(false);
    }

    await Axios({
      method: "POST",
      url: "homesections/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getSections();
        setFinalDraggedRow(null);
        setDraggedRow(null);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  // const setArrangeNumber = async (video_id, video_number) => {
  //   console.log(video_number);
  //   console.log(video_id);
  //   // return
  //   setLoader(true);
  //   if (video_number == currentNumber) {
  //     return setLoader(false);
  //   }
  //   await Axios({
  //     method: "POST",
  //     url: "homesections/change_number/" + video_id,
  //     data: { number: video_number },
  //   })
  //     .then(async (response) => {
  //       console.log(response);
  //       toast.success(response?.message);
  //       getSections();
  //     })
  //     .catch((error) => console.log(error))
  //     .finally(() => {
  //       setLoader(false);
  //     });
  // };
  const getSections = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}homesections/get_all?token=${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setProducts(res?.data?.result?.filter((item) => item?.hidden == "0"));
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setProducts([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  const getCategories = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}categories/get_categories_for_admin?token=${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        if (res.data.status == "success") {
          setAllCats(res?.data?.result?.filter((item) => item?.hidden == "0"));
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"هناك مشكلة في تحميل الفئات":"There Are Problem in Downloading");
        }
      })
      .catch((e) => {
        console.log(e);
        setAllCats([]);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };
  const handleAdd = async (image) => {
    setLoading({ ...loading, add: true });

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}homesections/add_new?token=${token}`, data,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          setOpen({ ...open, add: false });
          getSections();
        } else if(res.data.status=='faild'){
          toast.error(res?.data?.message);
        }
        else {
          toast.error(language=='ar'?"هناك مشكلة في تحميل الفئات":'Something Went Error')
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading({ ...loading, add: false });
      });
  };
  const handleEdit = async (image) => {
    setLoading({ ...loading, edit: true });

    const token = localStorage.getItem("GreenTreesAdminToken");
    delete open?.edit?.category;
    await axios
      .post(
        `${BASE_URL}homesections/update_section/${open?.edit?.id}?token=${token}`,
        {
          ...open?.edit,
        },
        {
          headers:{
            lang:language
          }
        }
      )
      .then((res) => {
        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getSections();
          setOpen({ ...open, edit: false });
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading({ ...loading, edit: false });
      });
  };
  const handleShow_hide = async (image) => {
    setLoading({ ...loading, hide: true });
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .get(
        `${BASE_URL}homesections/update_status/${open?.hide?.id}?token=${token}`,{
          headers:{
            lang:language
          }
        }
      )
      .then((res) => {
        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getSections();
          setOpen({ ...open, hide: false });
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading({ ...loading, hide: false });
      });
  };
  useEffect(() => {
    getSections();
    getCategories();
  }, []);
  useEffect(() => {
    console.log(open);
  }, [open]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language=='ar'?"السكاشن":"Sections"}</h5>
            <button
              className="btn btn-success"
              onClick={() => setOpen({ ...open, add: true })}
            >
              {language=='ar'?"إضافة":"Add"}
            </button>
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={productsHeader} data={products}      finalDraggedRow={finalDraggedRow}
            setFinalDraggedRow={setFinalDraggedRow}
            draggedRow={draggedRow}
            setDraggedRow={setDraggedRow}/>
          )}
        </div>
      </div>
      <PopUp title={language=='ar'?"إضافه سكشن جديد":"Add New Section"} open={open?.add} setOpen={() => setOpen({ ...open, add: false })}>
        <div className="inputs">
          <div className="field_input">
            <label htmlFor="">{language=='ar'?"اسم السكشن باللغة العربية":"Section Arabic Name"}</label>
            <input
              type="text"
              value={data?.sec_title_ar}
              onChange={(e) => {
                setData({ ...data, sec_title_ar: e.target.value });
              }}
            />
          </div>
          <div className="field_input">
            <label htmlFor="">{language=='ar'?"اسم السكشن باللغة الإنجليزية":"Section English Name"}</label>
            <input
              type="text"
              value={data?.sec_title_en}
              onChange={(e) => {
                setData({ ...data, sec_title_en: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="field_input">
          <label htmlFor="">{language=='ar'?'نوع السكشن':'Section Type'}</label>
          <select
            name=""
            id=""
            value={data?.section_type}
            onChange={(e) => setData({ ...data, section_type: e.target.value })}
          >
            {type?.map((item, index) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </div>
        <div className="field_input col-md-6 classAddSection">
          <label htmlFor="">{language=='ar'?'الفئة':'Category'}</label>
          <select
            value={data?.category_id}
            onChange={(e) => {
              setData({ ...data, category_id: e.target.value });
            }}
          >
            {allCats && allCats
              ? allCats?.map((item) => {
                  return <option value={item?.id}>{item?.title_ar}</option>;
                })
              : null}
          </select>

          {loading?.add ? (
            <Loader />
          ) : (
            <button className="btn btn-success" onClick={() => handleAdd()}>
              {language=='ar'?"إضافة":"Add"}{" "}
            </button>
          )}
        </div>
      </PopUp>

      <PopUp
        title={language=='ar'?'تعديل سكشن':'Edit Section'}
        open={open?.edit}
        setOpen={() => setOpen({ ...open, edit: false })}
      >
        <div className="inputs">
          <div className="field_input">
            <label htmlFor="">{language=='ar'?"اسم السكشن باللغة العربية":"Section Arabic Name"}</label>
            <input
              type="text"
              value={open?.edit?.sec_title_ar}
              onChange={(e) => {
                setOpen((prevState) => ({
                  ...prevState,
                  edit: {
                    ...prevState.edit,
                    sec_title_ar: e.target.value,
                  },
                }));
              }}
            />
          </div>
          <div className="field_input">
            <label htmlFor="">{language=='ar'?"اسم السكشن باللغة الإنجليزية":"Section English Name"}</label>
            <input
              type="text"
              value={open?.edit?.sec_title_en}
              onChange={(e) => {
                setOpen((prevState) => ({
                  ...prevState,
                  edit: {
                    ...prevState.edit,
                    sec_title_en: e.target.value,
                  },
                }));
              }}
            />
          </div>
        </div>
        <div className="field_input">
          <label htmlFor="">{language=='ar'?"نوع السكشن":"Section Type"}</label>
          <select
            name=""
            id=""
            value={open?.edit?.section_type}
            onChange={(e) => {
              setOpen((prevState) => ({
                ...prevState,
                edit: {
                  ...prevState.edit,
                  section_type: e.target.value,
                },
              }));
            }}
          >
            {type?.map((item, index) => {
              return <option value={item}>{item}</option>;
            })}
          </select>
        </div>
        <div className="field_input col-md-6 classAddSection">
          <label htmlFor="">{language=='ar'?"الفئة":"Category"}</label>
          <select
            value={open?.edit?.category_id}
            onChange={(e) => {
              setOpen((prevState) => ({
                ...prevState,
                edit: {
                  ...prevState.edit,
                  category_id: e.target.value,
                },
              }));
            }}
          >
            {allCats && allCats
              ? allCats?.map((item) => {
                  return <option value={item?.id}>{language=='ar'?item?.title_ar:item?.title_en}</option>;
                })
              : null}
          </select>

          {loading?.edit ? (
            <Loader />
          ) : (
            <button className="btn btn-success" onClick={() => handleEdit()}>
              {language=='ar'?"تعديل":"Edit"}{" "}
            </button>
          )}
        </div>
      </PopUp>

      <PopUp
        open={open?.hide}
        setOpen={() => setOpen({ ...open, hide: false })}
        title={language=='ar'?"تغيير حالة الظهور":"Change Appearance Status"}
        children={
          <div className="">
            <h5 className="">
              {language=='ar'?"هل تريد بالفعل ":"Do you really want to"}{open?.hide?.hidden == "0" ? language=='ar'?" إخفاء ": " Hide " : language=='ar'?" إظهار ":" Show "}
                {language=='ar'?'هذا السكشن':"this Section"}?
            </h5>

            <div className="modalButtons">
              <button
                onClick={() => (loading?.hide ? null : handleShow_hide())}
                className="btn btn-danger"
              >
                {loading?.hide ? <Loader /> : language=='ar'?"تأكيد":"Confirm"}
              </button>
              <button
                onClick={() => setOpen({ ...open, hide: false })}
                className="btn btn-success"
              >
                {language=='ar'?'إلغاء':"Cancel"}
              </button>
            </div>
          </div>
        }
      />
    </>
  );
};

export default Sections;
