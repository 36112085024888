import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Menu,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";

import imageAvatar from "../../assets/image_placeholder.png";
import { edit } from "./../../assets/svgIcons/index";
import { Loader } from "rsuite";
import PopUp from "../../components/popup";
import UseGeneral from "../../customHooks/useGeneral";

const IdeasPage = () => {
  const {language}=UseGeneral()
  const navigate = useNavigate();
  const [subIdeas, setSubIdeas] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddNewSubIdea, setShowAddNewSubIdea] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [searchValue,  setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [changeStatusloading, setChangeStatusloading] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [newIdea, setNewIdea] = useState({
    title_ar: "",
    title_en: "",
    category_id: 0,
  });

  const [img, setImg] = useState("");

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const subIdeasHeaders = [
    

    {
      label: language=='ar'?"اسم الفكرة":"Idea Name",
   dataIndex: language=='ar'?"title_ar":"title_en",
    },
    {
      label: language=='ar'?"الصورة":"Image",
      dataIndex: "id",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="table_row_image">
            <img src={row.image} alt="image" />
          </div>
        );
      },
    },

    {
      label: language=='ar'?"حالة الظهور":"Appearance Status",
      dataIndex: "num_order",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {language=='ar'?'مخفى':"Hidden"}
          </div>
        );
      },
    },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label:language=='ar'? " أوامر":"Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setImgUrl(row.image)
                setImg(row.image);
                // console.log(row.image)
                setShowUpdateModal(true);
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate(`/ideas/${row.id}`, { state: { ideaData: row } });
                console.log(row);
              }}
            >
             <button className="btn btn-success">{language =="ar" ?"عرض":"View"}</button>
            </div>
          </div>
        );
      },
    },
  ];

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const getSubCatsForIdeas = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(
        `${BASE_URL}categories/category_subcategories_for_admin/0?token=${token}`
      )
      .then((res) => {
        if (res.data.status == "success") {
          setSubIdeas(res?.data?.result?.filter((item) => item?.hidden == "1"));
          setOriginalData(
            res.data.result?.filter((item) => item?.hidden == "1")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getSubCatsForIdeas();
  }, []);

  const handleAddNewSubIdea = async (image) => {
 
    const dataset = {
      ...newIdea,
      image: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}subcategories/add_new?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success("تم إضافة الفكرة الفرعية بنجاح");
          getSubCatsForIdeas();
        } else if (res.data == "") {
          toast.error("هناك مشكلة في رفع الصورة");
        } else {
          toast.error("هناك مشكلة في رفع الصورة");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        // setShowAddSubCatModal(false);
        // setAddLoading(false);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusloading(true);
    await axios
      .post(`${BASE_URL}subcategories/change_status?token=${token}`, dataset)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            res.data.message
          );
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusloading(false);
        getSubCatsForIdeas();
        setRowData({});
      });
  };

  const handleAddFile = async () => {
 
    if (!img) {
      toast.error("لم تقم بإضافة صورة");
      return;
    }

    setAddLoading(true);
    // setImgLoading(true);
    const formData = new FormData();
    formData.append("image", selectedFile);
    await axios
      .post(`${BASE_URL}upload_image`, formData)
      .then((res) => {
        if (res.data != "" && res.status == 201) {
          // setImgUrl(res?.data?.message);
          handleAddNewSubIdea(res?.data?.result?.image);
        } else if (res.data == "") {
          toast.error("هناك مشكلة في رفع الصورة");
        } else {
          toast.error("هناك مشكلة في رفع الصورة");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddNewSubIdea(false);
        setAddLoading(false);
        setImg('');
        setSelectedFile(null);
      });
  };

  // updates
  // updates
  // updates
  // updates

  const handleUpdateIdeaImage = async () => {
 

    setUpdateLoading(true);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          console.log(res);
          if (res.data != "" && res.status == 201) {
            // setImgUrl(res?.data?.message);
            console.log(res?.data?.result?.image);
            handleUpdateIdea(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error("هناك مشكلة في رفع الصورة");
          } else {
            toast.error("هناك مشكلة في رفع الصورة");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
          setUpdateLoading(false);
          setShowUpdateModal(false);
          setImg('');
          setSelectedFile(null);
        });
    } else {
      handleUpdateIdea(rowData.image);
      setUpdateLoading(false);
      setShowUpdateModal(false);
      setImg('');
      setSelectedFile(null);
    }
  };

  const handleUpdateIdea = async (image) => {
    

    const dataset = {
      ...rowData,
      title_ar: rowData.title_ar,
      title_en: rowData.title_en,
      category_id: 0,
      id: rowData.id,
      image: image,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    // setEditLoading(true)
    await axios
      .post(
        `${BASE_URL}subcategories/update_sub_category?token=${token}`,
        dataset
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success("تم تعديل  الفكرة بنجاح");
          getSubCatsForIdeas();
        } else if (res.data == "") {
          toast.error("هناك مشكلة في عملية التعديل");
        } else {
          toast.error(" هناك مشكلة في عملية التعديل  ");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setEditLoading(false);
        setShowUpdateModal(false);

        setUpdateModal(false);
      });
  };

  // filterations
  // filterations
  // filterations

  // filteration

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((idea) => {
          if (
            searchValue.length >= 1 &&
            !idea.title_ar?.toLowerCase()?.includes(searchValue) &&
            !idea.title_en?.toLowerCase()?.includes(searchValue) &&
            !idea.id.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setSubIdeas(newData);
      } else {
        setSubIdeas(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language=='ar'?"الأفكار":"Ideas"}</h5>
            {/* <div onClick={() => setShowAddNewSubIdea(true)}>{add}</div> */}
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language=='ar'?"بحث...":"Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>
          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout headers={subIdeasHeaders} data={subIdeas} />
          )}
        </div>
      </div>
      <PopUp
        open={showAddNewSubIdea}
        setOpen={setShowAddNewSubIdea}
        title={"إضافة فكرة جديدة "}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddFile();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الفكرة باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewIdea({
                      ...newIdea,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الفكر باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewIdea({
                      ...newIdea,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اختر صورة الفكرة</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img
                      width={100}
                      src={
                        img == ""
                          ? "https://res.cloudinary.com/duovxefh6/image/upload/v1701863613/upload_vv02m0.png"
                          : img
                      }
                      alt=""
                    />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg("");
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language=='ar'?"تغيير حالة الظهور":"Change Appearance Status"}
        children={
          <div className="">
            <h5 className="">
              {language=='ar'?'هل تريد إخفاء هذه الفكره':"Do You Want To Hide This Idea"}
            </h5>

            <button
              onClick={() => {
                if (!changeStatusloading) {
                  handleShow_hide();
                }
              }}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : language=='ar'?"تأكيد":"confirm"}
            </button>
          </div>
        }
      />

      <PopUp
        open={showUpdateModal}
        setOpen={setShowUpdateModal}
        title={`تعديل الفكرة  : ${rowData.title_ar}`}
        onClose={() => {
          console.log('closed');
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateIdeaImage();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الفكرة باللغة العربية</label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الفكرة باللغة الإنجليزية</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اختر صورة الفكرة</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && img != rowData.image && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.image);
                        console.log(rowData.image);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button className="popup_agree_btn">
              {updateLoading ? <Loader /> : "تعديل"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default IdeasPage;
