import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import { Menu, add, edit, eyeOff, eyeOn } from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import UseGeneral from "../../customHooks/useGeneral";

const IdeasProductsPage = () => {
  const {language}=UseGeneral()
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [catProds, setCatProds] = useState(null);

  const location = useLocation();
  const ideaData = location?.state?.ideaData;

  const [originalData, setOriginalData] = useState(null);
  const [searchValue,  setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddCatPordModal, setShowAddCatPordModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [hasOptions, setHasOptions] = useState(0);

  const [newProd, setNewProd] = useState({
    title_ar: "",
    title_en: "",
    description_ar: "",
    description_en: "",
    price: "",
    discount: "",
    hasOptions: 0,
    // images:image1**green**image2**green**image3,
    subcategory_id: id,
  });

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const catProdsHeader = [
    

    {
      label: language=='ar'?"اسم المنتج":'Product Name',
      dataIndex: language=='ar'?"title_ar":"title_en",
    },
    {
      label: language=='ar'?"سعر المنتج":"Product Price",
      dataIndex: "price",
    },
    {
      label: language=='ar'?"الخصم":"Discount",
      dataIndex: "discount",
    },
    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },

    {
      label: language=='ar'?"الحالة":"Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0" ? language=='ar'?"ظاهر":"apparent" : language=='ar'?"مخفي":"hidden"}
          </div>
        );
      },
    },
    {
      label: language=='ar'?" أوامر":"Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                // setUpdateModal(true);
                // setImgUrl(row.image)
                // console.log(row.image);
                navigate(`/editProductIdeas/${row.id}`, {
                  state: { subCat: id },
                });
              }}
            >
              <button className="btn btn-success">{language =="ar" ?"تعديل":"Edit"}</button>
            </div>
            {/* <div
              className='text-primary'
              style={{cursor: "pointer"}}
              onClick={() => {
                console.log(row);
              }}
            >
             <button className="btn btn-success">{language =="ar" ?"عرض":"View"}</button>
            </div> */}
          </div>
        );
      },
    },
  ];

  const getCategoryProds = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}subcategories/subcategory_products/${id}?token=${token}`,{
        headers:{
          lang:language
        }
      })
      .then((res) => {
        if (res?.data && res.data.status == "success") {
          setCatProds(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "faild"||res.data.status =="error") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getCategoryProds();

    console.log(ideaData);
  }, []);

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);

    await axios
      .post(`${BASE_URL}products/update_status?token=${token}`, dataset)
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res?.data?.message);
          getCategoryProds();
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res?.data?.message);
        } else {
          toast.error(language=='ar'?'حذث خطأ ما':'Something Went Error');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((prod) => {
          if (
            searchValue.length >= 1 &&
            !prod.title_ar?.toLowerCase()?.includes(searchValue) &&
            !prod.id.toString()?.toLowerCase()?.includes(searchValue) &&
            !prod.title_en?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setCatProds(newData);
      } else {
        setCatProds(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language=='ar'?'منتجات للفكرة ':'Products Of Idea '} .({language=='ar'?ideaData?.title_ar:ideaData?.title_en}) </h5>
            {/* <div onClick={() => navigate('/addproduct', {state:{subCat:id, subCatName:subCategoryData?.title_ar}})}>{add}</div> */}

            <div
              onClick={() =>
                navigate('/addproduct', {
                  state: {
                    subCat: id,
                    subCatName: language=='ar'?ideaData?.title_ar:ideaData?.title_en,
                    hasOptions: 1,
                  },
                })
              }
              // onClick={() =>
              //   // navigate("/addproduct", {
              //   //   state: {subCat: id, subCatName: subCategoryData?.title_ar},
              //   // }
              //     {
              //       ''
              //     }
              //   )
              // }
            >
              {/* {add} */}
              {language != "ar" ? "Add New Idea" : "إضافة فكرة جديد"}
            </div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language=='ar'?"بحث...":"Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={catProdsHeader} data={catProds} />
          )}
        </div>
      </div>

      <PopUp
        open={showAddCatPordModal}
        setOpen={setShowAddCatPordModal}
        title={"إضافة منتج لهذة الفئة "}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // handleAddNewSubCat();
            }}
          >
            <div className="inputs ">
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_ar: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="field_input">
                <label htmlFor="">وصف المنتج باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      description_en: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">سعر المنتج </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      price: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">قيمة الخصم</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewProd({
                      ...newProd,
                      discount: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="">
                <label htmlFor="options" className="ms-3">
                  له إضافات؟
                </label>
                <input
                  id="options"
                  type="checkbox"
                  onChange={(e) => {
                    console.log(e.target.checked);
                    setNewProd({
                      ...newProd,
                      hasOptions: e.target.checked ? 1 : 0,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language=='ar'?"تغيير حالة الظهور":"Change appearance status"}
        children={
          <div className="">
            <h5 className="">
              {language=='ar'?' هل تريد بالفعل ':'Do you really want to '}{rowData.hidden == "0" ? language=='ar'?" إخفاء ":" hide " : language=='ar'?" إظهار ":'Show'}
                {language=='ar'?'هذا المنتج':'This Product'}
            </h5>

            <button
              onClick={() => {
                if (!changeStatusloading) {
                  handleShow_hide();
                }
              }}
              className="popup_agree_btn"
            >
              {changeStatusloading ? <Loader /> : language=='ar'?'تأكيد':"Confirm"}
            </button>
          </div>
        }
      />
    </>
  );
};

export default IdeasProductsPage;
