import React, { useEffect, useState } from "react";
import TableLayout from "../../components/table";
import { BASE_URL } from "../../components/Axios/base_uri";
import axios from "axios";
import toast from "react-hot-toast";
import imageAvatar from "../../assets/image_placeholder.png";
import {
  Menu,
  add,
  exitModal,
  eyeOff,
  eyeOn,
  search,
} from "../../assets/svgIcons";
import "./Wallets.css";
// import './Wallets.css'
import PopUp from "./../../components/popup/index";
// import "./style.css";
import { edit } from "./../../assets/svgIcons/index";
import { Loader } from "rsuite";
import { useNavigate } from "react-router-dom";
import UseGeneral from "../../customHooks/useGeneral";

const AllWallets = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [type, setType] = useState("inc");
  const [brands, setBrands] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [updateModal, setUpdateModal] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [showChangeVal, setShowChangeVal] = useState(false);
  const [value, setValue] = useState("");
  const [newBrand, setNewBrand] = useState({
    user_name: "",
    phone: "",
    user_id: "",
    balance: "",
    status: 1,
  });
  const [showEmptyModal, setShowEmptyModal] = useState(false);
  const [img, setImg] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [users, setUsers] = useState([]);

  const getBrands = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}user_wallets/get_all?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setBrands(res?.data?.result);
          setOriginalData(res?.data?.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => {
        setBrands([]);
        console.log(e);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  const getUsers = () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    axios
      .get(BASE_URL + `user/get_users?token=${token}`)
      .then((res) => {
        console.log(res, "resres");
        if (Array.isArray(res.data.result)) {
          setUsers(res.data.result);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getBrands();
    getUsers();
  }, []);

  const brandsHeader = [
    {
      label: "#",
      dataIndex: "id",
    },
    {
      label: language == "ar" ? "الإسم" : "Name",
      dataIndex: "id",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <>
            <p>{row?.user_name}</p>
          </>
        );
      },
    },

    {
      label: language == "ar" ? "رقم الهاتف" : "Phone",
      dataIndex: "phone",
    },
    {
      label: language == "ar" ? "قيمه المحفظه" : "Wallet Value",
      dataIndex: "balance",
    },
    {
      label: language == "ar" ? "الحاله" : "Status",
      dataIndex: "id",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <>
            {row.status == 1 ? (
              <img
                style={{ width: "40px" }}
                src={require("../../assets/active.png")}
                alt=""
              />
            ) : (
              <img
                style={{ width: "40px" }}
                src={require("../../assets/not_active.png")}
                alt=""
              />
            )}
          </>
        );
      },
    },

    {
      label: language == "ar" ? "الأوامر" : "Action",
      dataIndex: "id",
      type: "children",
      children: ({ row, headers }) => {
        return (
          <div className="d-flex align-items-center gap-3">
            <img
              onClick={() => {
                setShowChangeVal(true);
                setRowData(row);
              }}
              style={{ width: "30px", cursor: "pointer" }}
              src={require("../../assets/editing.png")}
              alt=""
            />
            <img
              onClick={() => {
                setShowEmptyModal(true);
                setRowData(row);
              }}
              style={{ width: "30px", cursor: "pointer" }}
              src={require("../../assets/empty_wallet.png")}
              alt=""
            />
            <img
              onClick={() => {
                setShowChangeStatus(true);
                setRowData(row);
              }}
              style={{ width: "30px", cursor: "pointer" }}
              src={require("../../assets/chnage_status.png")}
              alt=""
            />
          </div>
        );
      },
    },

    // {
    //   label: "عنوان المحفظه",
    //dataIndex: language=='ar'?"title_ar":"title_en",
    // },

    // {
    //   label: "وقت الانشاء",
    //   dataIndex: "time",
    //   type: "children",
    //   children: ({ row }) => {
    //     return <div>{getOlnlyDate(row.created_at)}</div>;
    //   },
    // },
  ];

  const handleAddNewBrand = async (image) => {
    console.log("add file");

    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      ...newBrand,
      url: image,
    };

    setAddLoading(true);

    await axios
      .post(`${BASE_URL}brands/add_new?token=${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getBrands();
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
        setShowAddBrandModal(false);
        setNewBrand({
          title_ar: "",
          title_en: "",
          url: "",
        });
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);
    axios
      .get(
        BASE_URL + `user_wallets/empty_wallet/${rowData?.id}?token=${token}`,
        {
          headers: {
            lang: language,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          setShowEmptyModal(false);
          getBrands();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusLoading(false);
      });
  };

  const handleUpdateBrand = async (image) => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      url: image,
      link: rowData?.link,
      id: rowData?.id,
    };
    setUpdateLoading(true);

    await axios
      .post(`${BASE_URL}brands/update_brand?token=${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
          getBrands();
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setUpdateModal(false);
        setRowData({});
        setUpdateLoading(false);
      });
  };

  // filterations
  // filterations
  // filterations

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        const newData = originalData.filter((brand) => {
          if (
            (searchValue.length >= 1 &&
              brand.user_name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            brand.phone.toLowerCase().includes(searchValue.toLowerCase())
            // !brand.email.toLowerCase().includes(searchValue.toLowerCase()) &&
            // !brand?.phone.toLowerCase().includes(searchValue.toLowerCase()) &&
            // !brand.wallet_value.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setBrands(newData);
      } else {
        setBrands(originalData);
      }
    }
  }, [searchValue]);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImg(URL.createObjectURL(e.target.files[0]));
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleAddImage = async () => {
    setAddLoading(true);

    const data_send = {
      ...newBrand,
    };
    axios
      .post(BASE_URL + "user_wallets/add_new", data_send, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          getBrands();
          setShowAddBrandModal(false);
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleUpdateBrandImage = async () => {
    setUpdateLoading(true);
    if (selectedFile) {
      // setImgLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await axios
        .post(`${BASE_URL}upload_image`, formData)
        .then((res) => {
          if (
            res.data != "" &&
            (res.status == 201 || res.status == "success")
          ) {
            // setImgUrl(res?.data?.message);
            toast.success(language == "ar" ? "تم الرفع" : "Uploaded");
            handleUpdateBrand(res?.data?.result?.image);
          } else if (res.data == "") {
            toast.error(
              language == "ar"
                ? "هناك مشكلة في رفع الصورة"
                : "Faild To Upload Image"
            );
          } else {
            toast.error(
              language == "ar"
                ? "هناك مشكلة في رفع الصورة"
                : "Faild To Upload Image"
            );
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          // setImgLoading(false);
        });
    } else {
      handleUpdateBrand(rowData.url);
    }

    setUpdateLoading(false);
  };

  const handleChangeVal = () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    setChangeStatusLoading(true);
    const data_send = {
      ...rowData,
    };
    console.log(data_send);
    axios
      .post(
        BASE_URL + `user_wallets/edit_one/${rowData?.id}?token=${token}`,
        data_send
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          getBrands();
          setShowChangeVal(false);
          setValue("");
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "en" ? "Something Went Error" : "حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusLoading(false);
      });
    // axios.post(BASE_URL+`user_wallets/change_wallet_money/${rowData?.id}?token=${token}`,data_send,{
    //   headers:{
    //     lang:language
    //   }
    // })
    // .then((res)=>{
    //   console.log(res.data)
    //   if(res.data.status=='success'){
    //     toast.success(res.data.message)
    //     getBrands()
    //     setShowChangeVal(false)
    //     setValue('');
    //   }
    //   else if(res.data.status=='faild'){
    //     toast.error(res.data.message)
    //   }
    //   else {
    //     toast.error(language=='en'?'Something Went Error':'حدث خطأ ما')
    //   }
    // })
    // .catch(e=>console.log(e))
    // .finally(()=>{
    //   setChangeStatusLoading(false)
    // })
  };

  const handelStopAct = () => {
    const token = localStorage.getItem("GreenTreesAdminToken");
    setChangeStatusLoading(true);
    axios
      .get(
        BASE_URL + `user_wallets/change_status/${rowData?.id}?token=${token}`,
        {
          headers: {
            lang: language,
          },
        }
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message);
          getBrands();
          setShowChangeStatus(false);
          setValue("");
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == "en" ? "Something Went Error" : "حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusLoading(false);
      });
  };

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5>{language == "ar" ? "المحافظ" : "Wallets"}</h5>
            <div
              onClick={() => setShowAddBrandModal(true)}
              className="btn btn-success"
            >
              {language == "ar" ? "إضافة محفظه" : "Add Wallet"}
            </div>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language == "ar" ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader size="md" />
          ) : (
            <TableLayout
              hideCheckBox={true}
              headers={brandsHeader}
              data={brands}
            />
          )}
        </div>
      </div>

      <PopUp
        onClose={() => {
          setImg("");
          setSelectedFile(null);
          setNewBrand({
            title_ar: "",
            title_en: "",
          });
        }}
        open={showAddBrandModal}
        setOpen={setShowAddBrandModal}
        title={language == "ar" ? "إضافة محفظه جديدة " : "Add New Wallet"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">{language == "ar" ? "الإسم" : "Name"}</label>
                <input
                  value={newBrand.user_name}
                  type="text"
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      user_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "المستخدم" : "User"}
                </label>
                <select
                  onChange={(e) => {
                    setNewBrand({ ...newBrand, user_id: e.target.value });
                    console.log(users, e.target.value);
                    setNewBrand({
                      ...newBrand,
                      phone: users?.filter(
                        (item) => item?.id == e.target.value
                      )[0]?.phone,
                      user_name: `${language == "ar" ? "محفظة" : "Wallet"} : ${
                        users?.filter((item) => item?.id == e.target.value)[0]
                          ?.name
                      }`,
                      balance: 0,
                    });
                  }}
                  value={newBrand?.user_id}
                  name=""
                  id=""
                >
                  <option value={""}></option>
                  {users &&
                    users?.map((item, index) => {
                      return <option value={item?.id}>{item?.name}</option>;
                    })}
                </select>
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "رقم الموبايل" : "Phone Number"}
                </label>
                <input
                  type="text"
                  value={newBrand?.phone}
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">
                  {language == "ar" ? "المبلغ المبدئى" : "Initial amount"}
                </label>
                <input
                  type="text"
                  value={newBrand?.balance}
                  onChange={(e) => {
                    setNewBrand({
                      ...newBrand,
                      balance: e.target.value,
                    });
                  }}
                />
              </div>
              {/*
              <div className="field_input">
                <label htmlFor="">{language=='ar'?'الحاله':"Status"}</label>
                {console.log(newBrand.status)}
                <div onClick={()=>{
                  setNewBrand({...newBrand,status:newBrand.status==1?0:1})
                }} className={newBrand.status==1?"status_parent active":"status_parent"}>
                  <div className="status_child"></div>
                </div>
              </div> */}
            </div>

            <button
              onClick={() => handleAddImage()}
              className="popup_agree_btn"
            >
              {addLoading ? <Loader /> : language == "ar" ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={showChangeStatus}
        setOpen={setShowChangeStatus}
        title={language == "ar" ? "تغيير حالة المحفظه" : "Change Wallet Status"}
        children={
          <div className="">
            <h5 className="">
              {language == "ar" ? "هل تريد بالفعل " : "Do You really want to"}
              {rowData.status == "0"
                ? language == "ar"
                  ? " تنشيط "
                  : " Active "
                : language == "ar"
                ? "إيقاف"
                : " Stop "}
              {language == "ar" ? " هذه المحفظه؟ " : " This Wallet "}
            </h5>

            <button onClick={() => handelStopAct()} className="popup_agree_btn">
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
      <PopUp
        open={updateModal}
        onClose={() => {
          setSelectedFile(null);
          setRowData({});
          setImg("");
        }}
        setOpen={setUpdateModal}
        title={`تعديل المحفظه `}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">رابط</label>
                <input
                  type="text"
                  value={rowData?.link}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      link: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اختر صورة المحفظه</label>
                <div className="position-relative">
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor={"add-image"}
                    className="w-100"
                  >
                    <img width={100} src={img} alt="" />
                  </label>
                  <input
                    id={"add-image"}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => onImageChange(e)}
                  />

                  {img && selectedFile && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        setImg(rowData.url);
                        setSelectedFile(null);
                      }}
                      className="position-absolute"
                      style={{
                        top: "10px",
                        right: "20px",
                        backgroundColor: "#d50c0cda",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#FFF",
                      }}
                    >
                      {exitModal}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={() => handleUpdateBrandImage()}
              className="popup_agree_btn"
            >
              {updateLoading ? <Loader /> : "تعديل"}
            </button>
          </form>
        }
      />

      <PopUp
        open={showEmptyModal}
        setOpen={setShowEmptyModal}
        title={language == "ar" ? "تفريغ المحفظه" : "Empty Wallet"}
        children={
          <div className="">
            <h5 className="">
              {language == "ar"
                ? "هل تريد بالفعل إفراغ هذه المحفظه"
                : "Do You really want to Empty This Wallet"}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />

      <PopUp
        open={showChangeVal}
        setOpen={setShowChangeVal}
        title={language == "ar" ? "تعديل  المحفظه" : "Edit Wallet "}
        children={
          <div className="">
            <form onSubmit={(e) => e.preventDefault()} action="">
              {/* <div className="change_val_type">
                <p onClick={()=>{
                  setType('inc')
                }} className={type=='inc'?'active':''}>{language=='ar'?'زياده':'Increase'}</p>
                <p onClick={()=>{
                  setType('dec')
                }} className={type=='dec'?'active':''}>{language=='ar'?'نقص':'Decrease'}</p>
              </div>
              <div className="field_input">
                <label htmlFor="">{language=='ar'?'القيمه':"Value"}</label>
                <input
                  type="text"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value)
                  }}
                />
              </div> */}
              <div className="inputs">
                <div className="field_input">
                  <label htmlFor="">
                    {language == "ar" ? "الإسم" : "Name"}
                  </label>
                  <input
                    value={rowData.user_name}
                    type="text"
                    onChange={(e) => {
                      setRowData({
                        ...rowData,
                        user_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="field_input">
                  <label htmlFor="">
                    {language == "ar" ? "المستخدم" : "User"}
                  </label>
                  <select
                    // onChange={(e) => {
                    //   setRowData({ ...rowData, user_id: e.target.value });
                    // }}
                    value={rowData?.user_id}
                    disabled={true}
                    name=""
                    id=""
                  >
                    {users &&
                      users?.map((item, index) => {
                        return <option value={item?.id}>{item?.name}</option>;
                      })}
                  </select>
                </div>
                <div className="field_input">
                  <label htmlFor="">
                    {language == "ar" ? "رقم الموبايل" : "Phone Number"}
                  </label>
                  <input
                    type="text"
                    disabled={true}
                    value={rowData?.phone}
                    // onChange={(e) => {
                    //   setRowData({
                    //     ...rowData,
                    //     phone: e.target.value,
                    //   });
                    // }}
                  />
                </div>
                <div className="field_input">
                  <label htmlFor="">
                    {language == "ar" ? "المبلغ " : " amount"}
                  </label>
                  <input
                    type="text"
                    value={rowData?.balance}
                    onChange={(e) => {
                      setRowData({
                        ...rowData,
                        balance: e.target.value,
                      });
                    }}
                  />
                </div>

                {/* <div className="field_input">
                <label htmlFor="">{language=='ar'?'الحاله':"Status"}</label>
                <div onClick={()=>{
                  setRowData({...rowData,status:rowData.status==1?0:1})
                }} className={rowData.status==1?"status_parent active":"status_parent"}>
                  <div className="status_child"></div>
                </div>
              </div> */}
              </div>
            </form>

            <button
              onClick={() => handleChangeVal()}
              className="popup_agree_btn"
            >
              {changeStatusLoading ? (
                <Loader />
              ) : language == "ar" ? (
                "تأكيد"
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        }
      />
    </>
  );
};

export default AllWallets;
