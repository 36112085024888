import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import {
  Menu,
  add,
  edit,
  exitModal,
  eyeOff,
  eyeOn,
} from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";
import { Axios } from "../../components/Axios";
import UseGeneral from "../../customHooks/useGeneral";

const DistrictsArchive = () => {
  const {language}=UseGeneral()
  const location = useLocation();
  const rejionData = location?.state?.rejionData;
  const { id } = useParams();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);

  const [searchValue,  setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);
  const [showAddSubCatModal, setShowAddSubCatModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [product_id, set_product_id] = useState({});
  const [loader, setLoader] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [productData, setProductData] = useState({});
  const [newDistrict, setNewDistrict] = useState({
    title_ar: "",
    title_en: "",
    region_id: id,
  });

  const getDistricts = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .post(`${BASE_URL}districts/get_hidden_for_admin?token=${token}`, {
        region_id: id,
      })
      .then((res) => {
        if (res.data.status == "success") {
          setData(res?.data?.result?.filter((item) => item?.hidden == "1"));
          setOriginalData(
            res?.data?.result?.filter((item) => item?.hidden == "1")
          );
          console.log(res.data.result);
        } else if (res.data.status == "error") {
          toast.error(res.data.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  const setArrangeNumber = async (video_id, video_number) => {
    console.log(video_number);
    console.log(video_id);
    // return
    setLoader(true);
    if (video_number == currentNumber) {
      return setLoader(false);
    }
    await Axios({
      method: "POST",
      url: "districts/change_number/" + video_id,
      data: { number: video_number },
    })
      .then(async (response) => {
        console.log(response);
        toast.success(response?.message);
        getDistricts();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getDistricts();
  }, []);

  const headers = [
    

    {
      label: language=='ar'?"اسم  الحي ":"District Name",
   dataIndex: language=='ar'?"title_ar":"title_en",
    },

    {
      label:language=='ar'? "الحالة":"status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {language=='ar'?'مخفى':'Hidden'}
          </div>
        );
      },
    },
    {
      label: language=='ar'?" أوامر":'Actions',
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-success" : "text-danger"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleAddNewDis = async (image) => {
    const dataset = {
      ...newDistrict,
      region_id: +id,
    };

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}districts/add_new?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success("تم إضافة الحي  بنجاح");
          getDistricts();
        } else if (res.data == "") {
          toast.error("هناك مشكلة في رفع الصورة");
        } else {
          toast.error("هناك مشكلة في رفع الصورة");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    const dataset = {
      id: rowData.id,
    };

    setChangeStatusLoading(true);
    await axios
      .get(`${BASE_URL}districts/change_status/${rowData.id}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(
            res.data.message
          );
          getDistricts();
        } else if (res.data.status == "error"||res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language=='ar'?"حدث خطأ ما":"Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        setRowData({});
      });
  };

  const handleUpdateData = async () => {
   
    const dataset = {
      ...rowData,
      title_ar: rowData.title_ar,
      title_en: rowData.title_en,
      category_id: id,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    setEditLoading(true);
    await axios
      .post(
        `${BASE_URL}districts/update_district/${rowData.id}?token=${token}`,
        dataset
      )
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success("تم تعديل  الحي بنجاح");
          getDistricts();
        } else if (res.data == "") {
          toast.error("هناك مشكلة في عملية التعديل");
        } else {
          toast.error(" هناك مشكلة في عملية التعديل  ");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
        setUpdateModal(false);
      });
  };

  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((item) => {
          if (
            searchValue.length >= 1 &&
            !item.title_ar.toLowerCase().includes(searchValue.toLowerCase()) &&
            !item.title_en.toLowerCase().includes(searchValue.toLowerCase()) &&
            !item.id.toString()?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <>
      <div className="rowDiv flex-2-1 page_padding">
        <div>
          <div className="title_add">
            <h5> {language=='ar'?'الأحياء':'Districts'} </h5>
          </div>

          <div className="searchInput field_input">
            <input
              type="text"
              placeholder={language=='ar'?"بحث...":"Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>

          {dataLoading ? (
            <Loader />
          ) : (
            <TableLayout headers={headers} data={data} />
          )}
        </div>
      </div>

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={language=='ar'?"تغيير حالة الظهور":"Change Qppearance Status"}
        children={
          <div className="">
            <h5 className=" my-5">
              {language=='ar'?'هل تريد أظهار هذا الحى':"Do You Want To Show This District"}
            </h5>

            <button
              onClick={() => handleShow_hide()}
              className="popup_agree_btn"
            >
              {changeStatusloading ? (
                <Loader />
              ) : rowData.hidden == "0" ? (
                " إخفاء "
              ) : (
                language=='ar'?'إظهار':'Show'
              )}
            </button>
          </div>
        }
      />

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        title={"إضافة حي جديد "}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewDis();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الحي باللغة العربية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewDistrict({
                      ...newDistrict,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الحي باللغة الإنجليزية</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setNewDistrict({
                      ...newDistrict,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : "إضافة"}
            </button>
          </form>
        }
      />

      <PopUp
        open={updateModal}
        setOpen={setUpdateModal}
        title={`تعديل  الحي : ${rowData.title_ar}`}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateData();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <label htmlFor="">اسم الحي باللغة العربية</label>
                <input
                  type="text"
                  value={rowData.title_ar}
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_ar: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="field_input">
                <label htmlFor="">اسم الحي باللغة الإنجليزية</label>
                <input
                  value={rowData.title_en}
                  type="text"
                  onChange={(e) => {
                    setRowData({
                      ...rowData,
                      title_en: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {editLoading ? <Loader /> : "تعديل"}
            </button>
          </form>
        }
      />
    </>
  );
};

export default DistrictsArchive;
