import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../components/Axios/base_uri";
import toast from "react-hot-toast";
import TableLayout from "../../components/table";
import { Menu, Preview, add, edit, eyeOff, eyeOn } from "../../assets/svgIcons";
import PopUp from "../../components/popup";
import { Loader } from "rsuite";

import "./style.css";
import UseGeneral from "../../customHooks/useGeneral";

const CommonQuestions = () => {
  const { language } = UseGeneral();
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [changeStatusloading, setChangeStatusLoading] = useState(false);

  const getOlnlyDate = (date) => {
    const dateTimeString = date;
    const datePart = dateTimeString?.split("T")[0];
    return datePart;
  };

  const [newQ, setNewQ] = useState({
    description_ar: '',
    description_en: '', //anser
    title_en: '', // quesiton
    title_ar: '',
  });

  const [lang, setLang] = useState({
    engQ: false,
    engAns: false,
  });

  const getQestions = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setDataLoading(true);
    await axios
      .get(`${BASE_URL}faqs/get_all_for_admin?token=${token}`, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          setData(res?.data?.result);
          setOriginalData(res?.data?.result);
          console.log(res.data.result);
        } else if (res.data.status == "error" || res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setDataLoading(false);
      });
  };

  useEffect(() => {
    getQestions();
  }, []);

  const header = [
    {
      label: language == 'ar' ? "السؤال" : "Question",
      dataIndex: language == 'ar' ? "title_ar" : "title_en",
    },
    {
      label: language == 'ar' ? "الجواب" : "Answer",
      dataIndex:language == 'ar' ? "description_ar" : "description_en",
    },
    {
      label: language == 'ar' ? "الحالة" : "Status",
      type: "children",
      children: ({ row }) => {
        return (
          <div
            className={`${row.hidden == "0" ? "text-success" : "text-danger"}`}
          >
            {row.hidden == "0"
              ? language == 'ar'
                ? "ظاهر"
                : "Showen"
              : language == 'ar'
              ? "مخفي"
              : "Hidden"}
          </div>
        );
      },
    },

    {
      label: language == 'ar' ? "تاريخ الانشاء" : "Date Of Creation",
      dataIndex: "time",
      type: "children",
      children: ({ row }) => {
        return <div>{getOlnlyDate(row.created_at)}</div>;
      },
    },

    {
      label: language == 'ar' ? " أوامر" : "Actions",
      type: "children",
      children: ({ row }) => {
        return (
          <div className="d-flex align-items-center gap-4">
            <div
              className={`${
                row.hidden == "0" ? "text-danger" : "text-success"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setChangeStatusModal(true);
              }}
            >
              <button className="btn btn-success">
                {row.hidden == "0"
                  ? language == "ar"
                    ? "إخفاء"
                    : "Hide"
                  : language == "ar"
                  ? "إظهار"
                  : "Show"}
              </button>
            </div>
            <div
              className="text-primary"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setEditModal(true);

                console.log(row);
              }}
            >
              <button className="btn btn-success">
                {language == "ar" ? "تعديل" : "Edit"}
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  const handleAddNewQuestion = async () => {
   
    const dataset = {
      ...newQ,
    };

    console.log(dataset);

    setAddLoading(true);
    const token = localStorage.getItem("GreenTreesAdminToken");
    await axios
      .post(`${BASE_URL}faqs/add_new?token=${token}`, dataset, {
        headers: {
          lang: language,
        },
      })
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getQestions();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? 'حدث خطأ ما' : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setImgLoading(false);
        setShowAddModal(false);
        setAddLoading(false);
        setNewQ({
          description_ar: '',
          description_en: '', //anser
          title_en: '', // quesiton
          title_ar: '',
        });
        setLang({
          engQ: false,
          engAns: false,
        });
      });
  };

  const handleUpdateQuestion = async () => {
   
   

    const dataset = {
      description_ar: rowData.description_ar,
      description_en: rowData.description_en,
      title_en: rowData.title_en,
      title_ar: rowData.title_ar,
    };

    const token = localStorage.getItem("GreenTreesAdminToken");

    setEditLoading(true);
    await axios
      .post(`${BASE_URL}faqs/update_faq/${rowData.id}?token=${token}`, dataset)
      .then((res) => {
        console.log(res);

        if (res.data != "" && res.data.status == "success") {
          toast.success(res.data.message);
          getQestions();
        } else if (res.data.status == "faild") {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? 'حدث خطأ ما' : 'Something Went Error');
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setEditLoading(false);
        setLang({
          engQ: false,
          engAns: false,
        });
        setEditModal(false);
      });
  };

  const handleShow_hide = async () => {
    const token = localStorage.getItem("GreenTreesAdminToken");

    setChangeStatusLoading(true);
    await axios
      .get(`${BASE_URL}faqs/update_status/${rowData.id}?token=${token}`)
      .then((res) => {
        console.log(res);
        if (res?.data && res?.data?.status == "success") {
          toast.success(res.data.message);
        } else if (res.data.status == "error" || res.data.status == 'faild') {
          toast.error(res.data.message);
        } else {
          toast.error(language == 'ar' ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setChangeStatusModal(false);
        setChangeStatusLoading(false);
        getQestions();
        setRowData({});
      });
  };

  // filtrations
  useEffect(() => {
    if (originalData && originalData.length >= 1) {
      if (searchValue.length > 0) {
        console.log(searchValue);
        const newData = originalData.filter((q) => {
          if (
            searchValue.length >= 1 &&
            !q.title_ar?.toLowerCase()?.includes(searchValue) &&
            !q.title_en?.toLowerCase()?.includes(searchValue) &&
            !q.description_ar?.toLowerCase()?.includes(searchValue) &&
            !q.description_en?.toLowerCase()?.includes(searchValue)
          ) {
            return false;
          }
          return true;
        });
        setData(newData);
      } else {
        setData(originalData);
      }
    }
  }, [searchValue]);

  return (
    <div className="rowDiv flex-2-1 page_padding">
      <div>
        <div className="title_add">
          <h5> {language == 'ar' ? "الأسئلة الشائعة " : "Common Question"}</h5>
          <div
            onClick={() => setShowAddModal(true)}
            className="btn btn-success"
          >
            {language == 'ar' ? 'إضافة سؤال' : 'Add Question'}
          </div>
        </div>

        <div className="d-flex align-items-center gap-4">
          <div className="searchInput field_input">
            <input
              type="text"
              onWheel={(e) => e.target.blur()}
              placeholder={language == 'ar' ? "بحث..." : "Search..."}
              onChange={(e) => {
                setSearchValue(e.target.value?.toLowerCase());
              }}
            />
          </div>
        </div>

        {dataLoading ? (
          <Loader />
        ) : (
          <TableLayout headers={header} data={data} />
        )}
      </div>

      <PopUp
        open={showAddModal}
        setOpen={setShowAddModal}
        title={language == 'ar' ? " إضافة سؤال جديد " : "Add New Question"}
        onClose={() => {
          console.log('closed');
        }}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddNewQuestion();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? "السؤال باللغة" : "Question In"}{" "}
                    {lang.engQ
                      ? language == 'ar'
                        ? " الإنجليزية "
                        : " English "
                      : language == 'ar'
                      ? " العربية "
                      : " Arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="ques"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engQ: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="ques"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={lang.engQ ? newQ.title_en : newQ.title_ar}
                  onChange={(e) => {
                    lang.engQ
                      ? setNewQ({
                          ...newQ,
                          title_en: e.target.value,
                        })
                      : setNewQ({
                          ...newQ,
                          title_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? "الإجابة باللغة " : "Answer In "}{" "}
                    {lang.engAns
                      ? language == 'ar'
                        ? "الإنجليزية"
                        : "English"
                      : language == 'ar'
                      ? " العربية "
                      : "Arabic"}
                  </label>

                  <div class="toggle-switch">
                    <input
                      class="toggle-input"
                      id="prod_name"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engAns: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="prod_name"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={
                    lang.engAns ? newQ.description_en : newQ.description_ar
                  }
                  onChange={(e) => {
                    lang.engAns
                      ? setNewQ({
                          ...newQ,
                          description_en: e.target.value,
                        })
                      : setNewQ({
                          ...newQ,
                          description_ar: e.target.value,
                        });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {addLoading ? <Loader /> : language == 'ar' ? "إضافة" : "Add"}
            </button>
          </form>
        }
      />

      <PopUp
        open={changeStatusModal}
        setOpen={setChangeStatusModal}
        title={
          language == 'ar' ? "تغيير حالة الظهور" : "Change Appearance Status"
        }
        children={
          <div className="">
            <h5 className="">
              {language == 'ar' ? "هل تريد بالفعل" : "Do You Really want"}{" "}
              {rowData.hidden == "0"
                ? language == 'ar'
                  ? " إخفاء "
                  : " Hide "
                : language == 'ar'
                ? " إظهار "
                : " Show "}{" "}
              {language == 'ar' ? ' هذا السؤال ' : ' This Question '}
            </h5>
            <div className="modalButtons">
              <button
                onClick={() => handleShow_hide()}
                className="btn btn-danger"
              >
                {changeStatusloading ? (
                  <Loader />
                ) : language == 'ar' ? (
                  "تأكيد"
                ) : (
                  "Confirm"
                )}
              </button>
              <button
                onClick={() =>
                  changeStatusloading ? null : setChangeStatusModal(false)
                }
                className="btn btn-success"
              >
                {language == 'ar' ? "إلغاء" : "Cancel"}
              </button>
            </div>
          </div>
        }
      />

      <PopUp
        open={editModal}
        setOpen={setEditModal}
        title={language == 'ar' ? " تعديل  سؤال  " : "Edit Question"}
        children={
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateQuestion();
            }}
          >
            <div className="inputs">
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? "السؤال باللغة " : "Question In "}{" "}
                    {lang.engQ
                      ? language == 'ar'
                        ? " الإنجليزية "
                        : " English "
                      : language == 'ar'
                      ? " العربية "
                      : " Arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      checked={lang.engQ}
                      class="toggle-input"
                      id="prod_name"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engQ: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="prod_name"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={lang.engQ ? rowData.title_en : rowData.title_ar}
                  onChange={(e) => {
                    lang.engQ
                      ? setRowData({
                          ...rowData,
                          title_en: e.target.value,
                        })
                      : setRowData({
                          ...rowData,
                          title_ar: e.target.value,
                        });
                  }}
                />
              </div>
              <div className="field_input">
                <div className="d-flex align-items-center gap-4">
                  <label htmlFor="">
                    {language == 'ar' ? "الإجابة باللغة" : "Answer In "}{" "}
                    {lang.engAns
                      ? language == 'ar'
                        ? " الإنجليزية "
                        : " English "
                      : language == 'ar'
                      ? " العربية "
                      : " Arabic "}
                  </label>

                  <div class="toggle-switch">
                    <input
                      checked={lang.engAns}
                      class="toggle-input"
                      id="des"
                      type="checkbox"
                      onChange={(e) => {
                        setLang({
                          ...lang,
                          engAns: e.target.checked,
                        });
                      }}
                    />
                    <label class="toggle-label" for="des"></label>
                  </div>
                </div>
                <input
                  type="text"
                  value={
                    lang.engAns
                      ? rowData.description_en
                      : rowData.description_ar
                  }
                  onChange={(e) => {
                    lang.engAns
                      ? setRowData({
                          ...rowData,
                          description_en: e.target.value,
                        })
                      : setRowData({
                          ...rowData,
                          description_ar: e.target.value,
                        });
                  }}
                />
              </div>
            </div>

            <button className="popup_agree_btn">
              {editLoading ? <Loader /> : language == 'ar' ? "تعديل" : "Edit"}
            </button>
          </form>
        }
      />
    </div>
  );
};

export default CommonQuestions;
