export const links = (email) => {
  console.log(email);
  return [
    {
      to: "/",
      icon: "fas fa-home",
      labelArabic: "الرئيسية",
      labelEnglish: "Home",
    },
    {
      to: "/notifications",
      icon: "far fa-bell",
      labelArabic: "الإشعارات",
      labelEnglish: "Notifications",
    },
    {
      to: "/categories",
      icon: "fas fa-list",
      labelArabic: "الفئات",
      labelEnglish: "Categories",
    },
    {
      to: "/products",
      icon: "fas fa-shopping-cart",
      labelArabic: "المنتجات",
      labelEnglish: "Products",
    },
    {
      to: "/Traders",
      icon: "fas fa-shopping-cart",
      labelArabic: "التجار",
      labelEnglish: "Traders",
    },
    {
      to: "/Store",
      icon: "fas fa-stream",
      labelArabic: "المخزن",
      labelEnglish: "Stock",
    },
    // {
    //   to: "/Offers",
    //   icon: "fas fa-stream",
    //   labelArabic: "العروض",
    //   labelEnglish: "Offers",
    // },
    {
      to: "/orders",
      icon: "fas fa-calendar-week",
      labelArabic: "الطلبات",
      labelEnglish: "Orders",
    },
    email === "admin@greentrees.com"
      ? {
          to: "/management",
          icon: "fas fa-cogs",
          labelArabic: "المستخدمين",
          labelEnglish: "Users",
        }
      : {},
    {
      to: "/sections",
      icon: "far fa-building",
      labelArabic: "السكاشن",
      labelEnglish: "Sections",
    },
    {
      to: "/about",
      icon: "far fa-address-card",
      labelArabic: "عن الموقع",
      labelEnglish: "About",
    },
    // {
    //   to: "/ideas",
    //   icon: "far fa-lightbulb",
    //   labelArabic: "الأفكار",
    //   labelEnglish: "Ideas",
    // },
    {
      to: "/banners",
      icon: "fas fa-images",
      labelArabic: "البانرات",
      labelEnglish: "Banners",
    },
    {
      to: "/brands",
      icon: "fas fa-tag",
      labelArabic: "الماركات",
      labelEnglish: "Brands",
    },
    {
      to: "/rejions",
      icon: "fas fa-map-marker-alt",
      labelArabic: "مناطق الوصول",
      labelEnglish: "Locations",
    },
    {
      to: "/footerdetails",
      icon: "fas fa-info-circle",
      labelArabic: "بيانات الفوتر",
      labelEnglish: "Footer Info",
    },
    {
      to: "/coupons",
      icon: "fas fa-medal",
      labelArabic: "الكوبونات",
      labelEnglish: "Coupons",
    },
    {
      to: "/commonquestions",
      icon: "far fa-question-circle",
      labelArabic: "الأسئلة الشائعة",
      labelEnglish: "FAQ",
    },
    {
      to: "/contacts",
      icon: "far fa-envelope",
      labelArabic: "معلومات التواصل",
      labelEnglish: "Contact",
    },
    {
      to: "/gallery",
      icon: "far fa-images",
      labelArabic: "معرض الصور",
      labelEnglish: "Gallery",
    },
    {
      to: "/Wallets",
      icon: "far fa-images",
      labelArabic: "المحافظ",
      labelEnglish: "Wallets",
    },
  ];
};

export const Archived = [
  {
    to: "/ArchivedCategories",
    icon: "fas fa-archive",
    labelArabic: "أرشيف الفئات",
    labelEnglish: "Archived Categories",
  },
  {
    to: "/ArchivedProducts",
    icon: "fas fa-archive",
    labelArabic: "أرشيف المنتجات",
    labelEnglish: "Archived Products",
  },
  {
    to: "/BannersArchive",
    icon: "fas fa-archive",
    labelArabic: "أرشيف البانرات",
    labelEnglish: "Banners Archive",
  },
  {
    to: "/SubCategoryArchived",
    icon: "fas fa-archive",
    labelArabic: "أرشيف الفئات الفرعية",
    labelEnglish: "Sub Categories Archive",
  },
  {
    to: "/SectionsArchive",
    icon: "fas fa-archive",
    labelArabic: "أرشيف السكاشن",
    labelEnglish: "Sections Archive",
  },
  {
    to: "/RejionsArchive",
    icon: "fas fa-archive",
    labelArabic: "أرشيف المناطق",
    labelEnglish: "Archived Regions",
  },
  {
    to: "/DistrictsArchive",
    icon: "fas fa-archive",
    labelArabic: "أرشيف الأحياء",
    labelEnglish: "Archived Districts",
  },
  // {
  //   to: "/RecipesArchive",
  //   icon: "fas fa-archive",
  //   labelArabic: "أرشيف الوصفات",
  //   labelEnglish: "Archived Recipes",
  // },
];
